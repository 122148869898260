import React from "react";
// Customizable Area Start
import { Link } from "react-router-dom";
import { Col, Row, Button, Card } from "antd";
import {
  dhoniImage,
  femaleDoctor,
  Maledoctor,
} from "./assets";
import { Carousel } from "react-responsive-carousel";
import ServiceController from "./ServiceController.web";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import FooterCommonComponent from "../../../components/src/FooterCommonComponent.web";
import PatientCommanOffersComponent from "../../../components/src/PatientCommanOffersComponent.web";
import FooterCardComponent from "./component/FooterCardComponent";
import PatientProfilePopup from "../../../components/src/PatientProfilePopup.web";
import {banner7,banner9,banner10 } from "../../splashscreen/src/assets";
let config = require("../../../framework/src/config.js").baseURL;

// Customizable Area End

class Services extends ServiceController {
  // Customizable Area Start
  renderNavigationTitle = () => {
    return (
      <>
        <div className="navigationContainer">
          <Link to="/patienthome">Home</Link>
          <Link to="/patient-appointment-landingpage">Appointments</Link>

          <Link to="/services" className="active">
            Services
          </Link>
          <Link to="/patientmessage">Messages</Link>
        </div>
      </>
    );
  };
  // Customizable Area End

// Customizable Area Start
renderServiceCarousel = () => {
  return (
          <Carousel
              infiniteLoop={true}
              autoPlay={true}
              stopOnHover={false}
              showThumbs={false}
              showArrows={false}
              showStatus={false}
              className='renderMainCarousel'
          >
                    <div>
                        <img className='carouselImage' src={banner9} alt="CarouselImage" />
                    </div>
                    <div>
                        <img className='carouselImage' src={banner10} alt="CarouselImage"/>
                    </div>
                    <div>
                        <img className='carouselImage' src={banner7} alt="CarouselImage" />
                    </div>
          </Carousel>
  )
}
// Customizable Area End
  // Customizable Area Start

  renderOurServices = () => {
    let ourServicesDataShow = this.state.ourServicesDataSliced
      ? this.state.ourServicesData
      : this.state.ourServicesData.slice(0, 6);
    return (
      <>
        <div className="ourServicesContainer">
          <div className="ourServicesHeaderPart topmargin">
            <h2 className="ourServicesTitle">Our Services</h2>
            <p
              data-test-id="ourtrendingId"
              className="ourServicesSubTitle"
              onClick={this.handleServicesData}
            >
              {!this.state.ourServicesDataSliced ? "See All" : "View less"}
            </p>
          </div>
          <Row className="cardRow" gutter={[20, 20]}>
            {ourServicesDataShow.map((item, index) => {
              console.log(item, "order-medicine");
              if (item.attributes.title === "Order Medicines") {
                return (
                  <Col xl={4} lg={6} md={8} sm={12} xs={24} key={index + 1212}>
                    {/* onClick={()=> this.props.history.push('/OrderMedicinePage')} */}
                    <div className="servicesCard">
                      <div className="servicesCardImgBox">
                        <img
                          src={config + item.attributes.service_image}
                          className="servicesCarddImg"
                        />
                      </div>
                      <p className="servicesCardText">
                        {item.attributes.title}
                      </p>
                    </div>
                  </Col>
                );
              }
              return (
                <Col xl={4} lg={6} md={8} sm={12} xs={24} key={index + 1212}>
                  <div className="servicesCard">
                    <div className="servicesCardImgBox">
                      <img
                        src={config + item.attributes.service_image}
                        className="servicesCarddImg"
                      />
                    </div>
                    <p className="servicesCardText">{item.attributes.title}</p>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  renderServiceCarouselSecondPart = () => {
    const carrasolStyle = {
      cardImgWrapper: {
        height: "400px",
      },
      carrasolIma: {
        maxWidth: "100%",
        height: "100%",
      },
    };
    return (
      <div style={{ height: "412px" }}>
        <Carousel
          interval={3000}
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          infiniteLoop={true}
          autoPlay={true}
          stopOnHover={false}
          className="secondCarousel"
        >
          <div style={carrasolStyle.cardImgWrapper}>
            <img style={carrasolStyle.carrasolIma} src={dhoniImage} />
          </div>
          <div style={carrasolStyle.cardImgWrapper}>
            <img style={carrasolStyle.carrasolIma} src={dhoniImage} />
          </div>
          <div style={carrasolStyle.cardImgWrapper}>
            <img style={carrasolStyle.carrasolIma} src={dhoniImage} />
          </div>
        </Carousel>
      </div>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  render() {
    const dataList = this.state.trendingTherapiesDataSliced
      ? this.state.featureTherapiesData.slice(0, 6)
      : this.state.featureTherapiesData;
    const dataSymponet = this.state.SymptomDataSliced
      ? this.state.symptomsList.slice(0, 6)
      : this.state.symptomsList;

    const boxStyle: any = {
      borderRadius: "4px",
      boxShadow: "rgba(0, 0, 0, 0.1) 5px 6px 14px",
      margin: "10px",
      width: 350,
      height: 150,
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
    };
    return (
      <div>
        <NavbarCommonComponent handleProfile={this.showDetailsHandler} />
        <PatientProfilePopup
          data-test-id="handleLogoutId"
          profileData={this.state.showPopup}
          logoutFunctiuon={this.handleLogout}
        />
        {this.renderNavigationTitle()}
        <div className="itemContainerWiidth">
          <div>
            <PatientCommanOffersComponent label1="" label2="" img="" />
          </div>
          {this.renderOurServices()}
          <div className="ourServicesContainer">
            <div className="ourServicesHeaderPart">
              <h2 className="ourServicesTitle">Featured Therapies</h2>
              <p
                data-test-id="featureTherapyId"
                className="ourServicesSubTitle"
                onClick={this.handleTherapiesData}
              >
                {dataList.length <= 6 ? "See All" : "View Less"}
              </p>
            </div>
            <Row gutter={[8, 16]}>
              {dataList.map((item: any, index: any) => {
                return (
                  <Col xl={4} lg={6} md={8} sm={12} xs={24} key={index + 1212}>
                    <div>
                      <div className="shortTrendingCard" key={index + 123}>
                        <img
                          alt="example"
                          style={{ width: "175px", height: "98px",cursor:"pointer" }}
                          data-test-id={`service-${index}`}
                          src={
                            item.attributes.body_part_image
                              ? config + item.attributes.body_part_image
                              : ""
                          }
                          onClick={() =>
                            this.navigateToTherapyPage(
                              item?.attributes?.id,
                              item.attributes.body_part_name
                            )
                          }
                        />
                        <p style={{ marginLeft: "20px" }}>
                          {item.attributes.body_part_name}
                        </p>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
          {this.renderServiceCarouselSecondPart()}
          <div className="ourServicesContainer">
            <div className="ourServicesHeaderPart">
              <h2 className="ourServicesTitle">{"Browse by Symptoms"}</h2>
              <p
                data-test-id="symptomsId"
                className="ourServicesSubTitle"
                onClick={this.handleSymptonData}
              >
                {dataSymponet.length <= 6 ? "See All" : "View less"}
              </p>
            </div>
            <Row gutter={[8, 16]}>
              {dataSymponet.map((item: any, index: any) => {
                return (
                  <Col
                    xl={4}
                    lg={6}
                    md={8}
                    sm={12}
                    xs={24}
                    key={index + 1212652}
                  >
                    <div>
                      <div className="shortTrendingCard" key={index + 123}>
                        <img
                          alt="example"
                          style={{ width: "175px", height: "98px" }}
                          src={
                            item.attributes.symptom_image
                              ? config + item.attributes.symptom_image
                              : ""
                          }
                        />
                        <p style={{ marginLeft: "20px" }}>
                          {item.attributes.symptom_name}
                        </p>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
          <FooterCardComponent />
        </div>
        <FooterCommonComponent />
      </div>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default Services;
export { Services };
// Customizable Area End
