import React from "react";
// Customizable Area Start 
import {
  Box,
  Typography,
  styled,
  Grid,AccordionDetails,
  Accordion, AccordionSummary
} from "@material-ui/core";
import { createTheme,} from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import FaqPageController, { Props } from "./FaqPageController.web";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import FooterCommonComponent from '../../../components/src/FooterCommonComponent.web';
import FooterDocotr from "../../../components/src/FooterDoctor.web";
import PatientProfilePopup from "../../../components/src/PatientProfilePopup.web";
import { Link } from 'react-router-dom';
// Customizable Area End
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class FaqPage extends FaqPageController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start 
    // Customizable Area End 
    return (
        // Customizable Area Start
        <>   
        <NavbarCommonComponent handleProfile={this.handelShowDoctoPopUp} data-test-id="doctorProfilePopupId" />
          <PatientProfilePopup data-test-id="handleLogoutId" profileData={this.state.showPopup} logoutFunctiuon={this.handleDoctorLogout} />
            <NavigateBox>
              <Link to="/patienthome"><NavigateTitle>Home</NavigateTitle></Link>
              <Link to="/patient-appointment-landingpage/"><NavigateTitle>Appointments</NavigateTitle></Link>
              <Link to="/Services"><NavigateTitle>Services</NavigateTitle></Link>
              <Link to="/patientmessage"><NavigateTitle>Messages</NavigateTitle></Link>
            </NavigateBox>
        <ContainerBox>
        <h5 style={{fontWeight: 600 ,fontSize: "22px", textAlign:'center'}}>
          FAQs
        </h5>
        <New container spacing={4}>
                    <div className="mainQuestions">
    {this.state.faqData.map((item: any) => {
        return (
            <Accordion key={item.id} className="accordionContent" data-test-id="accordion">
                <AccordionSummary expandIcon={<ExpandMoreIcon className="expandIconStyle" />} className="accordionSummary">
                    <Typography className="question"  dangerouslySetInnerHTML={{ __html: item.question }}/>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className="answer" dangerouslySetInnerHTML={{ __html: item.answer }} />
                </AccordionDetails>
            </Accordion>
        );
    })}
</div>
        </New>
      </ContainerBox>
      {
        localStorage.getItem("token") ? (<FooterDocotr/>): <FooterCommonComponent />
      }
      </>
      // Customizable Area End 
    );
  }
}

// Customizable Area Start

const New = styled(Grid)({
  "& .selectDisease": {
      border: "1px solid #808080",
      marginTop: "8px",
      "&.ant-select:not(.ant-select-disabled):hover .ant-select-selector": {
          borderColor: "#eee6e6 !important",
          outline: "none",
          borderWidth:"1px"
      },
  },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#808080 !important",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#5AA4F4 !important",
      borderWidth:"1px"
  },
  "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#808080"
  },
  "& .accordionContent": {
    borderRadius: "12px",
    width:'50%',
    border: "1px solid black",
    "& .MuiAccordionSummary-content": {
        justifyContent: "center !important"
    },
    "& .MuiAccordionDetails-root": {
        justifyContent: "center !important",
        textAlign: "center",
        padding: "20px 70px"
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
        borderBottom: "1px solid #E2E8F0"
    },
    "@media(max-width:900px)":{
      width: "80%",
    }
},
"& .question": {
    fontSize: "20px",
    fontStyle: "normal",
    lineHeight: "24px",
    fontWeight: 200,
    color: "black",
    "@media (max-width: 725px)": {
        wordBreak: "break-word"
    }
},
"& .answer": {
    fontSize: "20px",
    fontStyle: "normal",
    lineHeight: "24px",
    fontWeight: 200,
    color: "black"
},
"& .mainQuestions": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding:"60px 0px",
    flexDirection: "column",
    gap:'20px',
    alignItems: "center",
},
"& .accordionSummary": {
    textAlign: "center"
},

});
const NavigateBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexFlow: "wrap",
  width: "89%",
  margin: "0 auto"
});

const NavigateTitle = styled("p")({
  color: "black",
  fontSize: "16px",
  fontWeight: 600
});
const ContainerBox = styled(Box)({
  width: "89%",
  margin: "0 auto"
});
// Customizable Area End
