import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import ApiCall from "../../../components/src/ApiCall.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  id: string;
  history: any;
  location: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  doctorLoginOtpVerify: string;
  remainingTime: number;
  getVerifyLoginOtpData: any;
  loginOtpVerify: string;
  patientLoginData: any;
  invalidAccount: boolean;
  invalidLoginOtp: boolean;
  getVerifyLoginDoctorOtpData: any;
  IsLoginotpExpired: boolean;
  loginOtptime: any;
  doctorResendData: any;
  doctor_id: any
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class LoginOtpController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getVerifyLoginOtpApiCallId: string = "";
  getDoctorVerifyLoginOtpApiCallId: string = "";
  getDoctorResendOtpApiCallId: string = "";
  getPatientResendOtpApiCallId: string = ""
  getPatientDetailsApiCallId: string = "";
  getPatientStatusApiCallId:string="";
  getDoctorStatusApiCallId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      // Customizable Area Start
      loginOtptime: Date.now() + 60 * 1000,
      doctorLoginOtpVerify: "",
      loginOtpVerify: "",
      remainingTime: 60,
      getVerifyLoginOtpData: [],
      invalidAccount: false,
      invalidLoginOtp: false,
      getVerifyLoginDoctorOtpData: [],
      IsLoginotpExpired: false,
      doctorResendData: "",
      patientLoginData: "",
      doctor_id: ""
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    this.setState({
      loginOtpVerify: this.props.location.state?.loginOtpValue,
      patientLoginData: this.props.location.state?.patientData?.attributes?.full_phone_number
    })
    this.setState({
      doctorResendData: this.props.location.state?.doctorLoginOtpValue?.attributes,
      doctorLoginOtpVerify: this.props.location.state?.doctorLoginOtpValue?.attributes?.pin
    })
    this.getPatientDetails()
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if(responseJson.message){
      if(apiRequestCallId === this.getPatientStatusApiCallId){
        localStorage.setItem("onlineStatus", 'true')
          this.checkPatientIsLogin()
      }
      if(apiRequestCallId === this.getDoctorStatusApiCallId){
        localStorage.setItem("onlineDoctorStatus", 'true')
        const is_kyc = localStorage.getItem("is_kyc");
        is_kyc === "approved" ? this.props.history.push({ pathname: "/doctorhome", state: { docDetails: responseJson.data } }) : this.props.history.push("/doctorprofiledetails")
        localStorage.setItem("isLogin", "true")
      }
    }
      if (this.isLoginOtpValidResponse(responseJson)) {
        this.loginOtpApiSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (this.isLoginOtpInValidResponse(responseJson)) {
        this.loginOtpApiFailureCallBacks(apiRequestCallId, responseJson);
      } else if (errorReponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  isLoginOtpValidResponse = (responseJson: any) => {
    return responseJson && responseJson.data;
  };
  // Customizable Area End

  // Customizable Area Start
  isLoginOtpInValidResponse = (responseJson: any) => {
    return responseJson && responseJson.errors;
  };
  // Customizable Area End

  // Customizable Area Start
  loginOtpApiSuccessCallBacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getVerifyLoginOtpApiCallId) {
      this.loginVerifyOtpSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getDoctorVerifyLoginOtpApiCallId) {
      this.doctorLoginVerifyOtpSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getDoctorResendOtpApiCallId) {
      this.doctorResendOtpLoginSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getPatientResendOtpApiCallId) {
      this.resendPatientSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getPatientDetailsApiCallId) {

      localStorage.setItem(
        "profile_photo",
        responseJson.data.attributes.profile_photo
      );
      localStorage.setItem(
        "patientDetails",
        JSON.stringify(responseJson.data.attributes)
      );
    }
  };
  // Customizable Area End

  // Customizable Area Start
  loginOtpApiFailureCallBacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getVerifyLoginOtpApiCallId) {
      this.loginVerifyOtpfailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.getDoctorVerifyLoginOtpApiCallId) {
      this.doctorLoginVerifyOtpfailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.getDoctorResendOtpApiCallId) {
      this.doctorResendOtpLoginFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.getPatientResendOtpApiCallId) {
      this.resendPatientFailureCallBack(responseJson);
    }
  };
  // Customizable Area End

  // Customizable Area Start

  handleOTPChange = (otp: string) => {
    this.setState({ loginOtpVerify: otp, invalidLoginOtp: false });
  };

  handleDoctorOTPChange = (otp: string) => {
    this.setState({ doctorLoginOtpVerify: otp, invalidLoginOtp: false });
  };

  getVerifyLoginOtp = async () => {
    this.getVerifyLoginOtpApiCallId = ApiCall({
      contentType: configJSON.getLoginOtpApiContentType,
      method: configJSON.getLoginOtpApiMethod,
      endPoint: `${configJSON.getVerifyLoginOtpAPiEndPoint}?pin=${this.state.loginOtpVerify}`,
    });
  };

  checkPatientIsLogin = () => {
    let patnname = `${this.state.doctor_id ? "/patient-appointment" : "/patienthome"}`
    this.props.history.push(patnname)
    localStorage.setItem("isLogin", "true")
  }
  loginVerifyOtpSuccessCallBack = (res: any) => {
    this.getPatientStatus()
    this.setState({
      getVerifyLoginOtpData: res.data
    })
    notification["success"]({
      message: "Patient Login successfully",
    });

    const doctor_id = localStorage.getItem("doctor_id");
    this.setState({ doctor_id });
    
  }
 getPatientStatus = async () => {
    this.getPatientStatusApiCallId = ApiCall({
      contentType: configJSON.postSignUpOtpApiContentType,
      method: configJSON.putMethods,
      endPoint: `${configJSON.putOnlineStatusEndPoints}?user_type=patient&online_status=true`,
    });
  };
  getDoctorStatus = async () => {
    this.getDoctorStatusApiCallId = ApiCall({
      contentType: configJSON.postSignUpOtpApiContentType,
      method: configJSON.putMethods,
      endPoint: `${configJSON.putOnlineStatusEndPoints}?user_type=doctor&online_status=true`,
    });
  };
  loginVerifyOtpfailureCallBack = (err: any) => {
    if (err.errors[0].pin === "Please enter a valid OTP") {
      this.setState({
        invalidLoginOtp: true,
      });

    }
  }


  // async componentDidUpdate(preProps:Props,preState:S){


  // }
  getDoctorVerifyLoginOtp = async () => {
    this.getDoctorVerifyLoginOtpApiCallId = ApiCall({
      contentType: configJSON.getLoginOtpApiContentType,
      method: configJSON.getLoginOtpApiMethod,
      endPoint: `${configJSON.getVerifyDoctorLoginOtpAPiEndPoint}?pin=${this.state.doctorLoginOtpVerify}`,
    });
  };

  doctorLoginVerifyOtpSuccessCallBack = (res: any) => {
    localStorage.setItem("token", res.meta.token);
    this.getDoctorStatus()
    this.setState({
      getVerifyLoginDoctorOtpData: res.data
    })
    localStorage.setItem("doctorId", res.data?.attributes?.doctor_id);
    localStorage.setItem("is_kyc", res.data?.attributes?.is_kyc);
    notification["success"]({
      message: "Doctor Login successfully",
    });
    
  }

  doctorLoginVerifyOtpfailureCallBack = (err: any) => {
    if (err.errors[0].pin === "Please enter a valid OTP") {
      this.setState({
        invalidLoginOtp: true,
      });
    }
  }

  OtpLoginFinishTime = () => {
    this.setState({
      IsLoginotpExpired: true,
    });
  };

  getResendDoctorLoginOtp = async () => {
    this.setState({
      doctorLoginOtpVerify: ""
    })
    localStorage.clear();
    this.getDoctorResendOtpApiCallId = ApiCall({
      contentType: configJSON.getLoginOtpApiContentType,
      method: configJSON.getLoginOtpApiMethod,
      endPoint: this.state.doctorResendData?.full_phone_number ? `${configJSON.getResendDoctorLoginAPiEndPoint}?full_phone_number=${this.state.doctorResendData?.full_phone_number}` : `${configJSON.getResendDoctorLoginAPiEndPoint}?email=${this.state.doctorResendData?.email}`,
    });
  };

  doctorResendOtpLoginSuccessCallBack = (res: any) => {
    localStorage.setItem("token", res.meta.token);
    this.setState({
      doctorLoginOtpVerify: res.data.attributes.pin,
      IsLoginotpExpired: false,
      loginOtptime: Date.now() + 60 * 1000
    })
  }

  doctorResendOtpLoginFailureCallBack = (err: any) => {
    console.log(err)
  }

  resendPatientOtp = async () => {
    this.setState({
      loginOtpVerify: ""
    })
    localStorage.clear();
    this.getPatientResendOtpApiCallId = ApiCall({
      contentType: configJSON.getLoginOtpApiContentType,
      method: configJSON.getLoginOtpApiMethod,
      endPoint: `${configJSON.getResendpatientOtpAPiEndPoint}?full_phone_number=${this.state.patientLoginData}`,
    });
  };
  getPatientDetails = async () => {
    this.getPatientDetailsApiCallId = ApiCall({
      contentType: configJSON.getPatientDetailsApiContentType,
      method: configJSON.getPatientDetailsMethod,
      endPoint: `${configJSON.getPatientDetailsAPiEndPoint}`,
    });
  };

  resendPatientSuccessCallBack = (res: any) => {
    localStorage.setItem("token", res.meta.token);
    this.setState({
      loginOtpVerify: res.data.attributes.pin,
      IsLoginotpExpired: false,
      loginOtptime: Date.now() + 60 * 1000
    })

  }

  resendPatientFailureCallBack = (err: any) => {
    console.log(err)
  }


  // Customizable Area End


}
// Customizable Area End
