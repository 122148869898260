import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import ApiCall from "../../../components/src/ApiCall.web";
import jwt_decode from "jwt-decode";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    id: string;
    history: any;
    location: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    selectLoginRole:string,
    changeIcon: boolean,
    loginRoleData: any,
    remainingTime: number;
    loginMobileNumber:string;
    getLoginOtpData: any;
    loginOtpValue: string;
    loginDoctorOtpValue:any;
    invalidAccount: boolean;
    inValidDoctorAccount:boolean;
    sendEmailOrNot:boolean;
    setDoctorInputValue:string;
    getDoctorLoginOtpData:any;
    loginEmail:string,
    googleLoginProfile:any,
    selectRole:any,
    userDetails:any,
    isGoogleLogin:boolean,
    active_role:string
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}

export default class LoginController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getLoginApiCallId: string = "";
    getDoctorLoginApiCallId:string = ""
    istimer: any;
    googleLoginApiCallId:string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.CountryCodeMessage),
        ];

        this.state = {
            // Customizable Area Start
            loginEmail:"vivek@mail.com",
            getDoctorLoginOtpData:[],
            setDoctorInputValue:"",
            selectLoginRole:"Doctor",
            changeIcon: false,
            loginRoleData:  [
                {
                  role: "Doctor",
                  id: "1"
                },
                {
                  role: "Patient",
                  id: "2"
                },
              ],
            sendEmailOrNot:true,
            loginMobileNumber: "9109080842",
            loginOtpValue: "",
            remainingTime: 60,
            getLoginOtpData: [],
            invalidAccount: false,
            inValidDoctorAccount:false,
            loginDoctorOtpValue:[],
            googleLoginProfile:null,
            selectRole:"",
            userDetails:null,
            isGoogleLogin:false,
            active_role:""
            // Customizable Area End
        };
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start


    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.status === 500) {
                MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
                return;
            }
            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (this.isLoginValidResponse(responseJson)) {
                this.loginApiSuccessCallBacks(apiRequestCallId, responseJson);
            } else if (this.isLoginInValidResponse(responseJson)) {
                this.loginApiFailureCallBacks(apiRequestCallId, responseJson);
            } else if (errorReponse) {
                notification["error"]({
                    message: "Internal Server Error.",
                });
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    isLoginValidResponse = (responseJson: any) => {
        return responseJson && responseJson.data;
    };
    // Customizable Area End

    // Customizable Area Start
    isLoginInValidResponse = (responseJson: any) => {
        return responseJson && responseJson.errors;
    };
    // Customizable Area End

    // Customizable Area Start
    loginApiSuccessCallBacks = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.getLoginApiCallId) {
            this.loginSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getDoctorLoginApiCallId) {
            this.doctorLoginSuccessCallBack(responseJson);
        }
        if(apiRequestCallId === this.googleLoginApiCallId){
            this.handleGoogeLoginSuccessCallBack(responseJson);
        }
    };
    // Customizable Area End

    // Customizable Area Start

    handleGoogeLoginSuccessCallBack=(responseJson:any)=>{
        localStorage.setItem("token",responseJson.meta.token);
        localStorage.setItem("is_new",responseJson.meta.is_new);

        switch (this.state.active_role) {
            case "Patient":
                notification["success"]({
                    message:"Login Successfully",
                });
                localStorage.setItem("full_name", responseJson.data.attributes.full_name);
                if (!responseJson.meta.is_new) {
                    this.props.history.push({ pathname: "/patienthome", state: { userDetails: this.state.userDetails } })
                } else {
                    this.props.history.push({ pathname: "/PatientUpdate", state: { userDetails: this.state.userDetails, comingfrom_signup: true } })
                }
                break;
            case "Doctor":
                notification["success"]({
                    message:"Login Successfully",
                });
                if(responseJson.data.attributes.is_kyc ==="approved"){
                    this.props.history.push({ pathname: "/doctorhome", state: { userDetails: this.state.userDetails } });
                    localStorage.setItem("is_kyc", responseJson.data.attributes.is_kyc);
                    localStorage.setItem("doctorId", responseJson.data.attributes.doctor_id);

                }else{
                    if(responseJson.data.attributes?.registration_no){
                        this.props.history.push({ pathname: "/doctorprofiledetails", state: { userDetails: this.state.userDetails } });
                        localStorage.setItem("is_kyc", responseJson.data.attributes.is_kyc);
                       localStorage.setItem("doctorId", responseJson.data.attributes.doctor_id);
                    }else{
                        this.props.history.push({ pathname: "/editdoctordetails", state: { userDetails: this.state.userDetails } });
                        localStorage.setItem("is_kyc", responseJson.data.attributes.is_kyc);
                       localStorage.setItem("doctorId", responseJson.data.attributes.doctor_id);
                    }
                }
                break;
            default:
        }

    }
    loginApiFailureCallBacks = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.getLoginApiCallId) {
            this.loginFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.getDoctorLoginApiCallId) {
            this.doctorLoginFailureCallBack(responseJson);
        }
    };
    // Customizable Area End

    // Customizable Area Start

    handleLoginMobile = (e: any) => {
        this.setState({
            setDoctorInputValue: e.target.value,
            invalidAccount:false,
            sendEmailOrNot: false
        })
    }


    getLoginOtp = async () => {
        this.getLoginApiCallId = ApiCall({
            contentType: configJSON.getLoginApiContentType,
            method: configJSON.getLoginApiMethod,
            endPoint: `${configJSON.getLoginOtpAPiEndPoint}?full_phone_number=91${this.state.setDoctorInputValue}`,
        });
    };

    loginSuccessCallBack = (res: any) => {
        localStorage.setItem("token", res.meta.token);
        notification["success"]({
            message: res.meta.message,
        });
        this.setState({
            getLoginOtpData: res.data,
            loginOtpValue: res.data.attributes.pin
        })
        this.props.history.push({ pathname: "/loginotp", state: { loginOtpValue: this.state.loginOtpValue ,patientData : this.state.getLoginOtpData } })
    }

    loginFailureCallBack = (err: any) => {
        if (err.errors[0].failed_login === "Patient not found, or not activated") {
            this.setState({
                invalidAccount: true,
                inValidDoctorAccount:false
            });
        }
    }

    
    handleLoginSelectRole = (value: any) => {
        this.setState({
          selectLoginRole: value,
        })
      }

    getDoctorLoginOtp = async () => {
        this.getDoctorLoginApiCallId = ApiCall({
            contentType: configJSON.getLoginApiContentType,
            method: configJSON.getLoginApiMethod,
            endPoint:  this.state.sendEmailOrNot === false ? `${configJSON.getDoctorLoginAPiEndPoint}?full_phone_number=91${this.state.setDoctorInputValue}` : `${configJSON.getDoctorLoginAPiEndPoint}?email=${this.state.setDoctorInputValue}`,
        });
    };

    doctorLoginSuccessCallBack = (res: any) => {
        localStorage.setItem("token", res.meta.token);
        notification["success"]({
            message: res.meta.message,
        });
        this.setState({
            getDoctorLoginOtpData: res.data,
            loginDoctorOtpValue: res.data.attributes.pin
        })

        this.props.history.push({ pathname: "/loginotp", state: { doctorLoginOtpValue: this.state.getDoctorLoginOtpData } })
    }

    doctorLoginFailureCallBack = (err: any) => {
        if (err.errors[0].failed_login) {
            this.setState({
                inValidDoctorAccount: true,
                invalidAccount:false

            });
        }  
    }

    validateDoctorInput = (e: any) => {
        let value = e.target.value
        const emailRegex = /^[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const numberRegex = /^-?\d+(\.\d+)?$/;
    
        if (emailRegex.test(value)) {
          this.setState({
            setDoctorInputValue: value,
            sendEmailOrNot: true,
            inValidDoctorAccount:false
          })
        }
        else if (numberRegex.test(value)) {
          this.setState({
            setDoctorInputValue: value,
            sendEmailOrNot: false,
            inValidDoctorAccount:false
          })
        }
      };
    
      handleGoogleLoginResponse = (credentialResponse: any) => {

        const decoded: any = jwt_decode(credentialResponse.credential)

        localStorage.setItem("jwt_token", decoded?.token);
        localStorage.setItem("isPatientGoogleLogin", JSON.stringify(true));
        localStorage.setItem("profile_pic", decoded?.picture);
        localStorage.setItem("googleLoginDetails", JSON.stringify(decoded));
    
        this.setState({googleLoginProfile:decoded?.picture})

        const active_role = this.state.selectLoginRole || "Doctor";

        if(active_role === "Doctor"){
          localStorage.setItem("isDoctorGoogleLogin", JSON.stringify(true));
          localStorage.removeItem("isPatientGoogleLogin")
        }else{
          localStorage.setItem("isPatientGoogleLogin", JSON.stringify(true));
          localStorage.removeItem("isDoctorGoogleLogin");
        }
    
        this.doGoogle_Login(decoded,active_role);
        
        this.setState({userDetails:decoded,isGoogleLogin:true,active_role:active_role});  
    }

    handleGoogleLoginFailure = () => {

    }


    doGoogle_Login = (userInfo: any,active_role:string) => {
    
        const Patient_login_body = {
          "data": {
            "attributes": {
              "full_name": userInfo?.name,
               "email_id": userInfo?.email,
              "unique_auth_id": userInfo?.sub,
              "provider": "google"
            }
          }
        }
    
        const Doctor_login_body = {
          "data": {
            "attributes": {
              "full_name":userInfo?.name,
              "email": userInfo?.email,
              "unique_auth_id":userInfo?.sub,
              "provider": "google"
                 }   
          }
        }
    
        const header = {
          "Content-Type": "application/json",
        };
    
        let body = active_role === "Doctor" ? Doctor_login_body : Patient_login_body
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.googleLoginApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${ active_role === "Doctor" ? configJSON.doctorgoogleLoginEndPoint : configJSON.patientgoogleLoginEndPoint}`
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "POST"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }

    // Customizable Area End

}
// Customizable Area End