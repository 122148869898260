import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import ApiCall from "../../../components/src/ApiCall.web";
import { RouterProps } from 'react-router';
import { withLoaderProps } from "../../../components/src/MerilLoader.web";
// Customizable Area End

export const configJSON = require("./config");

export type Props = RouterProps &
    withLoaderProps & {
        // Customizable Area Start
        id: string;
        history: any;
        location: any;
        // Customizable Area End
    }

// Customizable Area Start
export interface OurServicesType {
    id: string,
    type: string,
    attributes: {
        title: string,
        description: string,
        service_image: string
    }
}
// Customizable Area End
// Customizable Area Start
export interface TopHospitalsType {
    id: string,
    type: string,
    attributes: {
        id: number,
        name: string,
        distance: number,
        hospital_image: Array<HospitalsImagesType>,
        rating:number,
        total_reviews:number
    }
}
// Customizable Area End 

// Customizable Area Start
export interface HospitalsImagesType {
    id: number,
    url: string
}
// Customizable Area End

// Customizable Area Start
export interface TrendingType {
    id: string,
    type: string,
    attributes: {
        id: number,
        featured_therapy_id: number,
        therapy_name: string,
        therapy_images: Array<HospitalsImagesType>
    }
}
// Customizable Area End 
// Customizable Area Start
export interface TopDoctorsType {
    id: string,
    type: string,
    attributes: {
        full_name: string,
        rating: [
            {
                rating: number,
            }
        ],
        reviews: number,
        image: string,
        profile_image: string,
    },
}
// Customizable Area End 
// Customizable Area Start
export interface LabTestsType {
    id: string,
    type: string,
    attributes: {
        name: string,
        description: string,
        total_no_of_test: number,
        sample_type: string,
        fasting_required: string,
        price: number,
        lab_image: string,
    }
}
// Customizable Area End 
// Customizable Area Start
export interface RadioLogyTestsType {
    id: string,
    type: string,
    attributes: {
        name: string,
        description: string,
        total_no_of_test: number,
        sample_type: string,
        fasting_required: string,
        price: number,
        radiology_image: string,
    }
}
// Customizable Area End 

// Customizable Area Start
export interface ShortVideosListType {
    id: string,
    type: string,
    attributes: {
        id: number,
        name: string,
        description: string,
        type: string,
        doctor_id: number,
        status: string,
        stories: [
            {
                id: number,
                url: string,
            }
        ]
    }
}
// Customizable Area End 

// Customizable Area Start
export interface TopCardioType {
    id: string,
    type: string,
    attributes: {
        id: number,
        therapy_name: string,
        therapy_images: Array<HospitalsImagesType>
    }
}
// Customizable Area End 

// Customizable Area Start
export interface TopOrthoType {
    id: string,
    type: string,
    attributes: {
        id: number,
        therapy_name: string,
        therapy_images: Array<HospitalsImagesType>
    }
}
// Customizable Area End 

interface S {
    // Customizable Area Start
    isLoginUser: boolean;
    ourServicesData: Array<OurServicesType>;
    topDoctorsData: Array<TopDoctorsType>;
    trendingTherapiesData: Array<TrendingType>;
    topHospitalsData: Array<TopHospitalsType>;
    labTestData: Array<LabTestsType>;
    radioLogyTestData: Array<RadioLogyTestsType>;
    trendingTherapiesDataSliced: boolean;
    topDoctorsDataSliced: boolean;
    labTestDataSliced: boolean;
    radioLogyTestDataSliced: boolean;
    shortVideoListData: Array<ShortVideosListType>;
    ourServicesDataSliced: boolean;
    topHospitalsDataSliced: boolean;
    shortVideosDataSliced: boolean;
    topCardiovascularData: Array<TopCardioType>;
    topOrthopaedicData: Array<TopOrthoType>;
    topCardiovascularDataSliced: boolean;
    topOrthopaedicDataSliced: boolean;
    topCardiologistDoctorsData: Array<TopDoctorsType>;
    topOrthopaedicDoctorsData: Array<TopDoctorsType>;
    topCardiologistDataSliced: boolean;
    topOrthopaedicDoctorsDataSliced: boolean;
    postCovidCareData: any;
    showPopup: boolean;
    isUserLogin: any
    errorMessage: string;
    doctorDetailsData: object;
    isModalVisible:boolean;
    selectedStoryIndex:number
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}

export default class LandingpageController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getOurServicesApiCallId: string | null = null;
    getTopDoctorsApiCallId: string | null = null;
    getTrendingTherapiesApiCallId: string | null = null;
    getLabTestApiCallId: string | null = null;
    getRadioLogyTestApiCallId: string | null = null;
    getTopHospitalsApiCallId: string | null = null;
    getShortVideoListApiCallId: string | null = null;
    getTopCardiovascularApiCallId: string | null = null;
    getTopOrthopaedicApiCallId: string | null = null;
    getTopCardiologistDoctorsApiCallId: string | null = null;
    getTopOrthopaedicsDoctorsApiCallId: string | null = null;
    getPostCarePackageApiCallId: string | null = null;
    getDoctorProfileApiCallId: string | null = null;
    patientDetailsAPICalledId: string | null = null;
    // Customizable Area Enddata:

    constructor(props: Props) {
        super(props);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.CountryCodeMessage),
        ];

        this.state = {
            // Customizable Area Start
            isLoginUser: true,
            ourServicesData: [],
            topDoctorsData: [],
            trendingTherapiesData: [],
            labTestData: [],
            radioLogyTestData: [],
            topHospitalsData: [],
            trendingTherapiesDataSliced: true,
            topDoctorsDataSliced: true,
            labTestDataSliced: true,
            radioLogyTestDataSliced: true,
            shortVideoListData: [],
            ourServicesDataSliced: false,
            topHospitalsDataSliced: false,
            shortVideosDataSliced: false,
            topCardiovascularData: [],
            topOrthopaedicData: [],
            topCardiovascularDataSliced: true,
            topOrthopaedicDataSliced: true,
            topCardiologistDoctorsData: [],
            topOrthopaedicDoctorsData: [],
            topCardiologistDataSliced: true,
            topOrthopaedicDoctorsDataSliced: true,
            postCovidCareData: [],
            showPopup: false,
            isUserLogin: "",
            errorMessage: "",
            doctorDetailsData: {},
            isModalVisible:false,
            selectedStoryIndex: 0
            // Customizable Area End
        };
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start

    async componentDidMount() {
        this.getOurServicesShow();
        this.getTrendingTherapiesShow();
        this.getLabTestShow();
        this.getRadioLogyTestShow();
        this.getShortVideosListShow();
        this.getTopOrthopaedicShow();
        this.getTopCardiovascularProcedureShow();
        this.getTopCardiologistDocShow();
        this.getTopOrthopaedicsDocShow();
        this.getPostCarePackageShow();
        this.getDoctorProfileDetails();
        this.getPatientDetails();
        const is_kyc = localStorage.getItem("is_kyc");
        if (is_kyc) {
            this.props.history.push("/doctorhome");
        }
        localStorage.removeItem("doctor_id");
        localStorage.removeItem("doctor_category");
        setTimeout(()=>{
                const cityname = localStorage.getItem("cityname") ;
                if(cityname){
                    this.getTopDoctorsShow();
                    this.getTopCardiologistDocShow()
                }
            },6000)    
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            let landingpageResJdon = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (landingpageResJdon.status === 500) {
                MESSAGE.error(`${landingpageResJdon.error}. Please try again later.`, 4);
                return;
            }
            if (this.isValidResponse(landingpageResJdon)) {
                this.apiSuccessCallBacks(apiRequestCallId, landingpageResJdon);

            } else if (this.isInValidResponse(landingpageResJdon)) {
                this.apiFailureCallBacks(apiRequestCallId, landingpageResJdon);

            } else if (errorReponse) {
                notification["error"]({
                    message: "Internal Server Error.",
                });

            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        const locationInput = document.getElementById("location-input");
        if(prevState.topDoctorsData !== this.state.topDoctorsData){
            locationInput?.removeEventListener("blur", this.handleBlur);
            locationInput?.addEventListener("blur",this.handleBlur)
        }       
    }

    handleBlur=()=>{
            this.getTopDoctorsShow();
            this.getTopCardiologistDocShow()
    }
    isValidResponse = (responseJson: any) => {
        return responseJson && responseJson.data;
    };
    // Customizable Area End

    // Customizable Area Start
    isInValidResponse = (responseJson: any) => {
        return responseJson && responseJson.errors || responseJson && responseJson.error ;
    };
    // Customizable Area End

    // Customizable Area Start
    apiSuccessCallBacks = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.getTopCardiovascularApiCallId) {
            this.getTopCardiovascularSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getOurServicesApiCallId) {
            this.getOurServicesSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getTopDoctorsApiCallId) {
            this.getTopDoctorsSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getTrendingTherapiesApiCallId) {
            this.getTrendingTherapiesSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getLabTestApiCallId) {
            this.getLabTestSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getRadioLogyTestApiCallId) {
            this.getRadioLogyTestSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getTopHospitalsApiCallId) {
            this.getTopHospitalsSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getShortVideoListApiCallId) {
            this.getShortVideosListsSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getTopOrthopaedicApiCallId) {
            this.getTopOrthopaedicSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getTopCardiovascularApiCallId) {
            this.getTopCardiovascularSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getTopCardiologistDoctorsApiCallId) {
            this.getTopCardiologistDocSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getTopOrthopaedicsDoctorsApiCallId) {
            this.getToporthopaedicDocSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getPostCarePackageApiCallId) {
            this.getPostCarePackageSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getDoctorProfileApiCallId) {
            this.getDoctorProfileDetailSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.patientDetailsAPICalledId) {
            this.handePatientDetails(responseJson)
        }
    };
    // Customizable Area End

    // Customizable Area Start
    apiFailureCallBacks = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.getOurServicesApiCallId) {
            this.getOurServicesFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.getTopDoctorsApiCallId) {
            this.getTopDoctorsFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.getTrendingTherapiesApiCallId) {
            this.getTrendingTherapiesFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.getLabTestApiCallId) {
            this.getLabTestFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.getRadioLogyTestApiCallId) {
            this.getRadioLogyTestFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.getTopHospitalsApiCallId) {
            this.getTopHospitalsFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.getShortVideoListApiCallId) {
            this.getShortVideosListsFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.getTopCardiovascularApiCallId) {
            this.getTopCardiovascularFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.getTopOrthopaedicApiCallId) {
            this.getTopOrthopaedicFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.getTopCardiologistDoctorsApiCallId) {
            this.getTopCardiologistDocFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.getTopOrthopaedicsDoctorsApiCallId) {
            this.getTopOrthopaedicDocFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.getPostCarePackageApiCallId) {
            this.getPostCarePackageFailureCallBack(responseJson);
        }
    };
    // Customizable Area End


    handePatientDetails = (responseJson: any) => {
        if (responseJson.data.attributes.profile_photo) {
            localStorage.setItem("profile_pic", responseJson.data.attributes.profile_photo)
        }
        localStorage.setItem(
            "patientDetails",
            JSON.stringify(responseJson.data.attributes)
        );
        localStorage.setItem("health_id", responseJson.data.attributes.health_id);
        localStorage.setItem("full_name", responseJson.data.attributes.full_name);
    }

    handleServicesData = () => {
        this.setState({
            ourServicesDataSliced: !this.state.ourServicesDataSliced
        })
    }

    handleTherapiesData = () => {
        this.setState({
            trendingTherapiesDataSliced: !this.state.trendingTherapiesDataSliced
        })
    }

    handleDoctorsNearbyData = () => {
        this.setState({
            topDoctorsDataSliced: !this.state.topDoctorsDataSliced
        })
    }

    handleLabTestData = () => {
        this.setState({
            labTestDataSliced: !this.state.labTestDataSliced
        })
    }

    handleRadiologyData = () => {
        this.setState({
            radioLogyTestDataSliced: !this.state.radioLogyTestDataSliced
        })
    }

    handleTopHospitalsData = () => {
        this.setState({
            topHospitalsDataSliced: !this.state.topHospitalsDataSliced
        })
    }

    handleShortVideossData = () => {
        this.setState({
            shortVideosDataSliced: !this.state.shortVideosDataSliced
        })
    }

    getOurServicesShow = async () => {
        this.getOurServicesApiCallId = ApiCall({
            contentType: configJSON.getOurservicesApiContentType,
            method: configJSON.getOurservicesAPiMethod,
            endPoint: `${configJSON.getOurservicesAPiEndPoint}?is_request=web`,
        });
    };

    getOurServicesSuccessCallBack = (res: any) => {

        let filterOurServices: Array<OurServicesType> = res.data.filter((service: any) => service.attributes.title === "Book Appointment")
        this.setState({
            ourServicesData: filterOurServices
        })
    }

    getOurServicesFailureCallBack = (err: any) => {
        this.setState({ errorMessage: err });
    }

    handleOurServices = (item: any) => {

        const token: any = localStorage.getItem("isLogin");
        const isPatientGoogleLogin: any = localStorage.getItem("isPatientGoogleLogin");
        this.setState({ isLoginUser: token })

        switch (item.attributes.title) {
            case "Book Appointment":
                token || isPatientGoogleLogin ? this.props.history.push("/patient-appointment-landingpage") : this.props.history.push("/login");
                break;
            case "Order Medicines":
                alert("Coming soon...")
                break
            case "Lab Test":
                alert("Coming soon....")
                break
            case "Book Surgical Procedures":
                alert("Coming soon....")
                break
            case "Book Genetic Tests":
                alert("Coming soon....")
                break
            case "Book Radiology Tests":
                alert("Coming soon....")
                break
        }
    }


    handletrendingTherapies = (item: any) => {
        const token = localStorage.getItem("isLogin");
        if (token) {
            this.props.history.push({ pathname: "patienthome/therapiesDetails", state: { therapiesDetails: item } })

        } else {
            this.props.history.push({ pathname: "/therapiesDetails", state: { therapiesDetails: item } })
        }
    }

    getTopDoctorsShow = async () => {
        let cityname = await JSON.parse(localStorage.getItem("cityname") as any);
        this.getTopDoctorsApiCallId = ApiCall({
            contentType: configJSON.getTopDoctorsApiContentType,
            method: configJSON.getTopDoctorsAPiMethod,
            endPoint: `account_block/search_doctor_top_rating?city=${cityname}`,
        });
    };

    getTopDoctorsSuccessCallBack = (res: any) => {
        this.props.hideLoader();
        this.setState({
            topDoctorsData: res.data
        })   
    }

    getTopDoctorsFailureCallBack = (err: any) => {
        this.props.hideLoader();
        this.setState({ errorMessage: err,topDoctorsData:[] });
    }

    getTrendingTherapiesShow = async () => {
        this.getTrendingTherapiesApiCallId = ApiCall({
            contentType: configJSON.getTrendingTherapiesApiContentType,
            method: configJSON.getTrendingTherapiesAPiMethod,
            endPoint: `${configJSON.getTrendingTherapiesAPiEndPoint}?is_request=web`,
        });
    };

    getTrendingTherapiesSuccessCallBack = (res: any) => {
        this.setState({
            trendingTherapiesData: res.data
        })
    }

    getTrendingTherapiesFailureCallBack = (err: any) => {
        this.setState({ errorMessage: err });
    }

    getLabTestShow = async () => {
        this.getLabTestApiCallId = ApiCall({
            contentType: configJSON.getLabTestApiContentType,
            method: configJSON.getLabTestAPiMethod,
            endPoint: `${configJSON.getLabTestAPiEndPoint}?is_request=web`,
        });
    };

    getLabTestSuccessCallBack = (res: any) => {
        this.setState({
            labTestData: res.data
        })
    }

    getLabTestFailureCallBack = (err: any) => {
        this.setState({ errorMessage: err });
    }

    getRadioLogyTestShow = async () => {
        this.getRadioLogyTestApiCallId = ApiCall({
            contentType: configJSON.getLabTestApiContentType,
            method: configJSON.getLabTestAPiMethod,
            endPoint: `${configJSON.getRadioLogyTestAPiEndPoint}?is_request=web`,
        });
    };

    getRadioLogyTestSuccessCallBack = (res: any) => {
        this.setState({
            radioLogyTestData: res.data
        })
    }

    getRadioLogyTestFailureCallBack = (err: any) => {
        this.setState({ errorMessage: err });
    }

    getTopHospitalsShow = async () => {
        let hospital_cityname = await JSON.parse(localStorage.getItem("cityname") as any);
        this.getTopHospitalsApiCallId = ApiCall({
            contentType: configJSON.getLabTestApiContentType,
            method: configJSON.getLabTestAPiMethod,
            endPoint: `${configJSON.getTopHospitalsAPiEndPoint}?city=${hospital_cityname}&is_request=web`,
        });
    };

    getTopHospitalsSuccessCallBack = (res: any) => {
            this.setState({
                topHospitalsData: res.data
            })
    }

    getTopHospitalsFailureCallBack = (err: any) => {
        this.setState({ errorMessage: err,topHospitalsData:[] });
    }

    getShortVideosListShow = async () => {
        this.getShortVideoListApiCallId = ApiCall({
            contentType: configJSON.getLabTestApiContentType,
            method: configJSON.getLabTestAPiMethod,
            endPoint: `${configJSON.getShortVideosListAPiEndPoint}?user_type=doctor&is_request=web`,
        });
    };

    getShortVideosListsSuccessCallBack = (res: any) => {
        this.setState({
            shortVideoListData: res.data
        })
    }

    getShortVideosListsFailureCallBack = (err: any) => {
        this.setState({ errorMessage: err });
    }

    getTopCardiovascularProcedureShow = async () => {
        this.getTopCardiovascularApiCallId = ApiCall({
            contentType: configJSON.getLabTestApiContentType,
            method: configJSON.getLabTestAPiMethod,
            endPoint: `${configJSON.getTopCardiovascularAPiEndPoint}?is_request=web`,
        });
    };

    getTopCardiovascularSuccessCallBack = (res: any) => {
        this.setState({
            topCardiovascularData: res.data
        })
    }

    getTopCardiovascularFailureCallBack = (err: any) => {
        this.setState({ errorMessage: err });
    }

    getTopOrthopaedicShow = async () => {
        this.getTopOrthopaedicApiCallId = ApiCall({
            contentType: configJSON.getLabTestApiContentType,
            method: configJSON.getLabTestAPiMethod,
            endPoint: `${configJSON.getTopOrthopaedicAPiEndPoint}?is_request=web`,
        });
    };

    getTopOrthopaedicSuccessCallBack = (res: any) => {
        this.setState({
            topOrthopaedicData: res.data
        })
    }

    getTopOrthopaedicFailureCallBack = (err: any) => {
        this.setState({ errorMessage: err });
    }

    handleTopCardiovascularData = () => {
        this.setState({
            topCardiovascularDataSliced: !this.state.topCardiovascularDataSliced
        })
    }

    handleTopOrthopaedicData = () => {
        this.setState({
            topOrthopaedicDataSliced: !this.state.topOrthopaedicDataSliced
        })
    }

    handleTopCardiologistData = () => {
        this.setState({
            topCardiologistDataSliced: !this.state.topCardiologistDataSliced
        })
    }

    getTopCardiologistDocShow = async () => {
        let cityname = await JSON.parse(localStorage.getItem("cityname") as any);
        this.getTopCardiologistDoctorsApiCallId = ApiCall({
            contentType: configJSON.getLabTestApiContentType,
            method: configJSON.getLabTestAPiMethod,
            endPoint: `${configJSON.getTopDoctorsAPiEndPoint}?city=${cityname}&specialization_type=cardiologist`,
        });
    };

    getTopCardiologistDocSuccessCallBack = (res: any) => {
        this.setState({
            topCardiologistDoctorsData: res.data
        })
    }

    getTopCardiologistDocFailureCallBack = (err: any) => {
        this.setState({ errorMessage: err });
    }

    getTopOrthopaedicsDocShow = async () => {
        this.getTopOrthopaedicsDoctorsApiCallId = ApiCall({
            contentType: configJSON.getLabTestApiContentType,
            method: configJSON.getLabTestAPiMethod,
            endPoint: `${configJSON.getTopDoctorsAPiEndPoint}?is_request=web&specialization_type=orthopaedics`,
        });
    };

    getToporthopaedicDocSuccessCallBack = (res: any) => {
        this.setState({
            topOrthopaedicDoctorsData: res.data
        })
    }

    getTopOrthopaedicDocFailureCallBack = (err: any) => {
        this.setState({ errorMessage: err });
    }

    handleTopOrthopaedicsData = () => {
        this.setState({
            topOrthopaedicDoctorsDataSliced: !this.state.topOrthopaedicDoctorsDataSliced
        })
    }

    getPostCarePackageShow = async () => {
        this.getPostCarePackageApiCallId = ApiCall({
            contentType: configJSON.getOurservicesApiContentType,
            method: configJSON.getOurservicesAPiMethod,
            endPoint: `${configJSON.getPostCovidpackageAPiEndPoint}?advertisement_type=covid&is_request=web`,
        });
    };

    getPostCarePackageSuccessCallBack = (res: any) => {
        this.setState({
            postCovidCareData: res.data
        })
    }

    getPostCarePackageFailureCallBack = (err: any) => {
        this.setState({ errorMessage: err });
    }

    showDetailsHandler = () => {
        this.setState({ showPopup: !this.state.showPopup });
    }

    handleLogout = () => {
        localStorage.clear();
        notification["success"]({
            message: "Logout successfully",
        });
        this.setState({
            isLoginUser: false
        })
    };

    getDoctorProfileDetails = async () => {
        this.getDoctorProfileApiCallId = ApiCall({
            contentType: configJSON.getLabTestApiContentType,
            method: configJSON.getLabTestAPiMethod,
            endPoint: `${configJSON.getDoctorDetailsApiEndPoint}?is_request=web`,
        });
    };


    handleProfilePic = (res: any) => {
        const profileImage = res?.data?.attributes?.profile_image;
        if (profileImage) {
            localStorage.setItem("profile_pic", res.data.attributes.profile_image)
        }
    }
    getDoctorProfileDetailSuccessCallBack = (res: any) => {
        localStorage.setItem("doctorName", res.data.attributes.full_name);
        localStorage.setItem("doctorNum", res.data.attributes.full_phone_number);
        this.handleProfilePic(res)
        this.setState({
            doctorDetailsData: res
        });
    };

    handleDoctorDetailsPage = () => {
        this.props.history.push("/doctorprofiledetails");
    };

    handleEditDoctorProfile = () => {
        this.props.history.push("/editdoctordetails");
    };

    handlePopupClose = () => {
        this.setState({
            showPopup: false
        });
    };

    getPatientDetails = async () => {
        const token = localStorage.getItem("token") || ""
        this.patientDetailsAPICalledId = ApiCall({
            contentType: configJSON.getLabTestApiContentType,
            method: configJSON.getLabTestAPiMethod,
            token: token,
            endPoint: `${configJSON.getPatientDetails}`,
        });
    };

    logoutFunction = () => {
        localStorage.clear();
        notification["success"]({
            message: "Logout successfully",
        });
    };

    handleTrendingTherapiesNavigation = (item: any) => {
        const loginToken = localStorage.getItem("token");
        if (loginToken) {
            this.props.history.push({ pathname: "/therapiesDetails", state: { therapiesDetails: item } })
           
        } else {
            this.props.history.push({ pathname: "patienthome/therapiesDetails", state: { therapiesDetails: item } })
        }
    }
    showStories = (index:number) => {
        this.setState({ isModalVisible: true, selectedStoryIndex: index });
      };
      closeStories = () => {
        this.setState({ isModalVisible: false });
      };
}
// Customizable Area End
