import React from "react";
// Customizable Area Start
import ProfileSwitchAccountPageController from "./ProfileSwitchAccountPageController.web";
import PatientOutLet from "./ProfileOutlet.web";
import { Typography, Divider} from "antd";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {defaultImg} from './assets';
import { Modal, styled, Box } from '@material-ui/core';

// Customizable Area End

class PateintFamilyMembersList extends ProfileSwitchAccountPageController {
    // Customizable Area Start
    render() {
        const {memberListData} = this.state
        return (
            <div>
                <PatientOutLet {...this.props} selectedKey={"5"}>
                    <h5 className="addDetails">Add Family Member</h5>
                    {memberListData?.data?.map((data, index) => {
                        return (
                            <div key={index} onClick={() => this.handleEditIcon(data)} data-test-id="handleEditIcon">
                                <div className="showAccountDetails">
                                    <div className="details">
                                        <img src={data.attributes.profile_pic? data.attributes.profile_pic: defaultImg} alt="Selected"
                                          className="showProfileImage"/>
                                          <div>
                                        <h6>{data.attributes.full_name}</h6>
                                        </div>
                                    </div>
                                                <div
                                onClick={(event) => this.handleDeleteBtn(event, data.id)}
                                data-test-id="handleDeleteBtn"
                                className="deleteIcon"
                                >
                                    -
                                </div>
                                </div>
                                <Divider />
                            </div>
                        )
                    })}

                    <Typography className='addNewBtn' data-test-id="handleMemberDetailsOpen" onClick={this.handleMemberDetailsOpen}><AddCircleIcon style={{ marginRight: "20px", fontSize: "25px" }} /> <span>Add New Member</span></Typography>
                    <DeleteAppointmentModal
                    data-test-id="handleBack"
                    open={this.state.isModal}
                    onClose={this.handleBack}
                >
                    <ModalComponent>
                        <Box component={"h4"} data-test-id="deletetextid">Delete</Box>
                        <Box component={"p"}>
                            Are you sure you want to delete the appointment with the patient?
                        </Box>
                        <ButtonBox>
                            <NoButton onClick={this.handleBack} data-test-id="closemodalid">No</NoButton>
                            <YesButton data-test-id="deleteMemberDetailsApi" onClick={this.deleteMemberDetailsApi}>Yes, I'm sure</YesButton>
                        </ButtonBox>
                    </ModalComponent>
                </DeleteAppointmentModal>
                </PatientOutLet>
            </div>
        );
    }

    // Customizable Area End
}

// Customizable Area Start
export default PateintFamilyMembersList;
const DeleteAppointmentModal = styled(Modal)({
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
});
const YesButton = styled("button")({
    backgroundColor: "#3b78c2",
    color: "white",
    borderRadius: "25px",
    border: "2px solid #3b78c2",
    padding: "10px",
    width: "140px",
    fontWeight: "bold",
    cursor: "pointer",
    "@media (max-width:800px)": {
        fontSize: "13px",
        width: "130px"
    }
});

const ModalComponent = styled(Box)({
    textAlign: "center",
    padding: "50px 100px",
    borderRadius: '5px',
    backgroundColor: '#ffffff',
    outline: "none",
    margin: "20px",
    "@media (max-width:800px)": {
        padding: "10px 50px",
    },
    "@media (max-width:400px)": {
        padding: "10px 20px",
    }
});

const NoButton = styled("button")({
    color: "#3b78c2",
    border: "2px solid #3b78c2",
    borderRadius: "25px",
    padding: "10px",
    width: "140px",
    backgroundColor: "#ffffff",
    fontWeight: "bold",
    cursor: "pointer",
    "@media (max-width:800px)": {
        fontSize: "13px",
        width: "130px"
    }
});

const ButtonBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    margin: "10px 0px",
    columnGap: "10px"
});


// Customizable Area End
