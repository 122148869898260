import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { createRef } from "react";
// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import ApiCall from "../../../components/src/ApiCall.web";
import { RouterProps } from 'react-router';
import { withLoaderProps } from "../../../components/src/MerilLoader.web";
const is_kyc = localStorage.getItem("is_kyc");
export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
}

export interface SubCategoryData {
    data: Array<SubType>
}

export interface SubType {
 attributes: {
   id: string;
   name: string;
  }
}
// Customizable Area End

export const configJSON = require("./config");

export type Props = RouterProps &
  withLoaderProps & {
    // Customizable Area Start
    id: string;
    history: any;
    location: any;
    // Customizable Area End
  }

interface S {
  // Customizable Area Start
  openRegModal: boolean;
  openVerifyModal: boolean;
  identifyImg: any;
  degreeImg: any;
  registrationImg: any;
  clinicRegImg: any;
  identifyMaxSize: boolean;
  degreeMaxSize: boolean;
  registrationMaxSize: boolean;
  clinicRegMaxSize: boolean;
  name: string;
  doctorName: string;
  registrationNumber: boolean;
  yearData: any;
  specializationData: any;
  doctorCategoryData: any;
  doc_phone:string;
  doc_email:string;
  isLoader: boolean;
  contentHeight: number;
  selectCategory: string;
  selectSubCategory: string;
  subCategoryData: SubCategoryData;
  subCategory: string;
  isEmail: boolean;
  inputEmail: string;
  emailErr: string;
  phoneErr: string;
  registrationErr: string;
  inputPhone: string;
  isPhone: boolean;
  registartionValue: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class DoctorRegistController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  postDoctorRegisterApiCallId: string = "";
  getSubCategoryApiCallId: string = "";
  postDoctorVerifyApiCallId: string = "";
  getDoctorSpecializationApiCallId: string = "";
  upload: any;
  degreeUpload: any;
  clinicdegree: any;
  registration: any;
  getDoctorCategoryApiCalledId:string ="";
  getDoctorStatusApiCallId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      // Customizable Area Start
      openRegModal: true,
      openVerifyModal: false,
      identifyImg: "",
      degreeImg: "",
      registrationImg: "",
      clinicRegImg: "",
      identifyMaxSize: false,
      degreeMaxSize: false,
      registrationMaxSize: false,
      clinicRegMaxSize: false,
      name: "",
      doctorName: "",
      registrationNumber: false,
      yearData: [
        {
          value: "2001"
        },
        {
          value: "2002"
        },
        {
          value: "2008"
        },
        {
          value: "2005"
        },
        {
          value: "2017"
        },
        {
          value: "2011"
        },
        {
          value: "2020"
        },
      ],
      specializationData: [],
      doctorCategoryData:[],
      doc_phone:"",
      doc_email:"",
      isLoader:false,
      contentHeight:600,
      selectCategory: "",
      selectSubCategory: "",
      subCategoryData: {
        data: [
            {
              attributes: {
                id: "",
                name: ""
               }
          }
        ]
      },
      subCategory: "",
      isEmail: false,
      inputEmail: "",
      emailErr: "",
      phoneErr: "",
      registrationErr: "",
      inputPhone: "",
      isPhone: false,
      registartionValue: ""
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    this.setState({
      doctorName: this.props.location.state.doctorDetails.attributes.full_name,
      doc_phone: this.props.location.state.doctorDetails.attributes.full_phone_number ? this.removeCountryCode(this.props.location.state.doctorDetails.attributes.full_phone_number):"" ,
      doc_email:this.props.location.state.doctorDetails.attributes.email ? this.props.location.state.doctorDetails.attributes.email :"" ,
    })
    this.getSpecializationShow();
    this.getDoctorCategoryShow();
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }
      if(responseJson.message){
      if(apiRequestCallId === this.getDoctorStatusApiCallId){
        localStorage.setItem("onlineDoctorStatus", 'true')
      }
    }
      if (this.isDoctorRegisterValidResponse(responseJson)) {
        this.apiDoctorRegisterSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (this.isDoctorRegisterInValidResponse(responseJson)) {
        this.apiDoctorRegisterFailureCallBacks(apiRequestCallId, responseJson);
      } else if (errorReponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  isDoctorRegisterValidResponse = (responseJson: any) => {
    return (responseJson && responseJson.data) || (responseJson && responseJson.specialization);
  };
  // Customizable Area End

  // Customizable Area Start
  isDoctorRegisterInValidResponse = (responseJson: any) => {
    return responseJson && responseJson.errors;
  };
  // Customizable Area End

  // Customizable Area Start
  apiDoctorRegisterSuccessCallBacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.postDoctorRegisterApiCallId) {
      this.postDoctorRegisterSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.postDoctorVerifyApiCallId) {
      this.postDoctorVerifySuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getDoctorSpecializationApiCallId) {
      this.getSpecializationSuccessCallBack(responseJson);
    }
    if(apiRequestCallId === this.getDoctorCategoryApiCalledId){
      this.getDoctorCategorySuccessCallBack(responseJson);
    }
    if(apiRequestCallId === this.getSubCategoryApiCallId){
      this.getSubCategorySuccessCallBack(responseJson);
    }
  };
  // Customizable Area End

  // Customizable Area Start
  apiDoctorRegisterFailureCallBacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.postDoctorRegisterApiCallId) {
      this.postDoctorRegisterFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.postDoctorVerifyApiCallId) {
      this.postDoctorVerifyFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.getDoctorSpecializationApiCallId) {
      this.getSpecializationFailureCallBack(responseJson);
    }
  };
  // Customizable Area End

  onFinishDoctorRegister =  (values: { docName: string, docRegistration: string, docYear: string, docCity: string, medicalName: string, medicalContact: string, docRegistrationCouncil: string}) => {
    this.postDoctorRegister(values)
  }

  postDoctorRegister = async (values: { docName: string, docRegistration: string, docYear: string, docCity: string, medicalName: string, medicalContact: string, docRegistrationCouncil: string}) => {
    let apiBody = {
      data: {
        attributes: {
          full_name: this.state.doctorName,
          registration_no: this.state.registartionValue,
          year: values.docYear,
          specialization: this.state.subCategory,
          city: values.docCity,
          medical_representative_name: values.medicalName,
          representative_contact_no: values.medicalContact,
          registration_council: values.docRegistrationCouncil,
          doctor_category: this.state.selectCategory,
          full_phone_number:`91${this.state.doc_phone ? this.state.doc_phone : this.state.inputPhone}`,
          email:this.state.doc_email ? this.state.doc_email: this.state.inputEmail
        }
      }
    }

    this.postDoctorRegisterApiCallId = ApiCall({
      contentType: configJSON.postDoctorRegisterApiContentType,
      method: configJSON.postDoctorRegisterApiMethod,
      endPoint: configJSON.postDoctorRegisterAPiEndPoint,
      body: apiBody,
    });
    this.setState({isLoader:true})
  };

  postDoctorRegisterSuccessCallBack = (res: any) => {
    localStorage.setItem("token", res.meta.token);
    localStorage.setItem("doctorId", res.data?.attributes?.doctor_id);
    localStorage.setItem("is_kyc", res.data?.attributes?.is_kyc);
    localStorage.setItem("isLogin",JSON.stringify(true))
    notification["success"]({
      message: "Doctor created successfully",
    });
    this.getDoctorOnlineStatus()
    this.setState({
      openRegModal: false,
      openVerifyModal: true,
      isLoader:false
    })
  };
  getDoctorOnlineStatus = async () => {
    this.getDoctorStatusApiCallId = ApiCall({
      contentType: configJSON.postDoctorRegisterApiContentType,
      method: configJSON.putMethods,
      endPoint: `${configJSON.putOnlineStatusEndPoints}?user_type=doctor&online_status=true`,
    });
  };

  postDoctorRegisterFailureCallBack = async (err: any) => {
    this.setState({isLoader:false})
    if (err.errors[0].full_phone_number === "has already been taken.") {
      this.setState({
        phoneErr: `Phone number ${err.errors[0].full_phone_number}`
      });
    }else if(err.errors[0].email){
      this.setState({
        emailErr: err.errors[0].email
      });
    }else if(err.errors[0].registration_no){
      this.setState({
        registrationErr: err.errors[0].registration_no
      });
    }
  }

  handleError = () => {
    notification["error"]({
      message: "Please enter valid registration number",
    });
  };

  
  handleVerificationModal = () => {
    this.setState({
      openVerifyModal: false
    })
    is_kyc === "approved" ? this.props.history.push("/doctorhome") : this.props.history.push("/doctorprofiledetails")
  }

  handleIdentifierImage = (key: string, e: any) => {
    let identityFiles = e.target.files;
    if (identityFiles.length > 0) {
      let file = identityFiles[0];
      const extensionFile = file.name?.split('.').pop().toLowerCase();
      if (extensionFile === 'pdf') {
        let identitySize = file.size / (1024 * 1024)
        if (identitySize <= 10) {
          this.setState({
            identifyImg: identityFiles
          });
        }
        else {
          notification["error"]({
            message: "File size exceeds the limit. Please select a PDF file smaller than 10 MB.",
          });
        }
      } else {
        notification["error"]({
          message: "Invalid file type. Please select a valid pdf file.",
        });
      }
    }
  }

  navigateToUploadDoc = () => {
    this.props.history.push("/uploaddoc")
  };

  failedToUploadDoc = () => {
    notification["error"]({
      message: "Invalid file type. Please select a valid pdf file.",
    });
  } 
  
  showFileSizeErrorMessage=()=>{
    notification["error"]({
      message: "File size exceeds the limit. Please select a PDF file smaller than 10 MB.",
    });
  }
  
  handleDrgreeImage = (key: string, e: any) => {
    if (e.target.files.length > 0) {
      let file = e.target.files[0];
      const fileExtention = file.name?.split('.').pop().toLowerCase();
      if (fileExtention === 'pdf') {
        let degreeSize = file.size / (1024 * 1024)
        if (degreeSize <= 10) {
          this.setState({
            degreeImg: e.target.files
          });
        }
        else {
          notification["error"]({
            message: "File size exceeds the limit. Please select a PDF file smaller than 10 MB.",
          });
        }
      } else {
        notification["error"]({
          message: "Invalid file type. Please select a valid pdf file.",
        });
      }
    }
  }

  handleRegistrationImage = (key: string, e: any) => {
    let registrationFile = e.target.files;
    if (registrationFile.length > 0) {
      let registerFile = registrationFile[0];
      const extension = registerFile.name?.split('.').pop().toLowerCase();
      if (extension === 'pdf') {
        let registerSize = registerFile.size / (1024 * 1024)
        if (registerSize <= 10) {
          this.setState({
            registrationImg: registrationFile
          });
        }
        else {
          notification["error"]({
            message: "File size exceeds the limit. Please select a PDF file smaller than 10 MB.",
          });
        }
      } else {
        notification["error"]({
          message: "Invalid file type. Please select a valid pdf file.",
        });
      }
    }
  }

  handleClinicImage = (key: string, e: any) => {
    let clinicRegFile = e.target.files;
    if (clinicRegFile.length > 0) {
      const clinicFile = clinicRegFile[0];
      const extension = clinicFile.name?.split('.').pop().toLowerCase();
      if (extension === 'pdf') {
        const registerSize = clinicFile.size / (1024 * 1024)
        if (registerSize <= 10) {
          this.setState({
            clinicRegImg: clinicRegFile
          });
        }
        else {
          notification["error"]({
            message: "File size exceeds the limit. Please select a PDF file smaller than 10 MB.",
          });
        }
      } else {
        notification["error"]({
          message: "Invalid file type. Please select a valid pdf file.",
        });
      }
    }
  }

  postDoctorVerify = async () => {
    if (this.state.identifyImg.length == 0) {
      return notification["error"]({
        message: "Please upload identity details document.",
      });
    }
    if (this.state.degreeImg.length == 0) {
      return notification["error"]({
        message: "Please upload degree details document",
      });
    }
    if (this.state.registrationImg.length == 0) {
      return notification["error"]({
        message: "Please upload registration details document.",
      });
    }
    if (this.state.clinicRegImg.length == 0) {
      return notification["error"]({
        message: "Please upload clinic details document.",
      });
    }
    let formdata = new FormData();
    if (this.state.identifyImg) {
      let fileListIdentifyImg = [...this.state.identifyImg]
      fileListIdentifyImg.forEach((item: any) => {
        formdata.append("identity_details[]", item, item?.name)
      });
    }
    if (this.state.degreeImg) {
      let fileListDegreeImgg = [...this.state.degreeImg]
      fileListDegreeImgg.forEach((item: any) => {
        formdata.append("degree_deatils[]", item, item?.name)
      });
    }
    if (this.state.registrationImg) {
      let fileListRegistrationImg = [...this.state.registrationImg]
      fileListRegistrationImg.forEach((item: any) => {
        formdata.append("registration_details[]", item, item?.name)
      });
    }
    if (this.state.clinicRegImg) {
      let fileListclinicRegImg = [...this.state.clinicRegImg]
      fileListclinicRegImg.forEach((item: any) => {
        formdata.append("clinic_details[]", item, item?.name)
      });
    }

    this.postDoctorVerifyApiCallId = ApiCall({
      method: configJSON.postDoctorRegisterApiMethod,
      endPoint: `${configJSON.postDoctorVerifyAPiEndPoint}?is_request=web`,
      body: formdata,
      type: "formData"
    });
    this.setState({
      isLoader: true
    });
  };

  postDoctorVerifySuccessCallBack = (res: any) => {
    is_kyc === "approved" ? this.props.history.push("/doctorhome") : this.props.history.push("/doctorprofiledetails")
    localStorage.setItem("is_Upload", "uploaded")
    this.setState({
      isLoader: false
    });
  };

  postDoctorVerifyFailureCallBack = async (err: any) => {
    this.setState({isLoader:false})
  }

  getSpecializationShow = async () => {
    this.getDoctorSpecializationApiCallId = ApiCall({
      contentType: configJSON.getSpecializationApiContentType,
      method: configJSON.getSpecializationApiMethod,
      endPoint: configJSON.getSpecializationAPiEndPoint,
    });
  };

  getDoctorCategoryShow = async () => {
    this.getDoctorCategoryApiCalledId = ApiCall({
      contentType: configJSON.getSpecializationApiContentType,
      method: configJSON.getSpecializationApiMethod,
      endPoint: configJSON.getDoctorCategoryAPiEndPoint,
    });
  };

  getSpecializationSuccessCallBack = (res: any) => {
    this.setState({
      specializationData: res.specialization
    })
  }

  getSpecializationFailureCallBack = (err: any) => {
    console.log("@@@----error doctor", err)
  }

  getDoctorCategorySuccessCallBack = (res: any) => {
    this.setState({
      doctorCategoryData: res.data
    })
  }

  removeCountryCode(phoneNumber:string) {
    if(phoneNumber.length >10){
      return phoneNumber.replace(/^91/, '');
    }else{
      return phoneNumber
    }
  } 

  navigateToDocumentUploadPage = () => {
    this.props.history.push("/editdoctordetails");
  };

  navigateToViewDocumentPage = () => {
    this.props.history.push("/viewdocument")
  };
  handleErrorForKYC = () => {
    notification["error"]({
      message: "Please upload or verify your documents to complete the KYC process",
    });
  };

  subCategoryApiCall = (data: APIPayloadType) => {
    const token = localStorage.getItem("token");
    let { contentType, method, endPoint, body } = data;
    const header = {
        "Content-Type": contentType,
        token
    };
    let requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
    );
    body &&
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
    }

  getSubCategoryfunction = async () => {
    this.getSubCategoryApiCallId = this.subCategoryApiCall({
          contentType: configJSON.getSpecializationApiContentType,
          method: configJSON.getSpecializationApiMethod,
          endPoint: `${configJSON.getDoctorSubCategoryAPiEndPoint}?category_id=${this.state.selectSubCategory}`
    });
  };

  getSubCategorySuccessCallBack = (response: SubCategoryData) => {
    this.setState({
      subCategoryData: response,
      selectSubCategory: ""
    });
  };

  handleCategoryChange = async (value: string) => {
    let category = await JSON.parse(value);
    this.setState({
      selectSubCategory: category.categoryId,
      selectCategory: category.categoryName,
      subCategoryData: {data: []},
      subCategory: ""
    }, () => {
      this.getSubCategoryfunction();
    });
  };

  handleSubCategory = (value: string) => {
    this.setState({
      subCategory: value
    });
  };

  handleEmailChange = (event: {target: {value: string}}) => {
    this.setState({
      isEmail: true,
      inputEmail: event.target.value,
      emailErr: ""
    });
  };

  handlePhoneChange = (event: {target: {value: string}}) => {
    this.setState({
      isPhone: true,
      inputPhone: event.target.value,
      phoneErr: ""
    });
  };

  handleRegistrationNumber = (event: {target: {value: string}}) => {
    this.setState({
      registartionValue: event?.target.value,
      registrationErr: ""
    });
  };
}
// Customizable Area End
