import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { message as MESSAGE, notification } from "antd";
import * as H from "history";
import ApiCall from "../../../components/src/ApiCall.web";
import React, { ChangeEvent } from "react";
let config = require('../../../framework/src/config.js').baseURL;
import { Default } from "../../../blocks/splashscreen/src/assets";

export interface ValidResponseType {
  message: object;
  data: object;
}

export interface InvalidResponseType {
  errors: "";
}

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
}

export interface CategoryType {
  data: Array<CategoryData>
}

export interface CategoryData{
    id: number;
    name: string;
}

export interface SpecializationType {
  specialization: Array<CategoryData>
}
export interface DoctorDetailsType {
  data: DoctorDetailsData;
}

export interface AvailabilityType {
  id: string;
  start_time: string;
  end_time: string;
  unavailable_start_time: string;
  unavailable_end_time: string;
  availability_date: string;
  timeslots: [
    {
      to: string;
      from: string;
    },
  ],
  available_slots_count: string;
  doctor_id: number;
  day_of_week: Array<string>;
  mode_type: string;
}

export interface DoctorDetailsData {
  id: string,
  type: string,
  attributes: {
    is_kyc: string;
    first_name: string,
    last_name: string,
    full_name: string,
    full_phone_number: string,
    email: string,
    rating: [],
    reviews: string,
    registration_no: string,
    registration_council: string,
    year: string,
    specialization: string,
    city: string,
    medical_representative_name: string,
    representative_contact_no: string,
    experience: string,
    doctor_category: string,
    language: Array<string> | unknown,
    user_type: string,
    about: string,
    device_language: string,
    service_offered: [],
    image: string,
    gender: string,
    qualification: string,
    date_of_birth: string,
    hospital: string,
    profile_image: string,
    doctor_id: number,
    fees: string,
    online_consultation: string,
    inclinic_consultation: string,
    clinic_addresses: Array<string>,
    doctor_weekly_availabilities: [
      {
        id: string,
        start_time: string,
        end_time: string,
        unavailable_start_time: string,
        unavailable_end_time: string,
        availability_date: string,
        timeslots: [
          {
            to: string,
            from: string,
          },
        ],
        available_slots_count: string,
        doctor_id: number,
        day_of_week: Array<string>,
        mode_type: string,
      },
      {
        id: string,
        start_time: string,
        end_time: string,
        unavailable_start_time: string,
        unavailable_end_time: string,
        availability_date: string,
        timeslots: [
          {
            to: string,
            from: string,
          },
        ],
        available_slots_count: string,
        doctor_id: number,
        day_of_week: Array<string>,
        mode_type: string,
      },
    ]
  }
}

export interface TokenErrorType {
  errors: [{ token: string }]
}

export interface TokenError {
  errors: string;
}

export interface TimeSlotType {
  timeSlot: string;
  isSelect: boolean
}

export interface TimeSlotObject {
  [key: string]: boolean;
}

export interface ClinicDetailsType {
  data: [
    {
      id: string,
      type: string,
      attributes: {
        name: string,
        address: string,
        contact_no: string,
        doctor_id: number,
        link: string,
        clinic_images: [
          {
            id: number,
            url: string,
          }
        ]
      }
    }
  ]
}

export interface DetailsType {
  data: {
    id: string,
    type: string,
    attributes: {
      name: string,
      address: string,
      contact_no: string,
      doctor_id: number,
      link: string,
      clinic_images: [
        {
          id: number,
          url: string,
        }
      ]
    }
  }
}

export interface ImageType {
  id: number,
  url: string
}

export interface ErrorType {
  message: string
}

export interface SpecializationData {
    id: string;
    name: string;
}

export interface TimeSlotss {
  start_time: string;
  end_time: string;
}

export interface AvailabilityTypeData {
    data: {
      id: string;
      type: string;
      attributes: {
        mode_type: string;
        day_of_week: Array<string>;
        time_slots: [
          {
            id: string;
            start_time: string;
            end_time:  string;
            unavailability_date: Array<string>
          }
        ]
      }
    }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: H.History;
  // Customizable Area End
}
export interface WeekDay {
  timeSlot: string, isSelect: boolean
}
interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  profileImageState: any;
  showEditProfileForm: boolean;
  showEditHalfFollowUpTime: boolean;
  showPopup: boolean;
  doctorProfileData: DoctorDetailsType;
  errorMessage: string;
  categoryErr: string;
  category: unknown;
  doctorFullName: string;
  doctorCityName: string;
  doctorRegistrationNumber: string;
  doctorRegistrationConsil: string;
  doctorPassingYear: string;
  doctorSpeciality: string | unknown;
  doctorEmail: string;
  updateDoctorDetails: object;
  profilePic: string | any;
  userProfilePicture: string;
  doctorInClinicAvailability: Array<object>;
  doctorVirtualAvailability: Array<string>;
  userNameErr: string;
  userEmailErr: string;
  userCityErr: string;
  userSpecialityErr: string;
  specialityErr: string;
  userRegErr: string;
  doctorRegConsilErr: string;
  doctorPassingErr: string;
  doctorImage: string;
  weekDays: string[];
  modeSelectData: Array<string>;
  showCreateAvailability: boolean;
  followUpTimeSlots: Array<string>;
  modeTypeValue: string | unknown;
  selectedDays: string[];
  followUpTimeHalf: Array<TimeSlotType>;
  editFollowUpTimeHalf: Array<TimeSlotType>;
  showHalfFollowUpTime: boolean;
  followUpTimeHr: Array<TimeSlotType>;
  editFollowUpTimeHr: Array<TimeSlotType>;
  timeZone: { time: string | unknown };
  editTimeZone: { time: string | unknown };
  showCreateClinic: boolean;
  clinicName: string;
  clinicAddress: string;
  clinicContact: string;
  clinicLink: string;
  clinicNameErr: string;
  clinicAddressErr: string;
  clinicContactErr: string;
  clinicLinkErr: string;
  getChecked: string;
  followUpTime: Array<TimeSlotObject>;
  editFollowUpTime: Array<TimeSlotObject>;
  followUpTimeSlotsCount: Array<object>;
  editFollowUpTimeSlotsCount: Array<object>;
  clinicId: string;
  showClinicData: DetailsType;
  getImages: Array<string>;
  getClinicImages: Array<ImageType>;
  clinicImage: Array<string>;
  editClinicDetails: boolean;
  getClinicName: string;
  getClinicAddress: string;
  getClinicContactNum: string;
  getClinicLink: string;
  editClinicImage: Array<string>;
  deleteClinicImage: boolean;
  getImageId: number;
  showFollowUpTimeDropdown: boolean;
  activeFollowUpTimeDropdownItem: string;
  doctorPhoneNum: string;
  doctorPhoneNumErr: string;
  isVerify: string | null;
  getDoctorId: string  | null;
  isLoader: boolean;
  contentHeight: number;
  specializationData: SpecializationType;
  categoryData: CategoryType;
  experience: string;
  experienceErr: string;
  qualification: Array<string> | any | string ;
  qualificationErr: string;
  doctorsAboutInput: string;
  doctorsAboutInputErr: string;
  representativeName: string;
  representativeNameErr: string;
  representativeMobileNo: string;
  representativeMobileNoErr: string;
  specializationList: string;
  knownLanguage: string | unknown;
  selectedValues: Array<string> | any;
  languageErr: string;
  availabilityId: string;
  availabilityData: AvailabilityTypeData;
  editWeekDaysValue: string[];
  editModeValue: string | unknown;
  editStartValue: string;
  editEndvalue: string;
  editSelectTimeZone: string;
  isEdit: boolean;
  mainArr: Array<TimeSlotss>;
  showCheckboxes:boolean
        // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Customisableuserprofiles2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDoctorProfileDetailsApiCallId: string = "";
  postDoctorDetailsApiCallId: string = "";
  putUpdateDoctorProfileApiCallId: string = "";
  postDoctorCreateAvailabilityApiCallId: string = "";
  postCreateClinicDestailsApiCallId: string = "";
  getClinicDetailsIndexApiCallId: string = "";
  getShowClinicDetailsApiCallId: string = "";
  editClinicDetailsApiCallId: string = "";
  deleteImageApiCallId: string = "";
  followUpTimeRef: React.RefObject<HTMLDivElement>;
  getDoctorDocumentApiCallId:string="";
  geSpecializationApiCallId: string = "";
  getAvailabilityAPICallID: string = "";
  getCategoryApiCallId: string = "";
  putDoctorAvailabilityApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      showEditProfileForm: false,
      experience: "",
      experienceErr: "",
      showPopup: false,
      doctorProfileData: {
        data: {
          id: "",
          type: "",
          attributes: {
            is_kyc: "",
            first_name: "",
            last_name: "",
            full_name: "",
            full_phone_number: "",
            email: "",
            rating: [],
            reviews: "",
            registration_no: "",
            registration_council: "",
            year: "",
            specialization: "",
            city: "",
            medical_representative_name: "",
            representative_contact_no: "",
            experience: "",
            doctor_category: "",
            language: [],
            user_type: "",
            about: "",
            device_language: "",
            service_offered: [],
            image: "",
            gender: "",
            qualification: "",
            date_of_birth: "",
            hospital: "",
            profile_image: "",
            doctor_id: 0,
            fees: "",
            online_consultation: "",
            inclinic_consultation: "",
            clinic_addresses: [],
            doctor_weekly_availabilities: [{
              id: "",
              start_time: "",
              end_time: "",
              unavailable_start_time: "",
              unavailable_end_time: "",
              availability_date: "",
              timeslots: [
                {
                  to: "",
                  from: ""
                },
              ],
              available_slots_count: "",
              doctor_id: 0,
              day_of_week: [

              ],
              mode_type: "",
            },
            {
              id: "",
              start_time: "",
              end_time: "",
              unavailable_start_time: "",
              unavailable_end_time: "",
              availability_date: "",
              timeslots: [
                {
                  to: "",
                  from: ""
                },
              ],
              available_slots_count: "",
              doctor_id: 0,
              day_of_week: [

              ],
              mode_type: "",
            }
            ],
          }
        }
      },
      showEditHalfFollowUpTime: false,
      editSelectTimeZone: "",
      categoryErr: "",
      category: "",
      errorMessage: "",
      doctorFullName: "",
      doctorCityName: "",
      doctorRegistrationNumber: "",
      doctorRegistrationConsil: "",
      doctorPassingYear: "",
      doctorSpeciality: "",
      doctorEmail: "",
      updateDoctorDetails: {},
      profilePic: "",
      userProfilePicture: "",
      doctorInClinicAvailability: [],
      doctorVirtualAvailability: [],
      userNameErr: "",
      userEmailErr: "",
      userCityErr: "",
      doctorRegConsilErr:"",
      doctorPassingErr:"",
      specialityErr: "",
      userSpecialityErr: "",
      userRegErr: "",
      doctorImage: "",
      profileImageState: "",
      weekDays: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      modeSelectData: ["In - Clinic", "Virtual"],
      showCreateAvailability: false,
      followUpTimeSlots: ["30 Minutes", "60 Minutes"],
      modeTypeValue: "",
      selectedDays: [],
      followUpTimeHalf: [
        { timeSlot: "09:00 AM - 09:30 AM", isSelect: false },
        { timeSlot: "09:30 AM - 10:00 AM", isSelect: false },
        { timeSlot: "10:00 AM - 10:30 AM", isSelect: false },
        { timeSlot: "10:30 AM - 11:00 AM", isSelect: false },
        { timeSlot: "11:00 AM - 11:30 AM", isSelect: false },
        { timeSlot: "11:30 AM - 12:00 PM", isSelect: false },
        { timeSlot: "12:00 PM - 12:30 PM", isSelect: false },
        { timeSlot: "12:30 PM - 01:00 PM", isSelect: false },
        { timeSlot: "01:00 PM - 01:30 PM", isSelect: false },
        { timeSlot: "01:30 PM - 02:00 PM", isSelect: false },
        { timeSlot: "02:00 PM - 02:30 PM", isSelect: false },
        { timeSlot: "02:30 PM - 03:00 PM", isSelect: false },
        { timeSlot: "03:00 PM - 03:30 PM", isSelect: false },
        { timeSlot: "03:30 PM - 04:00 PM", isSelect: false },
        { timeSlot: "04:00 PM - 04:30 PM", isSelect: false },
        { timeSlot: "04:30 PM - 05:00 PM", isSelect: false },
        { timeSlot: "05:00 PM - 05:30 PM", isSelect: false },
        { timeSlot: "05:30 PM - 06:00 PM", isSelect: false },
        { timeSlot: "06:00 PM - 06:30 PM", isSelect: false },
        { timeSlot: "06:30 PM - 07:00 PM", isSelect: false },
        { timeSlot: "07:00 PM - 07:30 PM", isSelect: false },
        { timeSlot: "07:30 PM - 08:00 PM", isSelect: false },
        { timeSlot: "08:00 PM - 08:30 PM", isSelect: false },
        { timeSlot: "08:30 PM - 09:00 PM", isSelect: false }
      ],
      showHalfFollowUpTime: false,
      categoryData: {
        data: [
          {
            id: 0,
          name: ""          }
        ]
      },
      followUpTimeHr: [
        { timeSlot: "09:00 AM - 10:00 AM", isSelect: false },
        { timeSlot: "10:00 AM - 11:00 AM", isSelect: false },
        { timeSlot: "11:00 AM - 12:00 AM", isSelect: false },
        { timeSlot: "12:00 PM - 01:00 PM", isSelect: false },
        { timeSlot: "01:00 PM - 02:00 PM", isSelect: false },
        { timeSlot: "02:00 PM - 03:00 PM", isSelect: false },
        { timeSlot: "03:00 PM - 04:00 PM", isSelect: false },
        { timeSlot: "04:00 PM - 05:00 PM", isSelect: false },
        { timeSlot: "05:00 PM - 06:00 PM", isSelect: false },
        { timeSlot: "06:00 PM - 07:00 PM", isSelect: false },
        { timeSlot: "07:00 PM - 08:00 PM", isSelect: false },
        { timeSlot: "08:00 PM - 09:00 PM", isSelect: false },
      ],
      timeZone: { time: "" },
      editTimeZone: { time: "" },
      showCreateClinic: false,
      clinicName: "",
      clinicAddress: "",
      clinicContact: "",
      clinicLink: "",
      clinicNameErr: "",
      clinicAddressErr: "",
      clinicContactErr: "",
      clinicLinkErr: "",
      getChecked: "",
      followUpTime: [],
      editFollowUpTime: [],
      followUpTimeSlotsCount: [],
      editFollowUpTimeSlotsCount: [],
      clinicId: "",
      showCheckboxes:false,
      showClinicData: {
        data: {
          id: "",
          type: "",
          attributes: {
            name: "",
            address: "",
            contact_no: "",
            doctor_id: 0,
            link: "",
            clinic_images: [
              {
                id: 0,
                url: "",
              }
            ]
          }
        }
      },
      getImages: [],
      getClinicImages: [],
      clinicImage: [],
      editClinicDetails: false,
      getClinicName: "",
      getClinicAddress: "",
      getClinicContactNum: "",
      getClinicLink: "",
      editClinicImage: [],
      deleteClinicImage: false,
      getImageId: 0,
      showFollowUpTimeDropdown: false,
      activeFollowUpTimeDropdownItem: "",
      doctorPhoneNum: "",
      doctorPhoneNumErr: "",
      isVerify: '',
      getDoctorId: "",
      isLoader: false,
      contentHeight: 0,
      specializationData:{
        specialization: [
          {
            id: 0,
            name: ""
          }
        ]
      },
      qualification: [],
      qualificationErr: "",
      doctorsAboutInput: "",
      doctorsAboutInputErr: "",
      representativeName: "",
      representativeNameErr: "",
      representativeMobileNo: "",
      representativeMobileNoErr: "",
      specializationList: "",
      knownLanguage: "",
      selectedValues: [],
      languageErr: "",
      availabilityId: "",
      availabilityData: {
        data: {
          id: "",
          type: "",
          attributes: {
            mode_type: "",
            day_of_week: [],
            time_slots: [
              {
                id: "",
                start_time: "",
                end_time: "",
                unavailability_date: []
              }
            ]
          }
        }
      },
      editWeekDaysValue: [],
      editModeValue: "",
      editStartValue: "",
      editEndvalue: "",
      editFollowUpTimeHalf: [
        { timeSlot: "09:00 AM - 09:30 AM", isSelect: false },
        { timeSlot: "09:30 AM - 10:00 AM", isSelect: false },
        { timeSlot: "10:00 AM - 10:30 AM", isSelect: false },
        { timeSlot: "10:30 AM - 11:00 AM", isSelect: false },
        { timeSlot: "11:00 AM - 11:30 AM", isSelect: false },
        { timeSlot: "11:30 AM - 12:00 PM", isSelect: false },
        { timeSlot: "12:00 PM - 12:30 PM", isSelect: false },
        { timeSlot: "12:30 PM - 01:00 PM", isSelect: false },
        { timeSlot: "01:00 PM - 01:30 PM", isSelect: false },
        { timeSlot: "01:30 PM - 02:00 PM", isSelect: false },
        { timeSlot: "02:00 PM - 02:30 PM", isSelect: false },
        { timeSlot: "02:30 PM - 03:00 PM", isSelect: false },
        { timeSlot: "03:00 PM - 03:30 PM", isSelect: false },
        { timeSlot: "03:30 PM - 04:00 PM", isSelect: false },
        { timeSlot: "04:00 PM - 04:30 PM", isSelect: false },
        { timeSlot: "04:30 PM - 05:00 PM", isSelect: false },
        { timeSlot: "05:00 PM - 05:30 PM", isSelect: false },
        { timeSlot: "05:30 PM - 06:00 PM", isSelect: false },
        { timeSlot: "06:00 PM - 06:30 PM", isSelect: false },
        { timeSlot: "06:30 PM - 07:00 PM", isSelect: false },
        { timeSlot: "07:00 PM - 07:30 PM", isSelect: false },
        { timeSlot: "07:30 PM - 08:00 PM", isSelect: false },
        { timeSlot: "08:00 PM - 08:30 PM", isSelect: false },
        { timeSlot: "08:30 PM - 09:00 PM", isSelect: false }
      ],
      editFollowUpTimeHr: [
        { timeSlot: "09:00 AM - 10:00 AM", isSelect: false },
        { timeSlot: "10:00 AM - 11:00 AM", isSelect: false },
        { timeSlot: "11:00 AM - 12:00 AM", isSelect: false },
        { timeSlot: "12:00 PM - 01:00 PM", isSelect: false },
        { timeSlot: "01:00 PM - 02:00 PM", isSelect: false },
        { timeSlot: "02:00 PM - 03:00 PM", isSelect: false },
        { timeSlot: "03:00 PM - 04:00 PM", isSelect: false },
        { timeSlot: "04:00 PM - 05:00 PM", isSelect: false },
        { timeSlot: "05:00 PM - 06:00 PM", isSelect: false },
        { timeSlot: "06:00 PM - 07:00 PM", isSelect: false },
        { timeSlot: "07:00 PM - 08:00 PM", isSelect: false },
        { timeSlot: "08:00 PM - 09:00 PM", isSelect: false },
      ],
      isEdit: false,
      mainArr: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.followUpTimeRef = React.createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (this.inValidResponse(responseJson)) {
        this.apiFailureCallBacks(apiRequestCallId, responseJson);
      }
      else if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (errorReponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    window.scroll({
      top:0,
      left:0
    });
    const rootElement = document.getElementById('root');
    if (rootElement) {
      this.setState({ contentHeight: rootElement.offsetHeight+33});
    }
   let ss = await localStorage.getItem("is_kyc");
   this.setState({isVerify: ss})
    this.getDoctorProfileDetailsfunction();
    this.getClinicDetailsIndexfunction();
    const handleClickOutsideApplication = (event: any) => {
      if (this.followUpTimeRef.current && !this.followUpTimeRef.current.contains(event.target)) {
        this.setState({
          showFollowUpTimeDropdown: false,
          timeZone: { time: "" },
          showHalfFollowUpTime:false
        })
      }
    };
    document.addEventListener('mouseup', handleClickOutsideApplication);
    this.setState({getDoctorId:localStorage.getItem("doctorId")},()=> this.getDoctorDocumentfunction());
    this.getDoctorCategoryShow();
    this.getSpecializationShow();
    }

  handleEditDotorProfileDetails = () => {
    this.getDoctorProfileDetailsfunction();
    this.props.history.push("/editdoctordetails");
  };

  handelDoctorProfilePopupShowFunction = () => {
    this.setState({ showPopup: !this.state.showPopup });
  };

  handleDoctorLogout = () => {
    localStorage.clear();
    notification["success"]({
      message: "Logout successfully",
    });
    this.props.history.push("/");
  };

  apiCall = (data: APIPayloadType) => {
    const { contentType, method, endPoint, body } = data;
    const token = localStorage.getItem("token");
    const header = {
      "Content-Type": contentType,
      token
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  isValidResponse = (responseJson: ValidResponseType & SpecializationType) => {
    return (responseJson && responseJson.data) || (responseJson && responseJson.message) || (responseJson && responseJson.specialization);
  };

  inValidResponse = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors;
  };

  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: DoctorDetailsType & ClinicDetailsType & DetailsType & object & ValidResponseType & SpecializationType & CategoryType & AvailabilityTypeData) => {
    if (apiRequestCallId === this.getDoctorProfileDetailsApiCallId) {
      this.getDoctorProfileDetailsSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.postDoctorDetailsApiCallId) {
      this.postDoctorDetailsSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.putUpdateDoctorProfileApiCallId) {
      this.putDoctorProfileImageSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.postDoctorCreateAvailabilityApiCallId) {
      this.postDoctorCreateAvailabilitySuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.postCreateClinicDestailsApiCallId) {
      this.postCreateClinicDestailsSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getClinicDetailsIndexApiCallId) {
      this.getClinicDetailsIndexSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getShowClinicDetailsApiCallId) {
      this.getShowClinicDetailsSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.editClinicDetailsApiCallId) {
      this.editClinicDetailsSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.deleteImageApiCallId) {
      this.deleteImageSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getDoctorDocumentApiCallId) {
      this.handleDoctorDocumentSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.geSpecializationApiCallId) {
      this.getSpecializationSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getAvailabilityAPICallID) {
      this.getAvailabilityByIdShowSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getCategoryApiCallId) {
      this.getDoctorCategorySuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.putDoctorAvailabilityApiCallId) {
      this.putDoctorAvailabilitySuccessCallBack(responseJson);
    }
  };

  apiFailureCallBacks = (apiRequestCallId: string, responseJson: TokenErrorType & string & ErrorType) => {
    if (apiRequestCallId === this.getDoctorProfileDetailsApiCallId) {
      this.getDoctorProfileDetailsFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.postDoctorCreateAvailabilityApiCallId) {
      this.postDoctorCreateAvailabilityFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.postDoctorDetailsApiCallId) {
      this.handlePostDoctorDataError( responseJson )
    }
  };

  getDoctorProfileDetailsfunction = async () => {
    this.getDoctorProfileDetailsApiCallId = this.apiCall({
      contentType: configJSON.getDoctorProfileApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getDoctorProfileDetailsAPiEndPoint,
    });
    this.setState({isLoader: true});
  };

  handleProfile_Pic=(res:any)=>{
   const profileImage = res?.data?.attributes?.profile_image;
   if(profileImage){
    localStorage.setItem("profile_pic",res.data.attributes.profile_image)
   }
}

handlePostDoctorDataError=(errorrResponse:any)=>{
  this.setState({isLoader: false});
  notification["error"]({
    message: errorrResponse.errors[0]
  });
}
  getDoctorProfileDetailsSuccessCallBack = (response: DoctorDetailsType) => {
    localStorage.setItem("is_kyc", response.data?.attributes?.is_kyc);
    localStorage.setItem("doctorName", response.data.attributes.full_name);
    localStorage.setItem("doctorNum", response.data.attributes.full_phone_number);
    localStorage.setItem("doctorId", JSON.stringify(response.data.attributes.doctor_id) );
    this.handleProfile_Pic(response);
    this.setState({
      isLoader: false,
      doctorProfileData: response,
      doctorFullName: response.data.attributes.full_name,
      doctorSpeciality: response.data.attributes.specialization,
      doctorCityName: response.data.attributes.city,
      doctorEmail: response.data.attributes.email,
      doctorRegistrationNumber: response.data.attributes.registration_no,
      userProfilePicture: response.data.attributes.profile_image,
      doctorInClinicAvailability: response.data.attributes?.doctor_weekly_availabilities,
      doctorPhoneNum: this.removeCountryCode(response.data.attributes?.full_phone_number),
      doctorPassingYear:response.data.attributes?.year,
      doctorRegistrationConsil:response.data.attributes?.registration_council,
      experience: response.data.attributes?.experience,
      qualification: response.data.attributes?.qualification,
      category: response.data.attributes?.doctor_category,
      doctorsAboutInput: response.data.attributes?.about,
      representativeName: response.data.attributes?.medical_representative_name,
      representativeMobileNo: response.data.attributes?.representative_contact_no,
      selectedValues: response.data.attributes?.language
    });
  };

  getDoctorProfileDetailsFailureCallBack = async (error: TokenErrorType) => {
    this.setState({
      isLoader: false,
      errorMessage: error.errors[0].token
    })
  }

  handleEditProfileError=()=>{
    if (this.state.doctorPhoneNum === null || this.state.doctorPhoneNum === "") {
        this.setState({
        doctorPhoneNumErr: "This field is required.",
      });
    }

    if (this.state.doctorCityName === null || this.state.doctorCityName === "") {
       this.setState({
        userCityErr: "This field is required.",
      });
    }

    if (this.state.doctorSpeciality === null || this.state.doctorSpeciality === "") {
         this.setState({
          specialityErr: "Please select doctor specialization",
      });
    }

    if (this.state.doctorRegistrationNumber === null || this.state.doctorRegistrationNumber === "") {
        this.setState({
       userRegErr: "This field is required.",
    })}

    if (this.state.doctorRegistrationConsil === null || this.state.doctorRegistrationConsil === "") {
       this.setState({
      doctorRegConsilErr : "This field is required.",
    })}

    if (this.state.doctorPassingYear === null || this.state.doctorPassingYear === "") {
      return  this.setState({
       doctorPassingErr: "This field is required.",
    });
     }
  }

  handleEditFormError = () => {
    const expRegex = /^(?:[1-9]|[1-4]\d|50)(?:\.\d)?$/;
    const qualiRegex = /^[A-Za-z]+(?: [A-Za-z]+)*(?:,\s?[A-Za-z]+(?: [A-Za-z]+)*)*$/;
    const aboutRegex = /^[()\.\/,A-Za-z0-9]+(?: [()\.\/,A-Za-z0-9]+)*$/;
    
    if (!expRegex.test(this.state.experience)) {
      this.setState({
        experienceErr: "Please enter a valid experience year"
      });
      return true
    }
    if (!qualiRegex.test(this.state.qualification) || this.state.qualification === null) {
      this.setState({
        qualificationErr: "Please enter a valid qualification"
      });
      return true
    }
    if (this.state.category === null) {
       this.setState({
        categoryErr: "Please select doctor category"
      });
      return true
    }
    if (!aboutRegex.test(this.state.doctorsAboutInput) || this.state.doctorsAboutInput === null) {
       this.setState({
        doctorsAboutInputErr: "Please enter valid information about the doctor"
      });
      return true
    }
  };

handleProfileError = () => {
  const nameRegex = /^[A-Za-z ']+$/;
  const numRegex = /^[6-9]\d{9}$/;
  if (!nameRegex.test(this.state.representativeName) || this.state.representativeName === null) {
     this.setState({
      representativeNameErr: "Please enter a valid name"
    });
    return true
  }
  if (!numRegex.test(this.state.representativeMobileNo)) {
   this.setState({
      representativeMobileNoErr: "Please enter a valid mobile Number"
    });
    return true
  }
  if (this.state.selectedValues.length == 0 || this.state.selectedValues == null) {
     this.setState({
      languageErr: "Please select known language"
    });
    return true
  }
};

  postEditDoctorDetails = async () => {
    const regNUmRegex = /^[a-zA-Z0-9]{3,10}$/ 

     this.handleEditProfileError();
    let error = this.handleEditFormError();
    if(error){
      return
    }
    let checkValidation =   this.handleProfileError();
    if(checkValidation){
      return
    }

     if (this.state.doctorPassingErr || this.state.doctorRegConsilErr || this.state.userCityErr || this.state.userSpecialityErr || this.state.languageErr || this.state.specialityErr || this.state.categoryErr) {
      return false
     }

    if (!regNUmRegex.test(this.state.doctorRegistrationNumber)) {
      return this.setState({
        userRegErr: "Please enter a valid registration number (max 10 charactor only)"
      });
    }
    if (this.state.doctorPhoneNum.length == 0){
      return notification["error"]({
        message: "Please input phone number",
      });
    }
    if (this.state.doctorEmail.length == 0) {
      return notification["error"]({
        message: "Please input email id",
      });
    }
 
    let editDoctorDetailsapiBody = {
      data: {
        attributes: {
          full_name: this.state.doctorFullName,
          specialization: this.state.doctorSpeciality,
          city: this.state.doctorCityName,
          email: this.state.doctorEmail,
          registration_no: this.state.doctorRegistrationNumber,
          full_phone_number: `91${this.state.doctorPhoneNum}`,
          year:this.state.doctorPassingYear,
          registration_council:this.state.doctorRegistrationConsil,
          qualification: this.state.qualification,
          doctor_category: this.state.category,
          about: this.state.doctorsAboutInput,
          medical_representative_name: this.state.representativeName,
          representative_contact_no: this.state.representativeMobileNo,
          experience: this.state.experience,
          language: this.state.selectedValues
        }
      }
    }

    let editDoctorDetail_googlelogin_apiBody = {
      data: {
        attributes: {
          full_name: this.state.doctorFullName,
          specialization: this.state.doctorSpeciality,
          city: this.state.doctorCityName,
          email: this.state.doctorEmail,
          registration_no: this.state.doctorRegistrationNumber,
          full_phone_number: `91${this.state.doctorPhoneNum}`,
          registration_council:this.state.doctorRegistrationConsil,
          year:this.state.doctorPassingYear,
          qualification: this.state.qualification,
          doctor_category: this.state.category,
          about: this.state.doctorsAboutInput,
          medical_representative_name: this.state.representativeName,
          representative_contact_no: this.state.representativeMobileNo,
          experience: this.state.experience,
          language: this.state.selectedValues
        }
      }
    }

    let isDoctorGoogleLogin = localStorage.getItem("isDoctorGoogleLogin");
    let editDoctorDetailsapiBodyy = isDoctorGoogleLogin ? editDoctorDetail_googlelogin_apiBody :editDoctorDetailsapiBody
     
    this.postDoctorDetailsApiCallId = this.apiCall({
      contentType: configJSON.getDoctorProfileApiContentType,
      method: configJSON.putDoctorRegisterApiMethod,
      endPoint: configJSON.postEditDoctorProfileApiEndPoint,
      body: editDoctorDetailsapiBodyy,
    });
    this.putDoctorProfileImage();
    this.setState({isLoader: true});
  };

  postDoctorDetailsSuccessCallBack = (response: object) => {
    this.setState({
      updateDoctorDetails: response,
      isLoader: false
    });
    this.props.history.push("/doctorprofiledetails")
    this.getDoctorProfileDetailsfunction();
    notification["success"]({
      message: "Doctor details update successfully.",
    });
  };

  handleDoctorName = (event: { target: { value: string } }) => {
    const inputValue = event.target.value;
    const nameRegex = /^[A-Za-z ']+$/;
    this.setState({
      doctorFullName: inputValue,
    }, () => {
      if (nameRegex.test(inputValue)) {
        this.setState({
          userNameErr: '',
        });
      } else {
        this.setState({
          userNameErr: inputValue ? 'Please enter a valid full name' : 'This field is required.',
        });
      }
    });
  };

  handleDoctorEmail = (event: { target: { value: string } }) => {
    const emailRegex = /^[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const emailInputValue = event.target.value;
    this.setState({
      doctorEmail: emailInputValue,
    }, () => {
      if (emailRegex.test(emailInputValue)) {
        this.setState({
          userEmailErr: '',
        });
      } else {
        this.setState({
          userEmailErr: emailInputValue ? 'Please enter a valid email address' : 'This field is required.',
        });
      }
    });
  };

  handleDoctorCity = (event: { target: { value: string } }) => {
    const cityInputValue = event.target.value;
    const cityNameRegex = /^[A-Za-z ']+$/;
    this.setState({
      doctorCityName: cityInputValue,
    }, () => {
      if (cityNameRegex.test(cityInputValue)) {
        this.setState({
          userCityErr: '',
        });
      } else {
        this.setState({
          userCityErr: cityInputValue ? 'Please enter a valid city name' : 'This field is required.',
        });
      }
    });
  };

  handleDoctorSpeciality = (event: { target: { value: string } }) => {
    const specialityInputValue = event.target.value;
    const cityNameRegex = /^[A-Za-z ']+$/;
    this.setState({
      doctorSpeciality: specialityInputValue,
    }, () => {
      if (cityNameRegex.test(specialityInputValue)) {
        this.setState({
          userSpecialityErr: '',
        });
      } else {
        this.setState({
          userSpecialityErr: specialityInputValue ? 'Please enter a valid speciality name' : 'This field is required.',
        });
      }
    });
  };

  handleDoctorRegistration = (event: { target: { value: string } }) => {
    const regNumberyInputValue = event.target.value;
    const regNUmRegex =/^[a-zA-Z0-9]{3,10}$/
    this.setState({
      doctorRegistrationNumber: regNumberyInputValue,
    }, () => {
      if (regNUmRegex.test(regNumberyInputValue)) {
        this.setState({
          userRegErr: '',
        });
      } else {
        this.setState({
          userRegErr: regNumberyInputValue ? 'Please enter a valid registration number (max 10 charactor only)' : 'This field is required.',
        });
      }
    });
  };

  handleDoctorRegistrationCouncil = (event: { target: { value: string } }) => {
    const regNumberyInputValue = event.target.value;
    const regNUmRegex = /^[A-Za-z\s]+$/;
    this.setState({
      doctorRegistrationConsil: regNumberyInputValue,
    }, () => {
      if (regNUmRegex.test(regNumberyInputValue)) {
        this.setState({
          doctorRegConsilErr: '',
        });
      } else {
        this.setState({
          doctorRegConsilErr: regNumberyInputValue ? 'Please enter a valid registration council name' : 'This field is required.',
        });
      }
    });
  };


  handleDoctorPassingYear = (event: { target: { value: string } }) => {
    const regNumberyInputValue = event.target.value;
    const regNUmRegex = /^(19|20)\d{2}$/;

    const currentYear = new Date().getFullYear();
    const enteredYear = regNumberyInputValue;

    const isYearValid = parseInt(enteredYear) <= currentYear;

    this.setState({
      doctorPassingYear: regNumberyInputValue,
    }, () => {
      if (regNUmRegex.test(regNumberyInputValue)  && isYearValid) {
        this.setState({
          doctorPassingErr: '',
        });
      } else {
        this.setState({
          doctorPassingErr: regNumberyInputValue ? 'Please enter a valid passing year' : 'This field is required.',
        });
      }
    });
  };


  handleProfilePicUpdate = (event: any) => {
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
    if (event.target?.files[0] && allowedTypes.includes(event.target.files[0].type)) {
    this.setState({ profilePic: event.target.files[0], profileImageState: event.target.files[0]});
    }
    else {
      notification["error"]({
        message: "Please upload valid image file.",
      });
  }
  };

  handleDoctorProfile = () => {
    this.props.history.push("/doctorprofiledetails");
  };

  putDoctorProfileImage = async () => {
    let formdata = new FormData();
    formdata.append("profile_image", this.state.profileImageState, this.state.profilePic)

    this.putUpdateDoctorProfileApiCallId = ApiCall({
      method: configJSON.postDoctorProfileImageApiMethod,
      endPoint: configJSON.putUpdateDoctorProfileApiEndPoint,
      body: formdata,
      type: "formData"
    });
  };

  putDoctorProfileImageSuccessCallBack = (response: DoctorDetailsType) => {
    this.props.history.push("/doctorprofiledetails");
    this.getDoctorProfileDetailsfunction();
  };

  handleCreateAvailability = () => {
    this.setState({
      showCreateAvailability: true
    });
  };

  handleCloseCreateAvailability = () => {
    this.setState({
      showCreateAvailability: false
    });
  };

  postDoctorCreateAvailability = async () => {
    if(this.state.selectedDays.length <= 0){
      return  notification["error"]({
        message: "Please select days",
      });
    }
    if(this.state.followUpTimeSlotsCount.length <= 0){
      return  notification["error"]({
        message: "Please select Duration",
      });
    }
    let apiBody = {
      mode_type: this.state.modeTypeValue,
      status: true,
      day_of_week: this.state.selectedDays,
      time_slots_attributes: this.state.followUpTimeSlotsCount
    }
    this.postDoctorCreateAvailabilityApiCallId = this.apiCall({
      contentType: configJSON.getDoctorProfileApiContentType,
      method: configJSON.postDoctorProfileImageApiMethod,
      endPoint: configJSON.postCreateAvailabilityApiEndPoint,
      body: apiBody
    });
};

  postDoctorCreateAvailabilitySuccessCallBack = (response: ValidResponseType) => {
    if(response.message){
      notification["error"]({
        message: response.message,
      });
      this.setState({
        showCreateAvailability: true
      });
    } else {
      this.setState({
        showCreateAvailability: false,
        followUpTimeHalf: this.state.followUpTimeHalf.map((time: TimeSlotType) => ({...time, isSelect:false})),
        followUpTimeHr: this.state.followUpTimeHr.map((time: TimeSlotType) => ({...time, isSelect:false})),
        modeTypeValue: "",
        selectedDays: [],
        followUpTimeSlotsCount: []
      });
      notification["success"]({
        message: "Availability created successfully",
      });
    
      this.getDoctorProfileDetailsfunction();
    }
  };

  postDoctorCreateAvailabilityFailureCallBack = (error: ErrorType) => {
    notification["error"]({
      message: "Availability not created",
    });
  };

  handleSelectModeType = (value: string | unknown) => {
    this.setState({
      modeTypeValue: value
    });
  };


handleDaysChange= (value: string[]) => {
  this.setState({ selectedDays: value });
};

  handleSelectFollowUpTime = (value: string | unknown) => {
    const { followUpTimeHr, followUpTimeHalf } = this.state;
    this.setState(
      {
        timeZone: {
          time: value,
        },
      },
      () => {
        let updatedHr = followUpTimeHr;
        let updatedHalf = followUpTimeHalf;
        let showHalfFollowUpTime = false;
        let followUpTime : any = [];
        if (value === "30 Minutes") {
          updatedHr = followUpTimeHr.map((item) => ({
            ...item,
            isSelect: false,
          }));
          showHalfFollowUpTime = true;
        } else {
          updatedHalf = followUpTimeHalf.map((item) => ({
            ...item,
            isSelect: false,
          }));
          showHalfFollowUpTime = true;
          followUpTime = [];
        }

        this.setState({
          followUpTimeHr: updatedHr,
          followUpTimeHalf: updatedHalf,
          showHalfFollowUpTime,
          followUpTime,
        });
      }
    );
  };


  handleEditSelectFollowUpTime = (value: string | unknown) => {
    const { editFollowUpTimeHr, editFollowUpTimeHalf } = this.state;
    this.setState(
      {
        editTimeZone: {
          time: value,
        },
      },
      () => {
        let updatedHr = editFollowUpTimeHr;
        let updatedHalf = editFollowUpTimeHalf;
        let showEditHalfFollowUpTime = false;
        if (value === "30 Minutes") {
          updatedHr = editFollowUpTimeHr.map((item) => ({
            ...item,
            isSelect: false,
          }));
          showEditHalfFollowUpTime = true;
        } else {
          updatedHalf = editFollowUpTimeHalf.map((item) => ({
            ...item,
            isSelect: false,
          }));
         
          showEditHalfFollowUpTime = true;
        }
        this.setState({
          editFollowUpTimeHr: updatedHr,
          editFollowUpTimeHalf: updatedHalf,
          showEditHalfFollowUpTime,
          mainArr: []
        });
      }
    );
  };

  postCreateClinicDestails = async () => {
    const clinicNameRegex = /^[A-Za-z ']{2,40}$/;
    const address = /^[0-9A-Za-z\s.,#-]{5,100}$/;
    const clinicNumberRegex = /^[6-9]\d{9}$/;
    const clincLinkRegex = /\b(?!https?:\/\/)(?!w{1,2}\.\w{2,}\.)www\.[a-zA-Z0-9.-]+\.(?:co\.in|com)\b(?![\w.-])(?!\s)/;
    if (this.state.clinicName.trim() == "") {
      return notification["error"]({
        message: "Please fill the clinic name field",
      });
    }
    if (this.state.clinicAddress.trim() == "") {
      return notification["error"]({
        message: "Please fill the clinic address field",
      });
    }
    if (this.state.clinicContact.trim() == "") {
      return notification["error"]({
        message: "Please fill the clinic contact field",
      });
    }
    if (this.state.clinicLink.trim() == "") {
      return notification["error"]({
        message: "Please fill the clinic link field",
      });
    }
    if (!clinicNameRegex.test(this.state.clinicName)) {
      return this.setState({
        clinicNameErr: "Please enter a valid clinic name",
      });
    }
    if (!address.test(this.state.clinicAddress)) {
      return this.setState({
        clinicAddressErr: "Please enter a valid clinic address",
      });
    }
    if (!clinicNumberRegex.test(this.state.clinicContact)) {
      return this.setState({
        clinicContactErr: "Please enter a valid clinic number",
      });
    }
    if (!clincLinkRegex.test(this.state.clinicLink)) {
      return this.setState({
        clinicLinkErr: "Please enter a valid clinic link",
      });
    }
    let apiBody = {
      name: this.state.clinicName,
      address: this.state.clinicAddress,
      contact_no: this.state.clinicContact,
      link: this.state.clinicLink,
      images: this.state.clinicImage
    }
    this.postCreateClinicDestailsApiCallId = this.apiCall({
      contentType: configJSON.getDoctorProfileApiContentType,
      method: configJSON.postDoctorProfileImageApiMethod,
      endPoint: configJSON.postCreateClinicDestailsApiEndPoint,
      body: apiBody
    });
  };

  postCreateClinicDestailsSuccessCallBack = (response: object) => {
    notification["success"]({
      message: "Clinic created successfully",
    });
    this.setState({
      showCreateClinic: false
    })
    this.getClinicDetailsIndexfunction();
    this.setState({
      clinicName: "",
      clinicAddress: "",
      clinicContact: "",
      clinicLink: "",
      clinicImage: []
    })
  };

  handleCreateClinic = () => {
    this.setState({
      showCreateClinic: true,
      editClinicDetails: false
    });
  };

  handleClinicName = (event: { target: { value: string } }) => {
    const clinicInputValue = event.target.value;
    const nameRegex = /^[A-Za-z ']{2,40}$/;
    this.setState({
      clinicName: event.target.value,
    }, () => {
      if (nameRegex.test(clinicInputValue)) {
        this.setState({
          clinicNameErr: "",
        });
      } else if(clinicInputValue.length > 40) {
        this.setState({
          clinicNameErr: "Please enter the clinic name within character limit",
        }); 
      }
      else {
        this.setState({
          clinicNameErr: clinicInputValue ? "Please enter a valid clinic name" : "This field is required.",
        });
      }
    });
  };

  handleClinicAddress = (event: { target: { value: string } }) => {
    const clinicInputValue = event.target.value;
    const addressRegex = /^[0-9A-Za-z\s.,#-]{5,100}$/;
    this.setState({
      clinicAddress: event?.target.value,
    }, () => {
      if (addressRegex.test(clinicInputValue)) {
        this.setState({
          clinicAddressErr: "",
        });
      } else if(clinicInputValue.length > 100){
        this.setState({
          clinicAddressErr: "Please enter the clinic address within character limit",
        });
      }
      else {
        this.setState({
          clinicAddressErr: clinicInputValue ? "Please enter a valid clinic address" : "This field is required.",
        });
      }
    });
  };

  handleClinicContactDetails = (event: { target: { value: string } }) => {
    const numberRegex = /^[6-9]\d{9}$/;
    const clinicInputValue = event.target.value;
    this.setState({
      clinicContact: event?.target.value
    }, () => {
      if (numberRegex.test(clinicInputValue)) {
        this.setState({
          clinicContactErr: "",
        });
      } else {
        this.setState({
          clinicContactErr: clinicInputValue ? "Please enter a valid clinic number" : "This field is required.",
        });
      }
    })
  };

  handleClinicLink = (event: { target: { value: string } }) => {
    const linkRegex = /\b(?!https?:\/\/)(?!w{1,2}\.\w{2,}\.)www\.[a-zA-Z0-9.-]+\.(?:co\.in|com)\b(?![\w.-])(?!\s)/;
    const clinicInputValue = event.target.value;
    this.setState({
      clinicLink: event?.target.value
    }, () => {
      if (linkRegex.test(clinicInputValue)) {
        this.setState({
          clinicLinkErr: "",
        });
      } else {
        this.setState({
          clinicLinkErr: clinicInputValue ? "Please enter a valid link address" : "This field is required.",
        });
      }
    })
  };

  handleCloseCreateClinic = () => {
    this.setState({
      showCreateClinic: false,
      clinicNameErr: "",
      clinicAddressErr: "",
      clinicContactErr: "",
      clinicLinkErr: "",
      clinicName: "",
      clinicAddress: "",
      clinicContact: "",
       clinicLink: "",
       clinicImage: []
    });
  };
  
  handleTimeSlots = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { followUpTimeHalf, followUpTimeHr, timeZone } = this.state;
    let updatedSlotHalf = followUpTimeHalf.slice();
    let updatedSlotHr = followUpTimeHr.slice();
    let showHalfFollowUpTime = false;
  
    switch (timeZone.time) {
      case "30 Minutes":
        updatedSlotHalf = updatedSlotHalf.map((time) => ({
          ...time,
          isSelect: time.timeSlot === event.target.name ? event.target.checked : time.isSelect,
        }));
        break;
  
      case "60 Minutes":
        updatedSlotHr = updatedSlotHr.map((time) => ({
          ...time,
          isSelect: time.timeSlot === event.target.name ? event.target.checked : time.isSelect,
        }));

        break;
  
      default:
        break;
    }
  
    const { followUpTime } = this.state;

    const updatedFollowUpTime = event.target.checked
      ? [...followUpTime, { [event.target.name]: event.target.checked }]
      : followUpTime.filter((item) => !item[event.target.name]);
  
    this.setState({
      followUpTimeHalf: updatedSlotHalf,
      followUpTimeHr: updatedSlotHr,
      showHalfFollowUpTime: (timeZone.time === "30 Minutes" || timeZone.time === "60 Minutes") ? true : false,
      followUpTime: updatedFollowUpTime,
    }, () => {
      const followUpTimeArr = updatedFollowUpTime.map((time) => {
        const timeKey = Object.keys(time)[0];
        const splittedArr = timeKey.split("-");
        return {
          start_time: splittedArr[0],
          end_time: splittedArr[1],
        };
      });
  
      this.setState({ followUpTimeSlotsCount: followUpTimeArr });
    });
  };
  

  getClinicDetailsIndexfunction = async () => {
    this.getClinicDetailsIndexApiCallId = this.apiCall({
      contentType: configJSON.getDoctorProfileApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getClinicDetailsIndexApiEndPoint,
    });
  };

  getClinicDetailsIndexSuccessCallBack = (response: ClinicDetailsType) => {
    this.setState({
      clinicId: response.data[0].id
    }, () => {
      this.getShowClinicDetailsfunction();
    });
  };

  getShowClinicDetailsfunction = async () => {
    this.getShowClinicDetailsApiCallId = this.apiCall({
      contentType: configJSON.getDoctorProfileApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getShowClinicDetailsApiEndPoint}?id=${this.state.clinicId}`,
    });
  };

  getShowClinicDetailsSuccessCallBack = (response: DetailsType) => {
    this.setState({
      showClinicData: response,
      getClinicImages: response.data.attributes?.clinic_images,
      getClinicName: response.data.attributes?.name,
      getClinicAddress: response.data.attributes?.address,
      getClinicContactNum: response.data.attributes?.contact_no,
      getClinicLink: response.data.attributes?.link
    });
  };

  imgFilehandler = (event: any) => {
    let files = [...event.target.files];
    let base64Images: any = [];
    const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif','image/jpg'];
    files.forEach((file: any, index: number) => {
      if (allowedImageTypes.includes(file.type)) {
      const reader = new FileReader();
      reader.onload = () => {
        base64Images.push(reader.result);
        if (base64Images.length === files.length) {
          this.setState({ clinicImage: base64Images });
        }
      };
      reader.readAsDataURL(file);
    } else {
      notification["error"]({
        message: "Please upload valid image file.",
      });
    }
    });
  };

  editImgFilehandler = (event: any) => {
    let files = [...event.target?.files];
    let base64Images: any = [];
    const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif','image/jpg'];
    files.forEach((file: any, index: number) => {
      if (allowedImageTypes.includes(file.type)) {
      const reader = new FileReader();
      reader.onload = () => {
        base64Images.push(reader.result);
        if (base64Images.length === files.length) {
          this.setState({ editClinicImage: base64Images });
        }
      };
      reader.readAsDataURL(file);
    } else {
      notification["error"]({
        message: "Please upload valid image file.",
      });
    }
    });
  };

  handleEditClinic = () => {
    this.setState({
      editClinicDetails: true,
      showCreateClinic: false
    });
    this.getClinicDetailsIndexfunction();
  };

  editClinicDetails = async () => {
    let clinicNameRegex = /^[A-Za-z ']{2,40}$/;
    const clincLinkRegex = /\b(?!https?:\/\/)(?!w{1,2}\.\w{2,}\.)www\.[a-zA-Z0-9.-]+\.(?:co\.in|com)\b(?![\w.-])(?!\s)/;
    let address = /^[0-9A-Za-z\s.,#-]{5,100}$/;
    const clinicNumberRegex = /^[6-9]\d{9}$/;
    if (this.state.getClinicName.trim() == "") {
      return notification["error"]({
        message: "Please fill the clinic name field",
      });
    }
    if (this.state.getClinicAddress.trim() == "") {
      return notification["error"]({
        message: "Please fill the clinic address field",
      });
    }
    if (!clinicNameRegex.test(this.state.getClinicName)) {
      return this.setState({
        clinicNameErr: "Please enter a valid clinic name",
      });
    }
    if (!address.test(this.state.getClinicAddress)) {
      return this.setState({
        clinicAddressErr: "Please enter a valid clinic address",
      });
    }
    if (!clinicNumberRegex.test(this.state.getClinicContactNum)) {
      return this.setState({
        clinicContactErr: "Please enter a valid clinic number",
      });
    }
    if (!clincLinkRegex.test(this.state.getClinicLink)) {
      return this.setState({
        clinicLinkErr: "Please enter a valid clinic link",
      });
    }
    
    let editClinicApiBody = {
      name: this.state.getClinicName,
      address: this.state.getClinicAddress,
      contact_no: this.state.getClinicContactNum,
      link: this.state.getClinicLink,
      images: this.state.editClinicImage
    }
    this.editClinicDetailsApiCallId = this.apiCall({
      contentType: configJSON.getDoctorProfileApiContentType,
      method: configJSON.putDoctorRegisterApiMethod,
      endPoint: `${configJSON.editClinicDetailsApiEndPoint}?id=${this.state.clinicId}`,
      body: editClinicApiBody
    });
  };

  editClinicDetailsSuccessCallBack = (response: object) => {
    notification["success"]({
      message: "Clinic details updated.",
    });
    this.setState({
      editClinicDetails: false,
      editClinicImage: []
    }, () => {
      this.getClinicDetailsIndexfunction();
    });
  };

  handleCloseEditClinic = () => {
    this.setState({
      editClinicDetails: false,
      clinicNameErr: "",
      clinicAddressErr: "",
      clinicContactErr: "",
      clinicLinkErr: ""
    });
  };

  handleEditClinicName = (event: { target: { value: string } }) => {
    const nameRegex = /^[A-Za-z ']{2,40}$/;
    const clinicInputValue = event.target.value;
    this.setState({
      getClinicName: event.target.value,
    }, () => {
      if (nameRegex.test(clinicInputValue)) {
        this.setState({
          clinicNameErr: "",
        });
      } else if (clinicInputValue.length > 40){
        this.setState({
          clinicNameErr: "Please enter the clinic name within character limit",
        });
      } 
      else{
        this.setState({
          clinicNameErr: clinicInputValue ? "Please enter a valid clinic name": "This field is required.",
        });
      }
    });
  };

  handleEditClinicAddress = (event: { target: { value: string } }) => {
    const clinicInputValue = event.target.value;
    const addressRegex = /^[0-9A-Za-z\s.,#-]{5,100}$/;
    this.setState({
      getClinicAddress: event?.target.value,
    }, () => {
      if (addressRegex.test(clinicInputValue)) {
        this.setState({
          clinicAddressErr: "",
        });
      } else if(clinicInputValue.length > 100){
        this.setState({
          clinicAddressErr: "Please enter the clinic address within character limit",
        });
      } 
      else {
        this.setState({
          clinicAddressErr: clinicInputValue ? "Please enter a valid clinic address" : "This field is required.",
        });
      }
    });
  };

  handleEditClinicContactDetails = (event: { target: { value: string } }) => {
    const numberRegex = /^[6-9]\d{9}$/;
    const clinicInputValue = event.target.value;
    this.setState({
      getClinicContactNum: event?.target.value
    }, () => {
      if (numberRegex.test(clinicInputValue)) {
        this.setState({
          clinicContactErr: "",
        });
      } else {
        this.setState({
          clinicContactErr: clinicInputValue ? "Please enter a valid clinic number" : "This field is required.",
        });
      }
    })
  };

  handleEditClinicLink = (event: { target: { value: string } }) => {
    const linkRegex = /\b(?!https?:\/\/)(?!w{1,2}\.\w{2,}\.)www\.[a-zA-Z0-9.-]+\.(?:co\.in|com)\b(?![\w.-])(?!\s)/;
    const clinicInputValue = event.target.value;
    this.setState({
      getClinicLink: event?.target.value
    }, () => {
      if (linkRegex.test(clinicInputValue)) {
        this.setState({
          clinicLinkErr: "",
        });
      } else {
        this.setState({
          clinicLinkErr: clinicInputValue ? "Please enter a valid link address" : "This field is required.",
        });
      }
    })
  };

  handleDeleteImage = () => {
    this.setState({
      deleteClinicImage: !this.state.deleteClinicImage
    });
  };

  handleCheckBoxClick = (item: ImageType, event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      this.setState({
        getImageId: item.id
      });
    } else {
      this.setState({
        getImageId: 0
      });
    }
  };

  deleteImagefunction = async () => {
    this.deleteImageApiCallId = this.apiCall({
      method: configJSON.putDoctorRegisterApiMethod,
      endPoint: `${configJSON.deleteImageApiEndPoint}?id=${this.state.getImageId}`,
    });
  };

  deleteImageSuccessCallBack = (response: object) => {
    notification["success"]({
      message: "Image deleted successfully.",
    });
    this.setState({
      deleteClinicImage: false,
      getImageId: 0
    }, () => {
      this.getClinicDetailsIndexfunction();
    });
  };

  handleDoctorDocumentSuccessCallBack = (response: any) => {
   if(response?.data?.attributes?.identity_details != null){
    localStorage.setItem("is_Upload", "uploaded")
   }
  };

  handleEditDoctor = () => {
    this.props.history.push("/editdoctordetails")
  };

  handleDoctorPhone = (event: { target: { value: string } }) => {

    const doctorPhoneNumValue = event.target.value;
    const numberRegex = /^[6-9]\d{9}$/;

    const isValidPhoneNumber = numberRegex.test(doctorPhoneNumValue);
    const doctorPhoneNumErr = isValidPhoneNumber ? "" : "Please enter a valid phone number.";

    this.setState({
        doctorPhoneNum: doctorPhoneNumValue,
        doctorPhoneNumErr: doctorPhoneNumErr
    });
  };

  handlePopupClose = () => {
    this.setState({
        showPopup: false
    });
  };

  handleUploadDoc = () => {
    this.props.history.push("/uploaddoc")
  };

  isProfileUpdate=async()=>{
    let doctorNum =  localStorage.getItem("doctorNum")  &&  JSON.parse(localStorage.getItem("doctorNum") as any) || "" ;
    if(doctorNum !== "" && doctorNum != null ){
      this.handleUploadDoc();
    }else{
      notification["error"]({
        message: "Please  update profile before uploading documents"
      }); 
    }
  }

  handleViewDoc = () => {
    this.props.history.push("/viewdocument")
  };

  handleVerifyKyc = () => {
    notification["error"]({
      message: "Please upload or verify your documents to complete the KYC process",
    });
  };

  removeCountryCode(phoneNumber:string) {
    if(phoneNumber?.length >10){
      return phoneNumber.replace(/^91/, '');
    }else{
      return phoneNumber
    }
  }

  getDoctorDocumentfunction = async () => {
    this.getDoctorDocumentApiCallId = this.apiCall({
      contentType: configJSON.getDoctorProfileApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getDoctorDocumentsApiEndPoint}?id=${this.state.getDoctorId}`,
    });
  };

  handleNavigateEditprofileDetails = () => {
    this.props.history.push("/editdoctordetails");
  };

  handelShowDoctorProfilePopup = () => {
    this.setState({ showPopup: !this.state.showPopup });
  };


  handlLogoutDoctor = () => {
    this.props.history.push("/");
  };

  handleClinicNavigate = () => {
    this.setState({
      showCreateClinic: false,
      editClinicDetails: true
    });
  };

  handleEditClinicForm = () => {
    this.setState({
      editClinicDetails: false
    });
  };

  handleClinicCopyLink = () => {
    const text: any = document.getElementById("copyLinkId");
      navigator.clipboard?.writeText(text.innerText)
      notification["success"]({
        message: "Clinic link copied"
      }); 
  };

  clinicDeleteImagefunction = async () => {
    this.deleteImageApiCallId = this.apiCall({
      method: configJSON.putDoctorRegisterApiMethod,
      endPoint: `${configJSON.deleteImageApiEndPoint}?id=${this.state.getImageId}`,
    });
  };

  clinicDeleteImageSuccessCallBack = (response: object) => {
    notification["success"]({
      message: "Image deleted successfully.",
    });
    this.setState({
      deleteClinicImage: false,
      getImageId: 0
    }, () => {
      this.getClinicDetailsIndexfunction();
    });
  };

  imageShowSrc = () => {
    const { profilePic, userProfilePicture } = this.state;
    if (profilePic && profilePic  instanceof Blob) {
      return URL.createObjectURL(profilePic);
    } else if (userProfilePicture) {
      return config + userProfilePicture
     }else {
      return Default;
    }
  };

  handleEditCopyLink = () => {
    const link: any = document.getElementById("EditCopyLinkId");
      navigator.clipboard?.writeText(link.value)
      notification["success"]({
        message: "Clinic link copied",
      });
  };

  handleNewCopyLink = () => {
    let text: any = document.getElementById("newCopyLinkId");
      navigator.clipboard?.writeText(text.value)
      notification["success"]({
        message: "Clinic link copied",
      });
    }
    
  getSpecializationShow = async () => {
    this.geSpecializationApiCallId = this.apiCall({
      contentType: configJSON.getDoctorProfileApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getSpecializationAPiEndPoint,
    });
  };

  getSpecializationSuccessCallBack = (res: SpecializationType) => {
    this.setState({
      specializationData: res
    });
  };

  getDoctorCategoryShow = async () => {
    this.getCategoryApiCallId = this.apiCall({
      contentType: configJSON.getDoctorProfileApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getCategoryAPiEndPoint,
    });
  };

  getDoctorCategorySuccessCallBack = (res: CategoryType) => {
    this.setState({
      categoryData: res
    });
  };

  handleDoctorExperience = (event: { target: { value: string } }) => {
    const regNumberyInputValue = event.target.value;
    const regNUmRegex = /^(?:[1-9]|[1-4]\d|50)(?:\.\d)?$/;
    this.setState({
      experience: regNumberyInputValue,
    }, () => {
      if (regNUmRegex.test(regNumberyInputValue)) {
        this.setState({
          experienceErr: '',
        });
      } else {
        this.setState({
         experienceErr: regNumberyInputValue ? 'Please enter a valid experience year' : 'This field is required.',
        });
      }
    });
  };

  handleCategory = (event: ChangeEvent<{ value: unknown}>) => {
    const value = event.target.value;
    this.setState({
      category: value,
    }, () => {
      if(this.state.category === null){
        return this.setState({categoryErr: "Please select doctor category"});
      } else {
        return this.setState({categoryErr: ""});
      }
    });
  };

  handleSpecialization = (event: ChangeEvent<{ value: unknown | string}>) => {
    const value = event.target.value;
    this.setState({
      doctorSpeciality: value,
    }, () => {
      if(this.state.doctorSpeciality === null || this.state.doctorSpeciality === ""){
        this.setState({
          specialityErr: "Please select doctor specialization"
        });
        return true
      } else {
        return this.setState({specialityErr: ""});
      }
    });
  };

  handleDoctorMRName = (event: { target: { value: string } }) => {
    const representativeNameValue = event.target.value;
    const regNUmRegex = /^[A-Za-z ']+$/;
    this.setState({
      representativeName: representativeNameValue,
    }, () => {
      if (regNUmRegex.test(representativeNameValue)) {
        this.setState({
          representativeNameErr: '',
        });
      } else {
        this.setState({
          representativeNameErr: representativeNameValue ? 'Please enter a valid name' : 'This field is required.',
        });
      }
    });
  };

  handleDoctorMRNumber = (event: { target: { value: string } }) => {
    const representativeMobileNumber = event.target.value;
    const regNUmRegex = /^[6-9]\d{9}$/;
    this.setState({
      representativeMobileNo: representativeMobileNumber,
    }, () => {
      if (regNUmRegex.test(representativeMobileNumber)) {
        this.setState({
          representativeMobileNoErr: '',
        });
      } else {
        this.setState({
          representativeMobileNoErr: representativeMobileNumber ? 'Please enter a valid mobile Number' : 'This field is required.',
        });
      }
    });
  };

  handleDoctorAbout = (event: { target: { value: string } }) => {
    const aboutInputValue = event.target.value;
    const regNUmRegex = /^[()\.\/,A-Za-z0-9]+(?: [()\.\/,A-Za-z0-9]+)*$/;
    this.setState({
      doctorsAboutInput: aboutInputValue,
    }, () => {
      if (regNUmRegex.test(aboutInputValue)) {
        this.setState({
          doctorsAboutInputErr: '',
        });
      } else {
        this.setState({
          doctorsAboutInputErr: aboutInputValue ? "Please enter valid information about the doctor" : 'This field is required.',
        });
      }
    });
  };

  handleDoctorQualification = (event: { target: { value: string } }) => {
    const regQualificationInputValue = event.target.value;
    const regEx = /^[A-Za-z]+(?: [A-Za-z]+)*(?:,\s?[A-Za-z]+(?: [A-Za-z]+)*)*$/;
    const valuesArray = regQualificationInputValue.split(",").toString();
    this.setState({
      qualification : valuesArray,
    }, () => {
      if (regEx.test(regQualificationInputValue)) {
        this.setState({
          qualificationErr: '',
        });
      } else {
        this.setState({
          qualificationErr: regQualificationInputValue ? 'Please enter a valid qualification' : 'This field is required.',
        });
      }
    });
  };

  handleChange = (event : ChangeEvent<{ value: unknown | string[]}>) => {
  this.setState({selectedValues: event.target.value}, () => {
    if(this.state.selectedValues === null || this.state.selectedValues.length == 0){
      return this.setState({languageErr: "Please select known language"});
    } else {
      return this.setState({languageErr: ""});
    }
  });
  };

  handleDocSpecialization = () => {
    this.setState({
      doctorSpeciality: this.state.doctorSpeciality,
    }, () => {
      if(this.state.doctorSpeciality === null || this.state.doctorSpeciality === ""){
        this.setState({
          specialityErr: "Please select doctor specialization"
        });
        return true
      }
    });
  };

  handleLanguageChange = () => {
    this.setState({selectedValues: this.state.selectedDays}, () => {
      if(this.state.selectedValues === null || this.state.selectedValues.length == 0){
        return this.setState({languageErr: "Please select known language"});
      }
    });
    };

    handleNavigate = () => {
      this.setState({
        showCreateClinic: false,
        editClinicDetails: true
      });
    };
  
    handleClinicForm = () => {
      this.setState({
        editClinicDetails: false
      });
    };

    handleDocCategory = () => {
      this.setState({
        category: this.state.category,
      });
    };

    handleAddress = () => {
      this.setState({
        clinicAddress: this.state.clinicAddress,
      });
    };

    editClinicCallBack = () => {
      this.setState({
        editClinicDetails: false,
        editClinicImage: []
      });
    };
  
    handleditClinic = () => {
      this.setState({
        editClinicDetails: false,
        clinicNameErr: "",
        clinicAddressErr: "",
        clinicContactErr: "",
        clinicLinkErr: ""
      });
    };

    handleDoctorCityFunction = () => {
      this.setState({
        doctorCityName: this.state.doctorCityName,
      });
    };
  
    handleSpecialityFuction = () => {
      this.setState({
        doctorSpeciality: this.state.doctorSpeciality,
      });
    };

  handleClinicCloseFunction = () => {
    this.setState({
      clinicName: "",
      clinicAddress: "",
      clinicContact: "",
      clinicLink: "",
      clinicImage: [],
      showCreateClinic: false,
      clinicNameErr: "",
      clinicAddressErr: "",
      clinicContactErr: "",
      clinicLinkErr: "",
    });
  };

 handleEditAvailability = (id: string) => {
  this.setState({
    availabilityId: id,
    isEdit: true
  }, () => {
    this.getAvailabilityByIdShow()
  });
 };

 getAvailabilityByIdShow = async () => {
  this.getAvailabilityAPICallID = this.apiCall({
    contentType: configJSON.getDoctorProfileApiContentType,
    method: configJSON.validationApiMethodType,
    endPoint: `${configJSON.getAvailableAPiEndPoint}?id=${this.state.availabilityId}`
  });
  this.setState({
    isLoader: true
  });

};

getAvailabilityByIdShowSuccessCallBack = (res: AvailabilityTypeData) => {
  this.setState({
    isLoader: false
  });
  const timeSlots = res.data.attributes.time_slots[0];
  const start_time = timeSlots.start_time.includes("30");
  const end_time = timeSlots.end_time.includes("30");
  const timeZone = start_time || end_time;
  const updatedRes = res.data.attributes.time_slots.map((timeSlots: {start_time:string ,end_time:string;}) => {
    const start_time = timeSlots.start_time.trim();
    const end_time = timeSlots.end_time.trim();
    return {
      timeSlots: `${start_time} - ${end_time}`
    }
  });

  if (timeZone) {
    const temp = this.state.editFollowUpTimeHalf.slice();
    updatedRes.forEach((time) => {
      const matchedIdx = temp.findIndex((tmepTime) => tmepTime.timeSlot === time.timeSlots);
      temp[matchedIdx].isSelect = true;
    });
    this.setState({
      editFollowUpTimeHalf: temp,
      editTimeZone: { time: "30 Minutes" },
      showEditHalfFollowUpTime: true
    });
  } else {
    const tempArr = this.state.editFollowUpTimeHr.slice();
    updatedRes.forEach((time) => {
      const matchedId = tempArr.findIndex((tmepTime) => tmepTime.timeSlot === time.timeSlots);
      tempArr[matchedId].isSelect = true;
    });
    this.setState({
      editFollowUpTimeHr: tempArr,
      editTimeZone: { time: "60 Minutes" },
      showEditHalfFollowUpTime: true
    });
  }
  const newArr = res.data.attributes.day_of_week.map((item) => item);
this.setState({
  editWeekDaysValue: newArr
});
  
  this.setState({
    availabilityData: res,
    editModeValue: res.data.attributes.mode_type,
  });
  this.handleEditAvailabilitySlots();
}

editHandleTimeSlots = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { editFollowUpTimeHalf, editFollowUpTimeHr, editTimeZone } = this.state;
  let updatedSlotHalf = editFollowUpTimeHalf.slice();
  let updatedSlotHr = editFollowUpTimeHr.slice();
    switch (editTimeZone.time) {
      case "30 Minutes":
        updatedSlotHalf = updatedSlotHalf.map((time) => ({
          ...time,
          isSelect: time.timeSlot === event.target.name ? event.target.checked : time.isSelect,
        }));

        break;

    case "60 Minutes":
      updatedSlotHr = updatedSlotHr.map((time) => ({
        ...time,
        isSelect: time.timeSlot === event.target.name ? event.target.checked : time.isSelect,
      }));

        break;

      default:
        break;
  }

  this.setState({
  editFollowUpTimeHalf: updatedSlotHalf,
  editFollowUpTimeHr: updatedSlotHr,
  showHalfFollowUpTime: (editTimeZone.time === "30 Minutes" || editTimeZone.time === "60 Minutes") ? true : false,
  }, () => {
    this.handleEditAvailabilitySlots();
  }
  )
  };

  handleEditAvailabilitySlots = () => {
  let timeSlot = this.state.editTimeZone.time === "30 Minutes" ? this.state.editFollowUpTimeHalf : this.state.editFollowUpTimeHr;
  let state = timeSlot.filter((item) => item.isSelect)
  const newState = state.map((item) => {
  const splitArr = item.timeSlot.split(" - ");
    return {
      start_time: splitArr[0],
      end_time: splitArr[1],
    }
  })
  this.setState({
    mainArr: newState
  });
  };

  editHandleSelectModeType = (value: string | unknown ) => {
    this.setState({
      editModeValue: value
    });
  };

  editHandleSelectDays = (value: string[]) => {
    this.setState({
      editWeekDaysValue: value
    });
  };

  handleCloseEditAvailability = () => {
    this.setState({
      isEdit: false,
    },() => {
      this.getAvailabilityByIdShow();
    });
  };

  putDoctorEditAvailability = async () => {
   if(this.state.mainArr.length <= 0){
      notification["error"]({
        message: "Please select Duration",
      });
    }
    else{
      let apiBody = {
        availability: {
          time_slots_attributes: this.state.mainArr,
          mode_type: this.state.editModeValue,
          day_of_week: this.state.editWeekDaysValue
        }
   }
      this.putDoctorAvailabilityApiCallId = this.apiCall({
        contentType: configJSON.getDoctorProfileApiContentType,
        method: configJSON.putDoctorRegisterApiMethod,
        endPoint: `${configJSON.putDoctorCreateAvailabilityAPiEndPoint}?id=${this.state.availabilityId}`,
        body: apiBody
      });
    }
    this.setState({
      isLoader: false
    });
};

putDoctorAvailabilitySuccessCallBack = (responseJson: ValidResponseType) => {
  if(responseJson.message){
    notification["error"]({
      message: responseJson.message,
    });
  }else{ 
 notification["success"]({
   message: "Availability updated successfully",
 });
 let temFollowUpTimeHr =this.state.editFollowUpTimeHr.map((item) => {
  return{
    ...item,
    isSelect:false
  }
 });

 let temFollowUpTimeHalf =this.state.editFollowUpTimeHalf.map((item) => {
  return{
    ...item,
    isSelect:false
  }
 });
 this.setState({
   isLoader: false,
   isEdit: false,
   mainArr: [],
   editFollowUpTimeHr:temFollowUpTimeHr,
   editFollowUpTimeHalf: temFollowUpTimeHalf,
   showEditHalfFollowUpTime: false
 });
this.getDoctorProfileDetailsfunction();
}
};
  // Customizable Area End
}
