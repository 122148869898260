import React from 'react'
// Customizable Area Start
import { Link } from 'react-router-dom';
import { Col, Row, Modal } from "antd";
import { mainsub, ortho,banner7,banner9,banner10 } from "./assets";
import { Carousel } from "react-responsive-carousel";
import LandingpageController from "../src/LandingpageController.web";
import NavbarCommonComponent from '../../../components/src/NavbarCommonComponent.web';
import DoctorsCommonComponent from '../../../components/src/DoctorsCommonComponent.web';
import ProceduresCommonComponent from '../../../components/src/ProceduresCommonComponent.web';
import FooterCommonComponent from '../../../components/src/FooterCommonComponent.web';
import FooterDocotr from "../../../components/src/FooterDoctor.web";
import MerilLoader from '../../../components/src/MerilLoader.web';
import PatientProfilePopup from "../../../components/src/PatientProfilePopup.web";
let config = require('../../../framework/src/config.js').baseURL;
import Stories from "react-insta-stories";
import CancelIcon from '@material-ui/icons/Cancel';

// Customizable Area End

class LandingPage extends LandingpageController {
    // Customizable Area Start
    // Customizable Area End

    // Customizable Area Start
    renderNavigationHomeTitle = () => {

        return (
                <div className="navigationContainer">
                    <Link to="/patienthome" className={window.location.pathname === "/patienthome" ? "activeColor" : ""}>Home</Link>
                    <Link to="/patient-appointment-landingpage">Appointments</Link>
                    <Link to="/Services">Services</Link>
                    <Link to="/patientmessage">Messages</Link>
                </div>
        )
    }
    // Customizable Area End

    // Customizable Area Start
    renderCarousell = () => {
        return (
            <div>
                <Carousel
                    className='renderMainCarousel'
                    showStatus={false}
                    showThumbs={false}
                    showArrows={false}
                    infiniteLoop={true}
                    autoPlay={true}
                    stopOnHover={false}
                    
                >
                     <div>
                        <img className='carouselImage' src={banner10} alt="CarouselImage"/>
                    </div>
                    <div>
                        <img className='carouselImage' src={banner7} alt="CarouselImage" />
                    </div>
                    <div>
                        <img className='carouselImage' src={banner9} alt="CarouselImage" />
                    </div>
                </Carousel>
            </div>
        )
    }
    // Customizable Area End
    // Customizable Area Start
    renderOurServices = () => {
        return (
                <div className='ourServicesContainer'>
                    <div className='ourServicesHeaderPart topmargin'>
                        <h2 className='ourServicesTitle'>Our Services</h2>
                    </div>
                    <Row className='cardRow' gutter={[20, 20]}>
                        {this.state.ourServicesData.map((item) => {
                            return (
                                <Col data-test-id="ourServicesID" key={item.id} xl={4} lg={6} md={8} sm={12} xs={24} onClick={()=>this.handleOurServices(item)} >
                                    <div className='servicesCard'>
                                        <div className='servicesCardImgBox'>
                                            <img src={config + item.attributes.service_image} className='servicesCarddImg' />
                                        </div>
                                        <p className='servicesCardText'>
                                            {item.attributes.title}
                                        </p>
                                        <p className='servicesSubText servicesSubTextHide' dangerouslySetInnerHTML={{
                                            __html: item.attributes.description.slice(0,60),
                                        }} />
                                    </div>
                                </Col>
                            )
                        })}

                    </Row>
                </div>
        )
    }
    // Customizable Area End
    // Customizable Area Start
    // Customizable Area End

    // Customizable Area Start
    renderShortVideos = () => {
        let shortVideosDataShow = this.state.shortVideosDataSliced ? this.state.shortVideoListData : this.state.shortVideoListData.slice(0, 6)
        return (
          
                <div className='ourServicesContainer'>
                    <div className='ourServicesHeaderPart'>
                        <h2 className='ourServicesTitle'>Short Videos</h2>
                        <p data-test-id="shortVideosId" className='ourServicesSubTitle' onClick={this.handleShortVideossData}>
                            {
                                this.state.shortVideosDataSliced ? "View less" : "See All"
                            }
                        </p>
                    </div>
                    <Row className='hospitalsRow' gutter={[20, 20]}>
                        {shortVideosDataShow.map((item: any) => {
                            return (
                                <Col xl={4} lg={6} md={8} sm={12} xs={24}>
                                    <div className='shortVideosCard'>
                                        <div className='shortVideosCardImgBox'>
                                            <video className='shortVideosCardImg' controls>
                                                <source src={config + item.attributes.stories[0]?.url} type="video/mp4" />
                                            </video>
                                        </div>
                                        <p className='shortVideosCardTextBox'>
                                            {item.attributes.name}
                                        </p>
                                    </div>
                                </Col>
                            )
                        })}

                    </Row>
                </div>
        )
    }
    // health stories
    renderHealthStories = () => {
        let HealthStoriesData = [
            { image: banner10, textName: "Cancer Care" },
            { image: banner10, textName: "Diabetes" },
            { image: banner10, textName: "Cancer Care" },
            { image: banner10, textName: "Diabetes" },
            { image: banner10, textName: "Cancer Care" },
            { image: banner10, textName: "Cancer Care" },
        ];
        const stories:any = HealthStoriesData.map((item) => ({
            url: item.image,
            duration: 5000,
            header: {
              heading: item.textName,
              subheading: "Health Awareness",
            },
          }));
    
        return (
            <>
                <div className='ourServicesContainer'>
                    <div className='ourServicesHeaderPart'>
                        <h2 className='ourServicesTitle'>Health Stories</h2>
                    </div>
                </div>
                <div className="healthStoriesContainer">
                    <div className="healthStoriesWrapper">
                        <Row gutter={[16,16]}>
                            {HealthStoriesData.map((item, index) => (
                                <Col
                                    key={index}
                                    xl={4}
                                    lg={6}
                                    md={8}
                                    sm={12}
                                    xs={24}
                                    style={{ textAlign: "center" }}
                                >
                                    <div className="healthStoriesItem" onClick={() => this.showStories(index)} data-test-id="healthStoriesItem">
                                        <img
                                            src={item?.image}
                                            alt={item?.textName}
                                            className="healthStoriesImage"
                                        />
                                        <p className="healthStoriesText">{item?.textName}</p>
                                    </div>
                                </Col>
                            ))}
                        </Row>

                            <Modal
          open={this.state.isModalVisible}
          footer={null}
          width={350}
          onCancel={this.closeStories}
          data-test-id="closeStories"
        >
          <div style={{ position: "relative" }}>
            <Stories
              stories={stories}
              defaultInterval={4000}
              width="100%"
              height={500}
              storyStyles={{ borderRadius: 0, margin: 0 }}
            />
            <CancelIcon
              onClick={this.closeStories}
              className='cancelBtn'
                         />
          </div>
        </Modal>
                    </div>
                </div>
            </>
        );
    };
    
    
    
    // 
    // Customizable Area End

    // Customizable Area Start
    renderCarouselSecondPart = () => {
        return (
                <Carousel
                    interval={3000}
                    showThumbs={false}
                    showArrows={false}
                    showStatus={false}
                    infiniteLoop={true}
                    autoPlay={true}
                    stopOnHover={false}
                    className='secondCarousel'
                >
                    <div>
                        <img className='carouselImage secondCarouselImg' src={mainsub} />
                    </div>
                    <div>
                        <img className='carouselImage secondCarouselImg' src={mainsub} />
                    </div>
                    <div>
                        <img className='carouselImage secondCarouselImg' src={mainsub} />
                    </div>
                    <div>
                        <img className='carouselImage secondCarouselImg' src={mainsub} />
                    </div>
                    <div>
                        <img className='carouselImage secondCarouselImg' src={mainsub} />
                    </div>
                </Carousel>
        )
    }
    // Customizable Area End

    // Customizable Area Start
    renderImageOrtho = () => {
        return (
            <div>
                <img className='orthoImg' src={ortho} alt="Ortho" />
            </div>
        )
    }
    // Customizable Area End
    // Customizable Area Start
    renderPostCovidCare = () => {
        return (
                <div className='ourServicesContainer'>
                    <div className='ourServicesHeaderPart'>
                        <h2 className='ourServicesTitle'>Post Covid Care Package</h2>
                    </div>
                    {
                        this.state.postCovidCareData.map((item: any) => {
                            return (
                                <Carousel
                                    interval={3000}
                                    showThumbs={false}
                                    showArrows={false}
                                    showStatus={false}
                                    infiniteLoop={true}
                                    autoPlay={true}
                                    stopOnHover={false}
                                >
                                    {item.attributes?.images.map((subitem: any) => {
                                        return (
                                            <div className='advertisementImgBox'>
                                                <img className='advertisementImg' src={config + subitem?.url} />
                                            </div>
                                        )
                                    })}
                                </Carousel>
                            )
                        })
                    }
                </div> 
               )
    }
    // Customizable Area End
    // Customizable Area Start
    render() {
        const isLogin = localStorage.getItem("isLogin");
        const isPatientGoogleLogin = localStorage.getItem("isPatientGoogleLogin");
        return (
            <div>
                <NavbarCommonComponent handleProfile={this.showDetailsHandler} handleFunction={this.getTopDoctorsShow} handleDoctorFunction={this.getTopCardiologistDocShow}/>
               <PatientProfilePopup data-test-id="handleLogoutId" profileData={this.state.showPopup} logoutFunctiuon={this.handleLogout} />
                { (isLogin || isPatientGoogleLogin) && this.renderNavigationHomeTitle()}
                {this.renderCarousell()}
                <div className='itemContainerWiidth'>
                    {this.state.ourServicesData.length>0 && this.renderOurServices()}
                    {this.state.trendingTherapiesData.length>0 && <ProceduresCommonComponent data-test-id="trendingId" handleProceduresDataList={this.handleTherapiesData} dataList={this.state.trendingTherapiesDataSliced ? this.state.trendingTherapiesData.slice(0, 4) : this.state.trendingTherapiesData} heading={"Trending Therapies"} handleTrendingTherapies={this.handletrendingTherapies} />}
                    {this.state.topDoctorsData.length>0 && <DoctorsCommonComponent data-test-id="topDoctorsId" handleDoctorsDataList={this.handleDoctorsNearbyData} dataList={this.state.topDoctorsDataSliced ? this.state.topDoctorsData.slice(0, 3) : this.state.topDoctorsData} heading={"Top Doctors Nearby"} />}
                    {this.state.shortVideoListData.length>0 && this.renderShortVideos()}
                    {this.renderHealthStories()}
                    {this.state.topCardiovascularData.length>0 && <ProceduresCommonComponent data-test-id="topCardiovascularId" handleProceduresDataList={this.handleTopCardiovascularData} dataList={this.state.topCardiovascularDataSliced ? this.state.topCardiovascularData.slice(0, 4) : this.state.topCardiovascularData} heading={"Top Cardiovascular Procedures"} handleTrendingTherapies={this.handletrendingTherapies} />}
                    {this.renderCarouselSecondPart()}
                   {this.state.topCardiologistDoctorsData.length>0 && <DoctorsCommonComponent data-test-id="topCardiologistId" handleDoctorsDataList={this.handleTopCardiologistData} dataList={this.state.topCardiologistDataSliced ? this.state.topCardiologistDoctorsData.slice(0, 3) : this.state.topCardiologistDoctorsData.slice(0,6)} heading={"Top Cardiologist"} />} 
                    {this.state.postCovidCareData.length>0 && this.renderPostCovidCare()}
                    {this.state.topOrthopaedicData.length>0 && <ProceduresCommonComponent data-test-id="topOrthopaedicId" handleProceduresDataList={this.handleTopOrthopaedicData} dataList={this.state.topOrthopaedicDataSliced ? this.state.topOrthopaedicData.slice(0, 4) : this.state.topOrthopaedicData.slice(0,5)} heading={"Top Orthopaedic Procedures"} handleTrendingTherapies={this.handletrendingTherapies} />}
                    {this.state.topOrthopaedicDoctorsData.length>0 && <DoctorsCommonComponent data-test-id="topOrthopaedicsDocId" handleDoctorsDataList={this.handleTopOrthopaedicsData} dataList={this.state.topOrthopaedicDoctorsDataSliced ? this.state.topOrthopaedicDoctorsData.slice(0, 3) : this.state.topOrthopaedicDoctorsData} heading={"Top Orthopaedic Doctors"} />}
                    {this.renderImageOrtho()}
                    {this.state.labTestData.length>0 && <DoctorsCommonComponent data-test-id="labTestID" handleDoctorsDataList={this.handleLabTestData} dataList={this.state.labTestDataSliced ? this.state.labTestData.slice(0, 3) : this.state.labTestData} heading={"Lab Tests"} />}
                    {this.state.radioLogyTestData.length>0 && <DoctorsCommonComponent data-test-id="radiologyTestID" handleDoctorsDataList={this.handleRadiologyData} dataList={this.state.radioLogyTestDataSliced ? this.state.radioLogyTestData.slice(0, 3) : this.state.radioLogyTestData.slice(0,6)} heading={"Radiology Tests"} />}
                </div>
                {
                    isLogin ? (<FooterDocotr/>):    <FooterCommonComponent />
                }
             
            </div>
        )
    }
    // Customizable Area End
}

// Customizable Area Start
export default MerilLoader(LandingPage);
export { LandingPage };
// Customizable Area End
