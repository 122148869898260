import React, { useEffect, useRef, useState } from "react";
// Customizable Area Start
import { AiOutlineRight } from "react-icons/ai";
import { Default, merilLogo } from "../../blocks/splashscreen/src/assets";

import { Link } from "react-router-dom";
import { qrcode } from "../../blocks/dataencryption/src/assets";
import { FaUserCircle } from "react-icons/fa";
// import {baseURL} from '../../framework/src/config'
let config = require('../../framework/src/config.js').baseURL;

// Customizable Area End

type PatientProfilePopupProps = {
  // Customizable Area Start
  profileData: any;
  logoutFunctiuon: any;
  // Customizable Area End
};

const PatientProfilePopup = ({
  // Customizable Area Start
  profileData,
  logoutFunctiuon,
}: // Customizable Area End
PatientProfilePopupProps) => {
  // Customizable Area Start
  const [patientDetails ,setPatientDetails] = useState<any>(null)
  const [patientPop ,setPatientPop] = useState<any>(false)
  const wrapperRef = useRef<any>(null);
  
  const[health_id,setHealth_id] = useState<any>()
  const[full_name,setFull_name] = useState<any>()
  const [profile_pic,setProfile_pic] = useState<any>("");

useEffect(()=>{
  const patientData = localStorage.getItem("patientDetails");
  const profilePic = localStorage.getItem("profile_pic");
  setProfile_pic(profilePic);
  const isPatientGoogleLogin = localStorage.getItem("isPatientGoogleLogin");
  const isDoctorGoogleLogin = localStorage.getItem("isDoctorGoogleLogin");

  setTimeout(()=>{
    const health_id= localStorage.getItem("health_id");
    const full_name= localStorage.getItem("full_name");

    setHealth_id(health_id);
    setFull_name(full_name);
  },1000)

  if (patientData !== null || isDoctorGoogleLogin || isPatientGoogleLogin ) {
    // setPatientDetails(JSON.parse(patientData));
    setPatientDetails(true);
  }
},[health_id])
useEffect(()=>{
if(profileData){
  setPatientPop(true)
}
},[profileData])
useEffect(()=>{
  function handleCLickOutSide(e:MouseEvent) {    
    if(wrapperRef.current && !wrapperRef.current.contains(e.target as Node)){
      setPatientPop(false)
    }
  
  }
  document.addEventListener("mousedown" ,handleCLickOutSide)

  return ()=> {
    document.removeEventListener("mousedown", handleCLickOutSide);
  }
  },[wrapperRef])


  // useEffect(()=>{
  //   alert("callback...");
  //   let isPatientGoogleLogin:any = localStorage.getItem("isPatientGoogleLogin") || "";
  //   let googleLoginExp:any = localStorage.getItem("userDetails");

  //   if(isPatientGoogleLogin){
  //     const expirationTime = new Date().getTime() + googleLoginExp.exp * 1000;
  //     const isTokenExpired = expirationTime < new Date().getTime();


  //     setTimeout(()=>{
  //       logoutFunctiuon()
  //     },expirationTime)
  
  //     if(isTokenExpired){
  //       logoutFunctiuon()
  //     }
  //   }

  //   alert("vikash...")
  // },[])
  const handleLogoutFunction = async () => {
  
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `${config}/account_block/set_online_status?user_type=patient&online_status=false`,
        {
          method: "PUT",
          headers: {
            token: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      logoutFunctiuon()
    } catch (error) {
      console.error("Error updating online status:", error);
    }
  };
  

  // Customizable Area End

  // Customizable Area Start
  return <>{patientPop  ?   <div >
  
  {patientDetails !== null &&
  <div className="patientPopupContainer"  tabIndex={0}     ref={wrapperRef}>
  <div
    className="container"
    style={{ display: "flex", alignItems: "center" }}

  >
    <div
      className="profile-info"
      style={{
        display: "flex",
        alignItems: "center",
        marginRight: "10px",
      }}
    >


{profile_pic ?
              <img src={profile_pic.includes("https") ? profile_pic :  config + profile_pic} style={{ width: "70px", height: "70px", borderRadius: "50%",marginRight:"10px" }} alt="" />
              :
              <p>
                <img src={Default} style={{ width: "60px", height: "60px", borderRadius: "50%",marginRight:"10px" }} />
              </p>
            }

      <div className="text-container" style={{ marginTop: "20px" }}>
        <span className="Patient-name-text">{full_name}</span>
        <p className="healthId-profile-text">Health ID <br/> #{health_id}</p>
    </div>
    </div>
    <div
      className="rightIcon"
      style={{
        display: "flex",
        alignItems: "center",
        marginLeft: "10px",
      }}
    >
      <div>
        <img
          src={qrcode}
          alt=""
          width={"30px"}
          height={"30px"}
          style={{ marginRight: "16px" }}
        />
      </div>
      <div style={{ marginLeft: "10px" }}>
        <AiOutlineRight />
      </div>
    </div>
  </div>
  <div style={{margin:"10px"}} className="Patient-navigationFeatures">
  
    <Link to="/PatientOrder" onClick={()=>setPatientPop(false)} >
      <p  className= "pop-text-item">Profile</p>
    </Link>
    <Link to="/PatientAppointmentsPage" onClick={()=>setPatientPop(false)}>
    <p className= "pop-text-item">Appointments</p>
    </Link>
    <p className= "pop-text-item">Treatment</p>
    <p className= "pop-text-item">Records</p>
    <p className= "pop-text-item">Insurance</p>
    <p className= "pop-text-item">Payments</p>
    <Link to="/ProfileSettingsPage"  onClick={()=>setPatientPop(false)}>
      <p className= "pop-text-item">Settings</p>
    </Link>
    <Link to="/" onClick={()=>setPatientPop(false)}>
      <p  className= "pop-text-item" onClick={handleLogoutFunction}>
        Log Out
      </p>
    </Link>
  </div>
  <div className="footerLogo">
    <img src={merilLogo} alt="logo" className="footerLogoImg"  style={{height:'70px'}}/>
  </div>
</div>
  }

  </div> : ""}</>;
};
// Customizable Area End

// Customizable Area Start
export default PatientProfilePopup;
// Customizable Area End