import React from "react";
// Customizable Area Start
import PatientAppointmentsPageController from "./PatientAppointmentsPageController.web";
import PatientOutLet from "./ProfileOutlet.web";
import { Col, Row, List, Typography, Avatar } from "antd";
import { calendarimg, clockimg, locationimg } from "./assets";
let config = require("../../../framework/src/config.js").baseURL;
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { PiDownloadSimple } from "react-icons/pi";
import { Box,Paper,styled} from "@material-ui/core";
// Customizable Area End
class PatientAppointmentsPage extends PatientAppointmentsPageController {
  // Customizable Area Start

  renderCalendar = () => {
    return (
      <>
        <span>
          <img
            className="calenderImg"
            src={calendarimg}
          />
        </span>
        
      </>
    )
  };

  renderClock = () => {
    return (
      <>

        <span>
          <img
            className="clock-img"
            src={clockimg}
          />
        </span>
        &nbsp;&nbsp;
      </>
    )
  };

  render() {
    const { Title, Text } = Typography;

    return (
      <div>
        <Dialog
          open={this.state.isViewPrescriptionMOdalOpen}
          maxWidth="lg"
          fullWidth
          onClose={this.handleClosPrescriptionModal}
        >
          <DialogTitle>
            <Box>
              <Typography.Title level={3} >
                Prescription
              </Typography.Title>
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ display: "flex", flexDirection: "column" }} id="alert-dialog-description">

              {
                this.state?.prescriptionDetails && this.state.prescriptionDetails.data.attributes.prescription_medicines.data?.map((item) => (
                  <Box key={item.attributes.id} component={Paper} p={2} mb={1} style={{
                    boxShadow: "0px 2px 13px 0px rgba(88, 87, 87, 0.16)",
                    width: "95%",
                    display: "flex", justifyContent: "space-between", flexWrap: "wrap", gap: "24px"
                  }}  >

                    <Box>
                      <ModalAppointmentValue>{item.attributes.medicine_name} </ModalAppointmentValue>
                      <Box style={{ display: "flex", justifyContent: "flex-start", columnGap: "8px" }}>
                        {
                          item.attributes.dose_time.split("-")?.map((doage: string, index: number) => (
                            <Box key={index} p={1}  >
                              <ModalAppointmentValue>{doage} </ModalAppointmentValue>
                              </Box>
                          ))
                        }
                      </Box>

                    </Box>

                    <Box>
                      <ModalAppointmentLabel>Duration</ModalAppointmentLabel>
                      <ModalAppointmentValue>{item.attributes.duration} </ModalAppointmentValue>
                    </Box>

                    <Box>
                      <ModalAppointmentLabel>Time</ModalAppointmentLabel>
                      <ModalAppointmentValue>{item.attributes.time} </ModalAppointmentValue>
                    </Box>
                  </Box>
                ))
              }
              
              <Box style={{width:"95%"}} >
                <Box mt={2} mb={1} >
                  <ModalAppointmentValue>General Advice/Notes</ModalAppointmentValue>
                </Box>
                    <ul>
                      <li style={{ textTransform: "capitalize" }} >
                        {this.state.prescriptionDetails.data.attributes.add_extra_information.diet_to_follow}
                      </li>
                    </ul>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: "center" }} >
          <GoToHomePageButton data-test-id="cancelButtonId" variant="contained" onClick={this.handleClosPrescriptionModal} color="primary">
              Cancel
            </GoToHomePageButton>
            <GoToHomePageButton data-test-id="downloadPrescriptionButtonID" endIcon={<PiDownloadSimple />} variant="contained" onClick={() => this.handleViewPrescription()} color="primary">
              Download prescription
            </GoToHomePageButton>
          </DialogActions>
        </Dialog>
        <PatientOutLet {...this.props} selectedKey="10">
        <div className="itemContainerWiidths">
            <Row justify={"space-between"}>
              <Row align={"middle"}>
                <Title level={4}  className="appointment">
                 Ongoing Appointment
                </Title>
              </Row>
            </Row>
            {this.state.ongoingAppointment?.data?.length>0 ?
            <div className="previousAppointmentList_container">
            <List
            data-test-id="antd_list_ongoing"
              itemLayout="horizontal"
              dataSource={this.state.ongoingAppointment?.data}
              renderItem={(item) => (
                <List.Item  id="upcomming_appointmentListID" className="list-items-containers"  data-test-id="upcomming_appointmentListID" onClick={()=> this.handleBookedAppointment(item)}  >
                  <List.Item.Meta
                    avatar={
                      <Avatar
                      src={
                        item?.attributes?.doctor_profile_image
                          ? config + item?.attributes.doctor_profile_image
                          : ""
                      }
                        style={{width:'80px' ,height:"80px" , borderRadius:'4px'}}
                        shape="square"
                      />
                    }
                    title={
                      <div>
                        <p style={{ fontWeight: 700  ,marginBottom:0}}>
                         Dr. {item?.attributes?.doctor_full_name} |{" "}
                          {
                          item?.attributes.doctor_age
                          }
                          {
                          item?.attributes.doctor_gender
                          }{" "}
                          <span style={{ color: "3AB896",
                            padding:'0 10px', background: '#F2F9FF',
                            borderRadius:'4px'}}>
                              New
                              </span>
                        </p>
                        <p style={{
                          marginBottom:"5px" ,
                          borderRadius:'4px',
                          marginTop:"12px"
                          }}> 
                          <span >
                            <img
                            className="locationImg"
                              src={locationimg}
                            />
                          </span>
                          &nbsp;&nbsp;&nbsp;
                          {item?.attributes?.mode_type}
                        </p>
                      </div>
                    }
                    className="showDetails"
                  />
                  <div className="listStyle">
                  <div
                    className="list-items-speciliation"
                    style={{ marginTop: "20px" ,borderRadius:'4px', display:'flex', gap:'10px' }}
                  >
                    {
                    this.renderCalendar()
                    }
                    <Typography 
                    className="list-items-text-fields"
                    style={{borderRadius: "4px", background: '#F2F9FF',marginTop:"1px"}}
                    >{
                      item.attributes.appointment_date
                      }
                    </Typography>
                  </div>

                  <div className="clockImg" >
                    <Col>
                       {
                       this.renderClock()
                       }
                      <Text className="list-items-text-fields" 
                      style={{background: '#F2F9FF',marginTop:"1px",borderRadius: "4px"}}>{item?.attributes.time_slot}</Text>
                    </Col>
                  </div>
                  </div>
                </List.Item>
              )}
            />
            </div>
            :<p>No Appointments Found</p>}
          </div>

          <div className="itemContainerWiidths">
            <Row justify={"space-between"}>
              <Row align={"middle"}>
                <Title level={4}  className="appointment">
                 Upcoming Appointments
                </Title>
              </Row>
            </Row>
            {this.state.AppointmentlsDataList?.data?.length>0 || this.state.newAppointmentsData?.length>0 ?
            <div className="previousAppointmentList_container">
            <List
            data-test-id="antd_list"
              itemLayout="horizontal"
              dataSource={this.state.AppointmentlsDataList.data}
              renderItem={(item, index) => (
                <List.Item  id="upcomming_appointmentListID"  style={{ cursor: `${item?.attributes?.status !== 2 && "pointer"}`}} className="list-items-containers"  data-test-id="upcomming_appointmentListID" onClick={()=> this.handleBookedAppointment(item)}  >
                  <List.Item.Meta
                    avatar={
                      <Avatar
                      src={
                        item?.attributes.doctor_profile_image
                          ? config + item?.attributes.doctor_profile_image
                          : ""
                      }
                        style={{width:'80px' ,height:"80px" , borderRadius:'4px'}}
                        shape="square"
                      />
                    }
                    title={
                      <div>
                        <p style={{ fontWeight: 700  ,marginBottom:0}}>
                         Dr. {item?.attributes.doctor_full_name} |{" "}
                          {item?.attributes.doctor_age}
                          {item?.attributes.doctor_gender}{" "}
                          <span style={{ color: `${item?.attributes.status === 2 ? "red":"3AB896"}`,padding:'0 10px', background: '#F2F9FF',borderRadius:'4px'}}>{item?.attributes.status === 2 ? "Cancelled":"New"}</span>
                        </p>

                        <p style={{marginBottom:"5px" ,borderRadius:'4px',marginTop:"12px"}}> 
                          <span >
                            <img
                            className="locationImg"
                              src={locationimg}
                            />
                          </span>
                          &nbsp;&nbsp;&nbsp;{item?.attributes.mode_type}
                        </p>
                      </div>
                    }
                    className="showDetails"
                    
                  />
                  <div className="listStyle">
                  <div
                    className="list-items-speciliation"
                    style={{ marginTop: "20px" ,borderRadius:'4px', display:'flex', gap:'10px' }}
                  >
                    {this.renderCalendar()}
                    <Typography 
                    className="list-items-text-fields"
                    style={{borderRadius: "4px", background: '#F2F9FF',marginTop:"1px"}}
                    >{item.attributes.appointment_date}
                    </Typography>
                  </div>

                  <div className="clockImg" >
                    <Col>
                       {this.renderClock()}
                      <Text className="list-items-text-fields" 
                      style={{background: '#F2F9FF',marginTop:"1px",borderRadius: "4px"}}>{item?.attributes.time_slot}</Text>
                    </Col>
                  </div>
                  </div>
                </List.Item>
              )}
            />
            <List
              data-test-id="cancelList"
              itemLayout="horizontal"
              dataSource={this.state.newAppointmentsData}
              renderItem={(item, index) => (
                <List.Item id="upcomming_appointmentListID" className="list-items-containers" data-test-id="cancelappointmentsid">
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        src={""}
                        style={{ width: '80px', height: "80px", borderRadius: '4px' }}
                        shape="square"
                      />
                    }
                    title={
                      <div>
                        <p style={{ fontWeight: 700, marginBottom: 0 }} >
                          Dr. {item?.attributes.doctor_full_name} |
                          <span style={{ color: "#3AB896" ,padding:'0 10px', background: '#F2F9FF',borderRadius:'4px'}}>{item?.attributes.appointment_type}</span>
                          <span style={{ color: `${item.attributes.status === 2 ? "red" : "3AB896"}`, padding: '0 10px', background: '#F2F9FF', borderRadius: '4px' }}>{item.attributes.status === 2 ? "Cancelled" : "New"}</span>
                        </p>
                        <p style={{ marginBottom: "5px", borderRadius: '4px', marginTop: "12px" }}>
                          <span >
                            <img
                              className="locationImg"
                              src={locationimg}
                            />
                          </span>
                          &nbsp;&nbsp;&nbsp;{item.attributes.consultation_category}
                        </p>
                      </div>
                    }
                    style={{ maxWidth: "48%" }}
                  />
                   <div className="listStyle">
                  <DivContainer
                    style={{ marginTop: "20px", borderRadius: '4px' }}
                    className="list-items-speciliation"
                  >
                    {this.renderCalendar()}
                    <Text className="list-items-text-fields" style={{ marginTop: "1px", borderRadius: "4px", background: '#F2F9FF' }}>{item?.attributes.appointment_date}</Text>
                  </DivContainer>
                  <div className="clockImg" >
                      <span>
                        <img
                          className="clock-img"
                          src={clockimg}
                        />
                      </span>
                      &nbsp;&nbsp;
                      <Text className="list-items-text-fields" style={{ marginTop: "1px", borderRadius: "4px", background: '#F2F9FF' }} data-test-id="timeslotId">{item.attributes.time_slot}</Text>
                  </div>
                  </div>
                </List.Item>
              )}
            />
            </div>
            :
            <p>No Appointments Found</p>}
          </div>
  
          <div className="itemContainerWiidths">
            <Row justify={"space-between"}>
              <Row align={"middle"}>
                <Title level={4}  className="appointment">
                 Previous Appointments
                </Title>
              </Row>
            </Row>
            {this.state.pastAppointmentsData?.length>0 || this.state.previousAppointmetDataList?.data?.length>0 ? 
            <div className="previousAppointmentList_container">
            <List
                data-test-id="cancelListtt"
                itemLayout="horizontal"
                dataSource={this.state.pastAppointmentsData}
                renderItem={(item, index) => (
                  <List.Item id="upcomming_appointmentListID" className="list-items-containers" data-test-id="cancelappointmentsid">
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          src={""}
                          style={{ width: '80px', height: "80px", borderRadius: '4px' }}
                          shape="square"
                        />
                      }
                      title={
                        <div>
                          <p style={{ fontWeight: 700, marginBottom: 0 }}>
                            Dr. {item?.attributes.doctor_full_name} |
                            <span style={{ color: "#3AB896" ,padding:'0 10px', background: '#F2F9FF',borderRadius:'4px'}}>{item?.attributes.appointment_type}</span>
                            <span style={{ color: `${item?.attributes.status === 2 ? "red" : "3AB896"}`, padding: '0 10px', background: '#F2F9FF', borderRadius: '4px' }}>{item?.attributes.status === 2 ? "Cancelled" : "New"}</span>
                          </p>
                          <p style={{ marginBottom: "5px", borderRadius: '4px', marginTop: "12px" }}>
                            <span >
                              <img
                                className="locationImg"
                                src={locationimg}
                              />
                            </span>
                            &nbsp;&nbsp;&nbsp;{item?.attributes.consultation_category}
                          </p>
                        </div>
                      }
                      className="showDetails"
                    />
                  <div className="listStyle">
                    <div
                      className="list-items-speciliation"
                      style={{ marginTop: "20px", borderRadius: '4px' }}
                    >
                      <span>
                        <img
                          className="calenderImg"
                          src={calendarimg}
                        />
                      </span>
                      &nbsp;&nbsp;
                      <Text className="list-items-text-fields" style={{ marginTop: "1px", borderRadius: "4px", background: '#F2F9FF' }}>{item?.attributes.appointment_date}</Text>
                    </div>
                    <div className="clockImg" >
                      <Col>
                        <span>
                          <img
                            className="clock-img"
                            src={clockimg}
                          />
                        </span>
                        &nbsp;&nbsp;
                        <Text className="list-items-text-fields" style={{ marginTop: "1px", borderRadius: "4px", background: '#F2F9FF' }}>{item?.attributes.time_slot}</Text>
                      </Col>
                    </div>
                    </div>
                  </List.Item>
                )}
              />
            <List
              data-test-id="previous_antd_list"
              itemLayout="horizontal"
              dataSource={this.state.previousAppointmetDataList.data}
              renderItem={(item, index) => (
                <List.Item data-test-id="previous_antd_list_item" className="past-list-items-containers">
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        shape="square"
                        src={
                          item?.attributes.doctor_profile_image
                            ? config + item?.attributes.doctor_profile_image
                            : ""
                        }
                        style={{width:'80px' ,height:"80px" , borderRadius:'4px'}}
                      />
                    }
                    title={
                      <div>
                        <p style={{ fontWeight: 700  ,marginBottom:0}}>
                         Dr. {item?.attributes.doctor_full_name} |{" "}
                          {item?.attributes.doctor_age}
                          {item?.attributes.doctor_gender}{" "}
                          <span style={{ color: "#3AB896" ,padding:'0 10px', background: '#F2F9FF',borderRadius:'4px'}}>{item?.attributes.appointment_type}</span>
                        </p>

                        <p style={{marginBottom:"5px" ,borderRadius:'4px',marginTop:"12px"}}> 
                          <span >
                            <img
                              src={locationimg}
                              style={{
                                height: "25px",
                                width: "17px",
                              }}
                            />
                          </span>
                          &nbsp;&nbsp;&nbsp;{item?.attributes.mode_type}
                        </p>
                      </div>
                    }
                    className="showDetails"
                  />
                  <div className="listStyle">
                  <div
                    className="list-items-speciliation"
                  >
                    <span>
                      <img
                        src={calendarimg}
                        style={{ height: "25px", width: "25px", gap: "100px" }}
                      />
                    </span>
                    &nbsp;&nbsp;
                    <Text className="list-items-text-fields" >{item?.attributes.appointment_date}</Text>
                  </div>

                  <div>
                      <div style={{display:"flex",alignItems: "center",rowGap:"8px",paddingBottom:"5px"}} >
                      <span>
                        <img
                         style={{
                                height: "25px",
                                width: "25px",
                                gap: "100px",
                          }}
                          src={clockimg}

                        />
                      </span>
                      &nbsp;
                      <Text className="list-items-text-fields" >{item?.attributes.time_slot}</Text>
                      </div>
                      <p data-test-id="viewPrescriptionID" onClick={()=>this.handlePrescription(item)} className="view-prescription-text">{item?.attributes.is_prescription_present ? "View Prescription":"" }  </p> 
                  </div>
                  </div>
                </List.Item>
              )}
            />
            </div>:
            <p>No Appointments Found</p>}
          </div>
        </PatientOutLet>
      </div>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default PatientAppointmentsPage;


const ModalTtitle=styled(Typography)({
  // fontFamily: "Encode Sans",
  fontSize: "18px",
  fontWeight: 600,
  marginTop:"12px"
  })
  
  
  const ModalAppointmentDetails=styled(Typography)({
    // fontFamily: "Encode Sans",
    fontSize: "18px",
    fontWeight: 600,
    color:"#498ecc"
    })
  
    const ModalAppointmentLabel=styled(Typography)({
      // fontFamily: "Encode Sans",
      fontSize: "16px",
      fontWeight: 400,
      color:"#979595"
      })
  
      const ModalAppointmentValue=styled(Typography)({
        // fontFamily: "Encode Sans",
        fontSize: "16px",
        fontWeight: 600,
        color:"#454545",
        textTransform:"capitalize"
        })
  
      const GoToHomePageButton=styled(Button)({
        height: "40px",
        borderRadius: "30px",
        background: "#498ECC",
        color: "#FFF",
        fontSize: "16px",
        fontWeight: 600,
        textTransform:"capitalize",
        "&:hover":{
          background:"498ECC"
        }
   
      })

const DivContainer = styled("div")({
  marginTop: "20px",
   borderRadius: '4px'
});
// Customizable Area End
