import React from "react";
// Customizable Area Start
import { AiOutlineRight } from "react-icons/ai";
import { merilLogo} from "../../blocks/splashscreen/src/assets";
import { FaUserAlt } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import { Link} from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';
let config = require('../../framework/src/config.js').baseURL;
// Customizable Area End

type DoctorProfilePopupProps = {
  // Customizable Area Start
  profileData: any,
  logoutFunctiuon: any,
  showProfile:any,
  doctorDetailsData: any,
  handleEditProfile: any,
  handleClosePopup: any
  // Customizable Area End
};

const DoctorProfilePopup = ({
  // Customizable Area Start
  profileData,
  logoutFunctiuon,
  showProfile,
  doctorDetailsData,
  handleEditProfile,
  handleClosePopup
  // Customizable Area End
}: DoctorProfilePopupProps) => {
  // Customizable Area Start
  const handleLogoutFunction = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `${config}/account_block/set_online_status?user_type=doctor&online_status=false`,
        {
          method: "PUT",
          headers: {
            token: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      logoutFunctiuon()
    } catch (error) {
      console.error("Error updating online status:", error);
    }
  };
  const renderPopup = () => {
    
    const is_kyc = localStorage.getItem("is_kyc");
    const profile_pic = localStorage.getItem("profile_pic");
    
    
    return (
      <>
      <div className={profileData?"closeModel":"closeModelOff"} onClick={() => { handleClosePopup() }}></div>
      <div className="doctor_detailsPopup_container">
        <div className="horizontalFlex">
          <div className="leftDetailsContainer">
          {profile_pic ?
              <img src={profile_pic.includes("https") ? profile_pic :  config + doctorDetailsData?.data?.attributes?.profile_image} style={{ width: "70px", height: "70px", borderRadius: "50%" }} alt="" />
              :
              <p>
                <FaUserCircle style={{ width: "70px", height: "70px", borderRadius: "50%" }} />
              </p>
            }

            <div className="verticalFlex">
              <p className="doctor-name-text">Dr. {doctorDetailsData?.data.attributes.full_name}</p>
              <p className="edit-profile-text" onClick={handleEditProfile}>Edit Profile</p>
            </div>
          </div>

          <div className="openEditProfile" onClick={() => { showProfile() }}>
            <AiOutlineRight />
          </div>
        </div>
        <div className="callDetailsConatiner">
          <p className="callHeading">Call your medical representative</p>
          <div className="horizontalFlex">
            <div className="leftDetailsContainer">
              <p className="callerProfile">
                <FaUserAlt />
              </p>
              <p className="callerName">{doctorDetailsData?.data.attributes.medical_representative_name}</p>
            </div>
            <div className="CallIcon">
              <BsFillTelephoneFill />
            </div>
          </div>
        </div>
        <div className="navigationFeatures">
          <p>My Performance</p>
          <Link to={is_kyc === "approved" ? "/mypatient" : "/doctorprofiledetails"} style={{color: "#000000d6"}}><p>My Patients</p></Link>
          <Link to={is_kyc === "approved" ? "/myclinics" : "/doctorprofiledetails"} style={{color: "#000000d6"}}><p>My Clinics</p></Link>
          <p>Diagnostics and Pharmacy Management</p>
          <Link to={is_kyc === "approved" ? "/feespackagemanagement" : "/doctorprofiledetails"} style={{color: "#000000d6"}}><p>Fee/Package Management</p></Link>
          <p>Settings</p>
          <Link to="/" onClick={() => logoutFunctiuon()}>
          <p className="logout-text" onClick={() => handleLogoutFunction()}>Log Out</p>
          </Link>
        </div>
        <div className="footerLogo">
          <img src={merilLogo} alt="logo" className="footerLogoImg"/>
        </div>
      </div>
      </>
    );
  }
  // Customizable Area End

  // Customizable Area Start
  return (
    <>
      {profileData ? renderPopup() : ""}
    </>
  )
}
// Customizable Area End

// Customizable Area Start
export default DoctorProfilePopup;
// Customizable Area End
