import React from "react";
// Customizable Area Start
import { Link } from "react-router-dom";
import { Col, Row, Rate, Checkbox } from "antd";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import FooterCommonComponent from "../../../components/src/FooterCommonComponent.web";
import FooterCardComponent from "./component/FooterCardComponent";
import HospitalListController from "./HospitalListController.web";

let config = require("../../../framework/src/config.js").baseURL;

// Customizable Area End

class HospitalListPage extends HospitalListController {
  // Customizable Area Start
  renderNavigationTitle = () => {
    return (
      <>
        <div className="navigationContainer">
          <Link to="/patienthome">Home</Link>
          <Link to="/patient-appointment">Appointments</Link>

          <Link to="/services">Services</Link>
          <Link to="/patientmessage">Messages</Link>
        </div>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  render() {
    return (
      <div>
        <NavbarCommonComponent handleProfile={undefined} />
        {this.renderNavigationTitle()}
        {/* <div className='navigation-list-css-container' style={{ paddingLeft: '5%' }}>  <Text className='navigation-list-css' style={{ color: "#498ECC" }}>Home</Text>   <RightOutlined style={{ color: "#498ECC" }} translate={undefined} /> <Text style={{ color: "#498ECC" }} className='navigation-list-css'>Service</Text> <RightOutlined style={{ color: "#498ECC" }} translate={undefined} /> <Text style={{ color: "#498ECC" }} className='navigation-list-css'>Heart</Text> <RightOutlined style={{ color: "#498ECC" }} translate={undefined} /> <Text type='secondary' className='navigation-list-css'>Angioplasty</Text> <RightOutlined style={{ color: "#498ECC" }} translate={undefined} /> <Text type='secondary' className='navigation-list-css'>Package Details  <RightOutlined style={{ color: "#498ECC" }} translate={undefined} /> <Text type='secondary' className='navigation-list-css'>Choose Hospital</Text></Text> </div> */}

        <div style={{ width: "80%", margin: "0 auto" }}>
          <div className="ourServicesContainer">
            <Row className="hospitalsRow" gutter={[20, 20]}>
              <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                {" "}
                <h5 style={{ textAlign: "start" }}>Choose Hospital</h5>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                {" "}
                <h5 style={{ textAlign: "end" }}>
                  Ahmedabad{" "}
                  <span style={{ marginLeft: "12px" }}>
                    {/* <EnvironmentOutlined translate="" height={32} width={32} /> */}
                  </span>
                </h5>
              </Col>
              {this.state.HospitalsDataList.map((item, index) => {
                return (
                  <Col
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={24}
                    key={index + 234}
                  >
                    <div
                      className="topHospitalsCard"
                      style={{ height: "auto" }}
                    >
                      <div className="hospiotalImgBox">
                        <img
                          src={config + item.attributes.hospital_image[0]?.url}
                          className="hospitalImg"
                          style={{ height: "153px" }}
                        />
                      </div>
                      <div className="hospitalTextBox">
                        <div className="hospitalNameBox">
                          <p className="hospitalName">
                            {item.attributes.name}Hospital
                          </p>
                        </div>
                        <p className="hospitalDistance">
                          {item.attributes.address}kms away
                        </p>
                        <div className="rateBox hospitalRate">
                          <div>
                            <span className="ratingCount">3</span>
                            <Rate allowHalf value={3} />
                          </div>
                          <p className="reviewText">
                            Selected&nbsp;<Checkbox></Checkbox>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
          <div>
            <FooterCardComponent />
          </div>
        </div>
        <FooterCommonComponent />
      </div>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default HospitalListPage;
export { HospitalListPage };
// Customizable Area End
