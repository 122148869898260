import React from "react";

// Customizable Area Start
import { Box, Typography,Paper,styled} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import { PiDownloadSimple } from "react-icons/pi";
// Customizable Area End

import VideosController, { Props, configJSON } from "./VideosController";

export default class Videos extends VideosController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div>
      <Dialog
        open={this.state.isModalOpen}
        maxWidth="xs"
        fullWidth
      >
          <DialogTitle style={{display:"flex", justifyContent:"center"}} >
            <Box>
              <Typography style={{fontWeight:"bold"}} variant="h5" >
                Thanks for Consulting
              </Typography>
            </Box>
          </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box pb={2} justifyContent="center" >
            </Box>
          
            <Box component={Paper} p={3} style={{
              boxShadow: "0px 2px 13px 0px rgba(88, 87, 87, 0.16)"
            }}  >
              <ModalAppointmentDetails>Your Appointment Details</ModalAppointmentDetails>

              <Box py={2} style={{display:"flex",flexDirection:"column",rowGap:"8px"}} >
              <ModalAppointmentLabel>Mode of Consultation</ModalAppointmentLabel>
              <ModalAppointmentValue>{this.state.consultation_mode}</ModalAppointmentValue>

              <ModalAppointmentLabel>Address</ModalAppointmentLabel>
              <ModalAppointmentValue>Sector 55-56 Gurgaon, Haryana</ModalAppointmentValue>

              <ModalAppointmentLabel>Date & Time</ModalAppointmentLabel>
              <ModalAppointmentValue>{this.state.date} </ModalAppointmentValue>

              <ModalAppointmentLabel>Meet</ModalAppointmentLabel>
              <ModalAppointmentValue>Dr. {this.state.doc_name}</ModalAppointmentValue>
              </Box>
              
            </Box>
          </DialogContentText>
        </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: "center" }} >
            <GoToHomePageButton data-test-id="goToAppointmentButtonID" variant="contained" onClick={()=>this.handleNavigation()} color="primary" autoFocus>
              Go to appointments
            </GoToHomePageButton>
          </DialogActions>
      </Dialog>

      <Dialog
        open={this.state.isViewPrescriptionMOdalOpen}
        maxWidth="xs"
        fullWidth
      >
          <DialogTitle>
            <Box>
              <Typography variant="h4">
                Prescription
              </Typography>
            </Box>
          </DialogTitle>
        <DialogContent>
          <DialogContentText style={{display:"flex", flexDirection:"column"}} id="alert-dialog-description">

          {
            new Array(4).fill(1).map((item:any,index)=>(
              <Box key={index} component={Paper} p={2} mb={2} style={{
                boxShadow: "0px 2px 13px 0px rgba(88, 87, 87, 0.16)",
                width:"90%"
              }}  >
              
                <Box style={{display:"flex",flexDirection:"column",rowGap:"12px"}} >
                <ModalAppointmentValue>Medicine name</ModalAppointmentValue>
                <Box style={{ display:"flex",justifyContent:"flex-start",columnGap:"8px"}}>
                {
                  new Array(3).fill(3).map((doage,index)=>(
                    <Box key={index} p={2} style={{background:"#f2f2f2",borderRadius:"8px"}} >{doage} </Box>
                  ))
                }
  
                </Box>
  
                    <Box mb={3} style={{display:"flex",justifyContent:"space-between",rowGap:"10px"}} >
                      <Box>
                        <ModalAppointmentLabel>Duration</ModalAppointmentLabel>
                        <ModalAppointmentValue>10 Days</ModalAppointmentValue>
                      </Box>
                      <Box>
                        <ModalAppointmentLabel>Time</ModalAppointmentLabel>
                        <ModalAppointmentValue>After lunch</ModalAppointmentValue>
                      </Box>
                    </Box>
                  </Box>
                  <ModalAppointmentLabel>No comment of doctor</ModalAppointmentLabel>
              </Box>
            ))
          }
          </DialogContentText>
        </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: "center" }} >
            <GoToHomePageButton data-test-id="downloadPrescriptionButtonID" endIcon={<PiDownloadSimple />} variant="contained" onClick={()=>this.handleViewPrescription()} color="primary" autoFocus>
              Download prescription
            </GoToHomePageButton>
          </DialogActions>
      </Dialog>

       <div data-test-id="videoID"  style={{ width: '100vw', height: '100vh', overflowX:"hidden" }} id="videoID" ref={(ref)=> this.myMeetings = ref} />
      </div>
     // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
};

const ModalTtitle=styled(Typography)({
// fontFamily: "Encode Sans",
fontSize: "18px",
fontWeight: 600,
marginTop:"12px"
})


const ModalAppointmentDetails=styled(Typography)({
  // fontFamily: "Encode Sans",
  fontSize: "18px",
  fontWeight: 600,
  color:"#498ecc"
  })

  const ModalAppointmentLabel=styled(Typography)({
    // fontFamily: "Encode Sans",
    fontSize: "16px",
    fontWeight: 400,
    color:"#979595"
    })

    const ModalAppointmentValue=styled(Typography)({
      // fontFamily: "Encode Sans",
      fontSize: "16px",
      fontWeight: 600,
      color:"#454545"
      })

    const GoToHomePageButton=styled(Button)({
      width: "268px",
      height: "40px",
      borderRadius: "30px",
      background: "#498ECC",
      color: "#FFF",
      fontSize: "16px",
      fontWeight: 600,
      textTransform:"capitalize",
      "&:hover":{
        background:"498ECC"
      }
 
    })
// Customizable Area End
