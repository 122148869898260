import React from "react";
// Customizable Area Start
import TermsAndConditionsContrller, { Props } from "./TermsAndConditionsContrller.web";
import {
    Box,
    styled
} from "@material-ui/core";
import Loader from "../../../components/src/Loader.web";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import FooterDocotr from "../../../components/src/FooterDoctor.web";
import FooterCommonComponent from '../../../components/src/FooterCommonComponent.web';
import DoctorProfilePopup from "../../../components/src/DoctorProfilePopup.web";
import { Link } from 'react-router-dom';
import PatientProfilePopup from "../../../components/src/PatientProfilePopup.web";
// Customizable Area End

export default class TermsAndCondtions extends TermsAndConditionsContrller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderHeader = () => {
        return (
            <>
                <Box>
                    <TermsText>
                        Terms & Conditions
                    </TermsText>
                </Box>
            </>
        )
    }

    renderTextTerms = () => {
        return (
            <Box>
                {
                    this.state.termsConditionData.settings.map((item) => {
                        return (
                            <>
                                <p style={{fontWeight: 600 , paddingTop: "10px"}}>
                                    {item.name}
                                </p>
                                <TextContent dangerouslySetInnerHTML={{
                                    __html: item.description
                                }} style={{backgroundColor: "transparent !important"}}></TextContent>
                            </>
                        ) 
                    })
                   }
                   <CheckboxContainer>
                   <input data-test-id="checkboxId" type="checkbox" id="checkbox" className="checkboxClass" onChange={this.handleCheckbox}/>&nbsp;
                   <span>I have read & agree to all the Terms & Conditions.</span>
                   </CheckboxContainer>
                   <button data-test-id="continueID" className={this.state.checkboxCheck? "submitBotton" : "disableButton"} disabled={!this.state.checkboxCheck}>Confirm & Continue</button>
            </Box>
        )
    }

    renderEditProfileNavigatePage = () => {
        let kyc_approved = this.state.isKyc;
        return (
                    <NavigateBox>
                        <Link to={kyc_approved === "approved" ? "/doctorhome" : "/editdoctordetails"}><NavigateTitle>Home</NavigateTitle></Link>
                        <Link to={kyc_approved === "approved" ? "/doctorschedule" : "/editdoctordetails"}><NavigateTitle>Schedule</NavigateTitle></Link>
                        <Link to={kyc_approved === "approved" ? "/addpatient" : "/editdoctordetails"}><NavigateTitle>Add Patient</NavigateTitle></Link>
                        <NavigateTitle>Chats</NavigateTitle>
                    </NavigateBox>
        )
    }

    renderPatientTerms = () => {
        return (
            <NavigateBox>
                    <Link to="/patienthome"><NavigateTitle>Home</NavigateTitle></Link>
                    <Link to="/patient-appointment-landingpage/"><NavigateTitle>Appointments</NavigateTitle></Link>
                    <Link to="/Services"><NavigateTitle>Services</NavigateTitle></Link>
                    <Link to="/patientmessage"><NavigateTitle>Messages</NavigateTitle></Link>
            </NavigateBox>
        )
    }

    renderNavigationTitle = () => {
        return (
            <NavigateBox onClick={this.handleVerifyKyc} data-test-id="clickId">
            <NavigateTitle>Home</NavigateTitle>
            <NavigateTitle>Schedule</NavigateTitle>
            <NavigateTitle>Add Patient</NavigateTitle>
            <NavigateTitle>Chats</NavigateTitle>
            </NavigateBox>
        )
    }
    renderNavigationScreen = () => {
            switch (true) {
                case this.state.isKyc === "approved":
                  return this.renderEditProfileNavigatePage();
                case this.state.isKyc === "pending":
                  return this.renderNavigationTitle();
                default:
                  return this.renderPatientTerms();
              }
    }

    renderNoData = () => {
        return (
            <NoDataBox>
                <p>No data found</p>
            </NoDataBox>
        )
    };
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <NavbarCommonComponent handleProfile={this.handelShowDoctoPopUp} data-test-id="doctorProfilePopupId" />
                {
                    this.state.doctorName || this.state.isLogin ?
                        <DoctorProfilePopup profileData={this.state.showPopup} logoutFunctiuon={this.handleDoctorLogout} showProfile={this.handleDocDetailsPage} doctorDetailsData={this.state.doctorProfileData} handleEditProfile={this.handleEditDocProfile} handleClosePopup={this.handleShowPopupClose} data-test-id="doctorProfileId" />
                        :
                        <PatientProfilePopup profileData={this.state.showPopup} logoutFunctiuon={this.handleDoctorLogout} />
                }
                {
                    localStorage.getItem("token") && this.renderNavigationScreen()
                }
                <ContainerBox>
                   {this.state.description ? this.renderTextTerms() : this.renderNoData()}
                </ContainerBox>
                {
                    localStorage.getItem("token") ? (<FooterDocotr/>):    <FooterCommonComponent />
                }
                <Loader loading={this.state.isLoader} height={this.state.contentHeight} />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const ContainerBox = styled(Box)({
    width: "89%",
    margin: "0 auto",
    "& .submitBotton":{
        color: "#fff",
        padding: "15px",
        width: "250px",
        borderRadius: "50px",
        backgroundColor: "#3a79c1",
        fontSize: "15px",
        fontWeight: 500,
        border: "none",
        display: "block",
        margin: "20px auto"
        },
        "& .disableButton":{
            color: "white",
            padding: "15px",
            width: "250px",
            borderRadius: "50px",
            backgroundColor: "grey",
            fontSize: "15px",
            fontWeight: 500,
            border: "none",
            display: "none",
            margin: "20px auto"
        }
});

const TermsText = styled("span")({
  marginLeft: "10px",
  marginBottom: "10px",
  fontWeight: 600
});

const CheckboxContainer = styled("div")({
    display : "none"
});

const TextContent = styled("p")({
  "& span":{
  backgroundColor: "transparent !important"
  }
});

const NavigateBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexFlow: "wrap",
    width: "89%",
    margin: "0 auto"
});

const NavigateTitle = styled("p")({
    color: "black",
    fontSize: "16px",
    fontWeight: 600
});

const NoDataBox = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "200px",
    boxShadow: "1px 1px 5px #aaaaaa",
    marginBottom: "25px",
    marginTop: "20px"
});
// Customizable Area End