import React from "react";
// Customizable Area Start 
import {
  Modal,
  Container,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Typography,
  Input,
  styled,
  Grid
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import ContactusController, { Props } from "./ContactusController";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import FooterCommonComponent from '../../../components/src/FooterCommonComponent.web';
import FooterDocotr from "../../../components/src/FooterDoctor.web";
import DoctorProfilePopup from "../../../components/src/DoctorProfilePopup.web";
import PatientProfilePopup from "../../../components/src/PatientProfilePopup.web";
import { Link } from 'react-router-dom';
// Customizable Area End
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start 
    let kyc_approved = this.state.isKyc;
    // Customizable Area End 
    return (
        // Customizable Area Start
        <>   
        <NavbarCommonComponent handleProfile={this.handelShowDoctoPopUp} data-test-id="doctorProfilePopupId" />
        {
            this.state.isLogin || this.state.doctorName ?
            <DoctorProfilePopup profileData={this.state.showPopup} logoutFunctiuon={this.handleDoctorLogout} showProfile={this.handleDocDetailsPage} doctorDetailsData={this.state.doctorProfileData} handleEditProfile={this.handleEditDocProfile} handleClosePopup={this.handleShowPopupClose} data-test-id="doctorProfileId" />
              :
          <PatientProfilePopup data-test-id="handleLogoutId" profileData={this.state.showPopup} logoutFunctiuon={this.handleDoctorLogout} />
        }
          {
            this.state.isKyc === "approved" &&
            <NavigateBox>
              <Link to={kyc_approved === "approved" ? "/doctorhome" : "/editdoctordetails"}><NavigateTitle>Home</NavigateTitle></Link>
              <Link to={kyc_approved === "approved" ? "/doctorschedule" : "/editdoctordetails"}><NavigateTitle>Schedule</NavigateTitle></Link>
              <Link to={kyc_approved === "approved" ? "/addpatient" : "/editdoctordetails"}><NavigateTitle>Add Patient</NavigateTitle></Link>
              <NavigateTitle>Chats</NavigateTitle>
            </NavigateBox>
          }
          {
            this.state.isKyc === "pending" &&
            <NavigateBox onClick={this.handleVerifyKyc} data-test-id="clickId">
            <NavigateTitle>Home</NavigateTitle>
            <NavigateTitle>Schedule</NavigateTitle>
            <NavigateTitle>Add Patient</NavigateTitle>
            <NavigateTitle>Chats</NavigateTitle>
            </NavigateBox>
          }
          {
            (this.state.healthId || !this.state.healthId === null) && 
            <NavigateBox>
              <Link to="/patienthome"><NavigateTitle>Home</NavigateTitle></Link>
              <Link to="/patient-appointment-landingpage/"><NavigateTitle>Appointments</NavigateTitle></Link>
              <Link to="/Services"><NavigateTitle>Services</NavigateTitle></Link>
              <Link to="/patientmessage"><NavigateTitle>Messages</NavigateTitle></Link>
            </NavigateBox>
          }
        <ContainerBox>
        <h5 style={{fontWeight: 600 ,fontSize: "22px"}}>
          Contact Us
        </h5>
        <New container spacing={4}>
          <Grid item sm={12} xs={12}>
            <CustomInputLable>Full Name<sup style={{ color: "red" }}>*</sup></CustomInputLable>
            <CustomInput onChange={this.handleName} value={this.state.patientFullName} data-test-id="nameId" placeholder='John lee' />
            {this.state.patientNameErr && <ErrorTag>{this.state.patientNameErr}</ErrorTag>}
          </Grid>
          <Grid item sm={12} xs={12}>
            <CustomInputLable>Email<sup style={{ color: "red" }}>*</sup></CustomInputLable>
            <CustomInput onChange={this.handleEmail} value={this.state.patientEmail} data-test-id="patientEmailId" placeholder="abc@gmail.com" />
            {this.state.patientEmailErr && <ErrorTag>{this.state.patientEmailErr}</ErrorTag>}
          </Grid>
          <Grid item sm={12} xs={12}>
            <CustomInputLable>Conatct Number<sup style={{ color: "red" }}>*</sup></CustomInputLable>
            <CustomInput onChange={this.handlePhone} value={this.state.patientPhoneNum} data-test-id="patientNumId" placeholder="0000000000" />
            {this.state.patientPhoneNumErr && <ErrorTag>{this.state.patientPhoneNumErr}</ErrorTag>}
          </Grid>
          <Grid item sm={12} xs={12}>
            <CustomInputLable>Description<sup style={{ color: "red" }}>*</sup></CustomInputLable>
            <CustomArea onChange={this.handleDescription} value={this.state.description} data-test-id="textAreaId" placeholder="Type your issue here" ></CustomArea>
            {this.state.descriptionErr && <ErrorTag>{this.state.descriptionErr}</ErrorTag>}
          </Grid>
        </New>
        <CustomButton data-test-id="submitButton" onClick={this.postContactUsApiFunction}>
          Submit
        </CustomButton>
      </ContainerBox>
      {
        localStorage.getItem("token") ? (<FooterDocotr/>): <FooterCommonComponent />
      }
      </>
      // Customizable Area End 
    );
  }
}

// Customizable Area Start

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const New = styled(Grid)({
  "& .selectDisease": {
      border: "1px solid #808080",
      marginTop: "8px",
      "&.ant-select:not(.ant-select-disabled):hover .ant-select-selector": {
          borderColor: "#eee6e6 !important",
          outline: "none",
          borderWidth:"1px"
      },
  },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#808080 !important",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#5AA4F4 !important",
      borderWidth:"1px"
  },
  "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#808080"
  }
});

const CustomInputLable = styled("label")({
  fontSize: "14px",
  fontWeight: 400,
  width: "100%"
});

const CustomInput = styled("input")({
  width: "60%",
  padding: "10px",
  border: "1px solid #808080",
  outline: "none",
  borderRadius: "5px",
  marginTop: "5px",
  boxSizing: "border-box",
  "&:focus":{
      borderColor:"#5AA4F4",
      borderWidth:"1px"
  },
  "@media(max-width:700px)":{
    width:"100%"
  }
});

const NavigateBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexFlow: "wrap",
  width: "89%",
  margin: "0 auto"
});

const NavigateTitle = styled("p")({
  color: "black",
  fontSize: "16px",
  fontWeight: 600
});

const CustomArea = styled("textarea")({
  outline: "none",
  borderRadius: "5px",
  marginTop: "5px",
  width: "60%",
  padding: "10px",
  border: "1px solid #808080",
  boxSizing: "border-box",
  resize: "none",
  "&:focus":{
      borderColor:"#5AA4F4",
      borderWidth:"1px"
  },
  "@media (max-width:700px)":{
    width:"100%"
  }

});

const ErrorTag = styled("p")({
  color: "red",
  fontSize: "14px",
  margin: "0 !important"
});

const CustomButton = styled("button")({
  width: "300px",
  display: "block",
  padding: "10px",
  color: "#fff",
  backgroundColor: "#3b76bf",
  borderRadius: "35px",
  margin: "20px 0px",
  border: "none",
  cursor: "pointer",
  "@media (max-width:400px)": {
      width: "200px"
  }
});

const ContainerBox = styled(Box)({
  width: "89%",
  margin: "0 auto"
});
// Customizable Area End
