Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.timeout = 2000

exports.getOurservicesAPiEndPoint = "account_block/list_all_services";
exports.getOurservicesAPiMethod = "GET";
exports.getOurservicesApiContentType = "application/json";

exports.getTopDoctorsAPiEndPoint = "account_block/search_doctor_top_rating";
exports.getTopDoctorsAPiMethod = "GET";
exports.getTopDoctorsApiContentType = "application/json";

exports.getTrendingTherapiesAPiEndPoint = "bx_block_categories/trending_therapies";
exports.getTrendingTherapiesAPiMethod = "GET";
exports.getTrendingTherapiesApiContentType = "application/json";

exports.getLabTestAPiEndPoint = "bx_block_categories/list_labs";
exports.getLabTestAPiMethod = "GET";
exports.getLabTestApiContentType = "application/json";

exports.getRadioLogyTestAPiEndPoint = "bx_block_categories/list_radiologies";

exports.getTopHospitalsAPiEndPoint = "bx_block_hospital/top_hospitals_by_city";

exports.getShortVideosListAPiEndPoint = "bx_block_posts/list_short_videos";

exports.getTopCardiovascularAPiEndPoint = "bx_block_categories/top_cardiovascular_procedures";

exports.getTopOrthopaedicAPiEndPoint = "bx_block_categories/top_orthopaedics_procedures";

exports.getPostCovidpackageAPiEndPoint = "bx_block_custom_ads/advertisement_type";
exports.putMethods= "PUT";
exports.putOnlineStatusEndPoints = "account_block/set_online_status"
exports.postCreatePatientAPiEndPoint = "account_block/patient_create";
exports.postCreatePatientAPiMethod = "POST";
exports.postCreatePatientApiContentType = "application/json";

exports.postUpdatePatientAPiEndPoint = "account_block/update_profile?";
exports.postUpdatePatientAPiMethod = "POST";
exports.postUpdatePatientApiContentType = "application/json";

exports.getGeneralHealthDoctorsApiEndPoint="bx_block_categories/categories_index?identifier=health_category&category_type=general_health"
exports.getAdvancedHealthDoctorsApiEndPoint="bx_block_categories/categories_index?identifier=health_category&category_type=advance_health";
exports.getSearchDoctorApiEndPoint="account_block/search_doctor";
exports.getDoctorDetailsApiEndPoint="account_block/show";
exports.getPatientDetails="account_block/current_patient_detail"
exports.getApiMethod="GET";
exports.postApiMethod="POST"
exports.getApiMethodContentType="application/json";
exports.getTrendingTherapiesDetailsApiEndPoint="bx_block_categories/show_trending_therapies"
// Customizable Area End