import React from "react";
// Customizable Area Start
import { Link } from "react-router-dom";
import { Col, Row, Button, Avatar } from "antd";
import { RightOutlined, pain, stroke } from "./assets";
import { Carousel } from "react-responsive-carousel";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import FooterCommonComponent from "../../../components/src/FooterCommonComponent.web";
import Text from "antd/lib/typography/Text";
import FooterCardComponent from "./component/FooterCardComponent";
import TherapiesDetailsController from "./TherapiesDetailsController.web";
import PatientProfilePopup from "../../../components/src/PatientProfilePopup.web";
let config = require("../../../framework/src/config.js").baseURL;

// Customizable Area End

class TherapiesDetailspage extends TherapiesDetailsController {
  // Customizable Area Start
  renderNavigationTitle = () => {
    return (
      <>
        <div className="navigationContainer">
          <Link to="/patienthome">Home</Link>
          <Link to="/patient-appointment">Appointments</Link>
          <Link to="/services" className="active" >Services</Link>
          <Link to="/patientmessage">Messages</Link>
        </div>
      </>
    );
  };
  // Customizable Area End

  // Customizable Area Start

  renderTreatmentDetails = () => {
    return (
      <>
        <div className="ourServicesContainer">
          <div className="ourServicesHeaderPart topmargin">
            <h2 className="ourServicesTitle">
              {this.state.therapiesDetails.attributes.therapy_name}
            </h2>
          </div>
          <>
            <Carousel
              interval={3000}
              showThumbs={false}
              showArrows={false}
              showStatus={false}
              infiniteLoop={true}
              autoPlay={true}
              stopOnHover={false}
              className="secondCarousel"
            >
              {this.state.therapiesDetails.attributes.therapy_images &&
                this.state.therapiesDetails.attributes.therapy_images.map(
                  (item) => (
                    <div key={item?.url}>
                      <img
                        className="carouselImage addCarouselImg"
                        src={config + item.url}
                      />
                    </div>
                  )
                )}
            </Carousel>
          </>
        </div>
        <div style={{ margin: "3rem 0rem 4rem" }}>
          <Button type="primary" shape="round" data-test-id="completePackage">
            Get Complete Package
          </Button>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "40px" }}>
          <div className="discription-of-desiece">
            <h2 className="angio-plastic-symptom-heading"> Description</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: this.state.therapiesDetails.attributes.description,
              }}
            ></p>
          </div>
          <div>
            <h2 className="angio-plastic-symptom-heading">Symptoms</h2>
            <Row gutter={[16, 24]} style={{ width: "25%" }}>
              {this.state.therapiesDetails.attributes.symptoms_name &&
                this.state.therapiesDetails.attributes.symptoms_name.map(
                  (item, index) => (
                    <Col span={12} key={index + 54}>
                      <Avatar
                        shape="circle"
                        size="small"
                        src={item.name === "Cough" ? pain : stroke}
                      />
                      <Text>{item.name}</Text>
                    </Col>
                  )
                )}
            </Row>
          </div>

          <div>
            <h2 className="angio-plastic-symptom-heading">Dignosis</h2>
            <Row>
              <ul className="angio-plastic-symptom-list">
                {this.state.therapiesDetails.attributes.diagnosis_name &&
                  this.state.therapiesDetails.attributes.diagnosis_name.map(
                    (item, index) => <li key={index + 453}>{item.name}</li>
                  )}
              </ul>
            </Row>
          </div>
          <div>
            <h2 className="angio-plastic-symptom-heading">Treatment</h2>
            <ul className="angio-plastic-symptom-list">
              <li>{this.state.therapiesDetails.attributes.treatment_id}</li>
            </ul>
          </div>
        </div>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  render() {
    const { navigationList } = this.state;

    return (
      <div>
         <NavbarCommonComponent handleProfile={this.showDetailsHandler} />
        <PatientProfilePopup data-test-id="handleLogoutId" profileData={this.state.showPopup} logoutFunctiuon={this.handleLogout} />
        {this.renderNavigationTitle()}
        <div
          className="navigation-list-css-container"
          style={{ paddingLeft: "5%" }}
        >
          <Text
            className="navigation-list-css"
            style={{ color: "#498ECC" }}
            onClick={() => this.props.history.push("/patienthome")}
            data-test-id="home-navigate-id"
          >
            Home
          </Text>
          <img src={RightOutlined} alt="" style={{width:'10px'}}/>
          <Text
            style={{ color: "#498ECC" }}
            className="navigation-list-css"
            data-test-id="sevices-navigate-id"
            onClick={() => this.props.history.push("/services")}
          >
            Service
          </Text>
          <img src={RightOutlined} alt="" style={{width:'10px'}}/>

          {navigationList &&
            navigationList.map((item: any, index: number) => (
              <>
                {index === navigationList.length - 1 ? (
                  <>
                    <Text
                      style={{ color: "#353535" }}
                      className="navigation-list-css"
                    >
                      {item.name}
                    </Text>
                  </>
                ) : (
                  <>
                    <Text
                      style={{ color: "#498ECC" }}
                      className="navigation-list-css"
                      onClick={() =>
                        this.props.history.push({
                          pathname: item.route,
                          state: {
                            navigateList: navigationList[0]?.name,
                            ids: { ...this.props.location?.state?.ids },
                          },
                        })
                      }
                    >
                      {item.name}
                    </Text>
                    <img src={RightOutlined} alt="" style={{width:'10px'}}/>
                  </>
                )}
              </>
            ))}
        </div>

        <div className="itemContainerWiidth">
          {this.renderTreatmentDetails()}

          <div>
            <FooterCardComponent />
          </div>
        </div>
        <FooterCommonComponent />
      </div>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default TherapiesDetailspage;
export { TherapiesDetailspage };
// Customizable Area End
