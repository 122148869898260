import React from "react";
// Customizable Area Start
import { Link } from "react-router-dom";
import { Button, Card, Col, Row, Divider, Typography, Modal } from "antd";
import FooterCommonComponent from "../../../components/src/FooterCommonComponent.web";
import MedicineCartPageController from "./MedicineCartPageController.web";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import { Plussymbol, discount, minsesymbol } from "./assets";
import PatientProfilePopup from "../../../components/src/PatientProfilePopup.web";
import Loader from "../../../components/src/Loader";
let config = require("../../../framework/src/config.js").baseURL;

// Customizable Area End
class MedicineCartPage extends MedicineCartPageController {
  // Customizable Area Start
  renderNavigationTitle = () => {
    return (
      <>
        <div className="navigationContainer">
          <Link to="/patienthome">Home</Link>
          <Link to="/patientappointment">Appointments</Link>

          <Link to="/services">Services</Link>
          <Link to="/patientmessage">Messages</Link>
        </div>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  renderCartItem = () => {
    const { CartDetails } = this.state;
    return (
      <>
        <div style={{ display: "flex", flexDirection: "column", gap: "40px" }}>
          <Loader loading={this.state.loading} />

          <h3 className="cart-text-heading">Cart</h3>
          {CartDetails?.cart_products?.data.map((item) => {
            return (
              <Card className="card-details-css">
                <Row>
                  <Col flex="80%">
                    <Typography.Title style={webStyle.cartItemHeading}>
                      {item.attributes.product_detail.medicine_name}{" "}
                      {item.attributes.product_detail.medicine_quantity}
                    </Typography.Title>
                    <Typography.Text style={webStyle.strip}>
                      {item.attributes.product_detail.medicine_quantity}
                    </Typography.Text>
                    <br />
                    <Typography.Text style={webStyle.strip}>
                      Manufacturer
                    </Typography.Text>
                    <Typography.Text style={webStyle.strip}>
                      {item.attributes.product_detail.manufacturer_name}
                    </Typography.Text>
                    <br />
                  </Col>
                  <Col flex="20%">
                    <Typography.Title style={webStyle.cartItemHeading}>
                      {item.attributes.product_detail.total_amount}
                    </Typography.Title>
                    <Typography.Text style={webStyle.strip2} delete>
                      {item.attributes.product_detail.discount_price}
                    </Typography.Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "5px",
                        marginTop: "30px",
                      }}
                    >
                      <Button
                        type="text"
                        shape="circle"
                        size="small"
                        icon={
                          <img src={minsesymbol} alt="" style={{height:"16px", width:"16px"}} />
                        }
                        onClick={() => this.updateCartData(item, -1)}
                        data-test-id="decrementId"
                      />

                      <Typography.Text>
                        {item.attributes.product_count}
                      </Typography.Text>
                      <Button
                        type="primary"
                        shape="circle"
                        size="small"
                        onClick={() => this.updateCartData(item, 1)}
                        icon={
                          <img src={Plussymbol} alt="" style={{height:"22px", width:"22px"}} />
                        }
                        data-test-id="incrementId"
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            );
          })}
        </div>
      </>
    );
  };
  // Customizable Area En
  // Customizable Area Start
  render() {
    return (
      <div>
        <NavbarCommonComponent handleProfile={this.showDetailsHandler} />
        <PatientProfilePopup
          data-test-id="handleLogoutId"
          profileData={this.state.showPopup}
          logoutFunctiuon={this.handleLogout}
        />
        {this.renderNavigationTitle()}
        <div className="mainWrapper-medicine">
          {this.renderCartItem()}

          <div className="side-card-css">
            <div>
              <Button
                className="btn-css-coupon"
                onClick={this.handleOpen}
                icon={
                  <img
                    style={{ width: "30px", height: "30px", color: "#498ECC" }}
                    src={discount}
                  />
                }
              >
                Apply Coupon
              </Button>
              <Modal
                visible={this.state.isModalOpen}
                onCancel={this.handleCancel}
                style={{ padding: "1%" }}
                centered
                width={600}
                closeIcon={
                  <span style={{ color: "#333", fontSize: "20px" }}>
                    &times;
                  </span>
                }
              >
                {this.state.CuponDetails.map((item: any) => (
                  <Card style={{ marginTop: "5px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <p>{item.attributes.name}</p>
                        <p>{item.attributes.discount} Rs</p>
                      </div>
                      <div>
                        <Button
                          type="primary"
                          onClick={() =>
                            this.getCartDetailApply(item.attributes.name)
                          }
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                  </Card>
                ))}
              </Modal>
            </div>

            <Card style={webStyle.cardWrapper}>
              <Row justify="space-between">
                <p className="card-text">Items Total (MRP)</p>
                <p className="card-text">
                  {this.state.CartDetails?.total_product_cost.total_cost}
                </p>
              </Row>
              <Row justify="space-between">
                <p className="card-text">Price Discount</p>
                <p className="card-text">
                  -Rs {this.state.CartDetails?.price_discount}
                </p>
              </Row>
              <Divider style={{ padding: 0 }} />
              <Row justify="space-between">
                <p className="card-text">Estimated Shipping Fee</p>
                <p className="card-text">
                  Rs {this.state.CartDetails?.shipping_charge.shipping_charge}
                </p>
                <p style={{ color: "#498ECC", fontSize: "14px" }}>
                  Free delivery on orders above Rs 499
                </p>
              </Row>
              <Divider />

              <Row justify="space-between">
                <p>To be paid</p>
                <p style={{ color: "#666", fontSize: "14px" }}>
                  Rs&nbsp;{this.state.CartDetails?.total_cart_cost.total_cart}
                </p>
              </Row>
              <Row justify="space-between">
                <p style={{ color: "#2AE867", fontSize: "14px" }}>
                  Total Saving Rs 4
                </p>
              </Row>
              <Row justify="space-between">
                <p>Total Payable</p>
                <Button type="primary" shape="round">
                  CHECK OUT
                </Button>
              </Row>
              <Row justify="space-between">
                <p className="Rs-text">
                  Rs&nbsp;{" "}
                  {this.state.CartDetails?.total_product_cost.total_cost}
                </p>
              </Row>
            </Card>
          </div>
        </div>
        <FooterCommonComponent />
      </div>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default MedicineCartPage;
export { MedicineCartPage };
// Customizable Area Start
const webStyle = {
  cartItem: {
    padding: "0px",
    borderRadius: "8px",
    background: "#FFF",
    boxShadow: "0px 2px 13px 0px rgba(88, 87, 87, 0.16)",
    marginTop: "20px",
  },
  cartItemHeading: {
    color: "#353535",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
  cardWrapper: {
    width: 358,
    padding: "0px",
    borderRadius: "8px",
    background: "#FFF",
    boxShadow: "0px 2px 13px 0px rgba(88, 87, 87, 0.16)",
    marginTop: "20px",
  },
  strip: {
    display: "block",
    color: "#353535",
    fontFamily: "Encode Sans",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
  strip2: {
    color: "#353535",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  },
};
// Customizable Area End
