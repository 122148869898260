import React from "react";
// Customizable Area Start
import TermsAndConditionsContrller, { Props } from "./TermsAndConditionsContrller.web";
import {
    Box,
    styled
} from "@material-ui/core";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import FooterDocotr from "../../../components/src/FooterDoctor.web";
import FooterCommonComponent from '../../../components/src/FooterCommonComponent.web';
import DoctorProfilePopup from "../../../components/src/DoctorProfilePopup.web";
import PatientProfilePopup from "../../../components/src/PatientProfilePopup.web";
import { Link } from 'react-router-dom';
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

export default class PrivacyPolicy extends TermsAndConditionsContrller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderPrivacyHeader = () => {
        return (
            <>
                <Box>
                    <Privacy>
                        Privacy and Policy
                    </Privacy>
                </Box>
            </>
        )
    }

    renderPrivacyTerms = () => {
        return (
            <Box>
                {
                    this.state.privacyPolicyData.settings.map((item) => {
                        return (
                            <>
                                <p style={{fontWeight: 600 , paddingTop: "10px"}}>
                                    {item.name}
                                </p>
                                <p dangerouslySetInnerHTML={{
                                    __html: item.description
                                }}></p>
                            </>
                        ) 
                    })
                   }
                   <CheckboxContainer>
                   <input data-test-id="checkboxId" type="checkbox" id="checkbox" className="checkboxClass" onChange={this.handleCheckbox}/>&nbsp;
                   <span>I have read & agree to all the Privacy Policy.</span>
                   </CheckboxContainer>
                   <button data-test-id="continueID" className={this.state.checkboxCheck?"submitBotton":"disableButton"} disabled={!this.state.checkboxCheck}>Confirm & Continue</button>
            </Box>
        )
    }

    renderScreen = () => {
        switch (true) {
            case this.state.isKyc === "approved":
                return this.renderKycTitle();
            case this.state.isKyc === "pending":
                return this.renderDocNavigate();
            default:
                return this.renderNavigationHomeTitle();
        }
    }

    renderNavigationHomeTitle = () => {
        return (
            <EditPageHeader>
                <Link to="/patienthome"><PageHeader>Home</PageHeader></Link>
                <Link to="/patient-appointment-landingpage/"><PageHeader>Appointments</PageHeader></Link>
                <Link to="/Services"><PageHeader>Services</PageHeader></Link>
                <Link to="/patientmessage"><PageHeader>Messages</PageHeader></Link>
            </EditPageHeader>
        )
    }

    renderKycTitle = () => {
        const is_kyc = this.state.isKyc;
        return (
            <EditPageHeader>
                <Link to={is_kyc === "approved" ? "/doctorhome" : "/editdoctordetails"}><PageHeader>Home</PageHeader></Link>
                <Link to={is_kyc === "approved" ? "/doctorschedule" : "/editdoctordetails"}><PageHeader>Schedule</PageHeader></Link>
                <Link to={is_kyc === "approved" ? "/addpatient" : "/editdoctordetails"}><PageHeader>Add Patient</PageHeader></Link>
                <PageHeader>Chats</PageHeader>
            </EditPageHeader>
        )
    }

    renderDocNavigate = () => {
        return (
            <EditPageHeader onClick={this.handleVerifyKyc} data-test-id="clickId">
                <PageHeader>Home</PageHeader>
                <PageHeader>Schedule</PageHeader>
                <PageHeader>Add Patient</PageHeader>
                <PageHeader>Chats</PageHeader>
            </EditPageHeader>
        )
    }

    renderNoData = () => {
        return (
            <NoDataBox>
                <p>No data found</p>
            </NoDataBox>
        )
    };
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <NavbarCommonComponent handleProfile={this.handelShowDoctoPopUp} data-test-id="doctorProfilePopupId" />
                {
                    this.state.isLogin || this.state.doctorName ?
                        <DoctorProfilePopup profileData={this.state.showPopup} logoutFunctiuon={this.handleDoctorLogout} showProfile={this.handleDocDetailsPage} doctorDetailsData={this.state.doctorProfileData} handleEditProfile={this.handleEditDocProfile} handleClosePopup={this.handleShowPopupClose} data-test-id="doctorProfileId" />
                        :
                        <PatientProfilePopup data-test-id="handleLogoutId" profileData={this.state.showPopup} logoutFunctiuon={this.handleDoctorLogout} />
                }
                {
                    localStorage.getItem("token") && (this.renderScreen())
                }
                <ContainerBox>
                   {this.state.descriptionPrivacy ? this.renderPrivacyTerms() : this.renderNoData()}
                </ContainerBox>
                {
                    localStorage.getItem("token") ? (<FooterDocotr/>):    <FooterCommonComponent />
                }
                <Loader loading={this.state.isLoader} height={this.state.contentHeight} />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const ContainerBox = styled(Box)({ 
    margin: "0 auto",
    width: "89%",
    "& .submitBotton":{
        color: "#fff",
        padding: "15px",
        width: "250px",
        borderRadius: "50px",
        backgroundColor: "#3a79c1",
        fontSize: "15px",
        fontWeight: 500,
        border: "none",
        display: "block",
        margin: "20px auto"
        },
        "& .disableButton":{
            color: "white",
            padding: "15px",
            width: "250px",
            borderRadius: "50px",
            backgroundColor: "grey",
            fontSize: "15px",
            fontWeight: 500,
            border: "none",
            display: "none",
            margin: "20px auto"
        }
});

const Privacy = styled("span")({
  marginLeft: "10px",
  marginBottom: "10px",
  fontWeight: 600
});

const EditPageHeader = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexFlow: "wrap",
    width: "89%",
    margin: "0 auto"
});

const PageHeader = styled("p")({
    color: "black",
    fontSize: "16px",
    fontWeight: 600
});

const CheckboxContainer = styled("div")({
    display : "none"
});

const NoDataBox = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "200px",
    boxShadow: "1px 1px 5px #aaaaaa",
    marginBottom: "25px",
    marginTop: "20px"
});
// Customizable Area End
