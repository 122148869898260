Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "scheduling";
exports.labelBodyText = "scheduling Body";
exports.cancelAppointmentEndPoint = "bx_block_appointment_management/doctor_appointment"
exports.btnExampleTitle = "CLICK ME";
exports.getApiMethodType = "GET";
exports.applicationJsonApiContentType = "application/json";
exports.serviceProviderAPiEndPoint = "scheduling/service_provider_schedulings/get_sp_details";

exports.getSymptomsEndpoint = "bx_block_categories/get_symptoms_list";
exports.getSymptomsApiMethodType = "GET";
exports.getSymptomsApiContentType = "application/json";

exports.getAppointmentsByDateEndpoint = "bx_block_appointment_management/show_appointment_by_date";

exports.getTimeSlotEndpoint = "bx_block_appointment_management/time_slot";

exports.getDoctorDetailsApiEndPoint = "account_block/show";

exports.postAddNewScheduleEndpoint = "bx_block_appointment_management/create";
exports.postAddNewScheduleApiMethodType = "POST";

exports.getHealthIdEndpoint = "account_block/search_patient_by_id_or_name";

// Customizable Area End