import React from "react";
// Customizable Area Start
import {Typography,Row,Col,Button,Divider} from "antd"
import Radio from '@material-ui/core/Radio';
import Box from '@material-ui/core/Box';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Link } from 'react-router-dom';
import NavBarCommonComponent from "../../../components/src/NavbarCommonComponent.web"
import { CertificationComponent } from "../../../components/src/CertificationComponent.web";
import {gPay,phonePay,bhimUPI,cashOnDelivery,bank} from "./assets"
import MerilLoader from '../../../components/src/MerilLoader.web';
let config = require('../../../framework/src/config.js').baseURL;
import FooterCommonComponent from "../../../components/src/FooterDoctor.web";

// Customizable Area End

import PatientAppointmentPaymentController, {
  Props
} from "./PatientAppointmentPaymentController.web";


 class PatientAppointmentPayment extends PatientAppointmentPaymentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderNavigationHeaderTitlee = () => {
    let isAppointmentActiveOnCheckout =
    window.location.pathname === "/patient-appointment-payment";
    let appointmentClassName = isAppointmentActiveOnCheckout ? "active" : "";
    return (
        <>
            <div className="navigationContainer">
                <Link to="/patienthome" >Home</Link>
                <Link className={appointmentClassName} to="/patient-appointment" >Appointment</Link>
                <Link to="/services" >Services</Link>
                <Link to="/patientmessage" >Message</Link>
            </div>
        </>
    )
    }

     appointmentDetails=[{
      name:"Mr. Yash Patel",
      date:"08 Oct, 2022, Thursday",
      time:"10:00 Am to 10:30 AM",
      Type_of_Consultation:"Online - Video Consultation"
    }]


  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
     <>
     <NavBarCommonComponent handleProfile={undefined} data-test-id="headerId"/>

     {this.renderNavigationHeaderTitlee()}

     <div className="doctorList" ref={this.myRef} >
        <div>
            <Row gutter={[48,24]} >
                <Col xs={24} >
                       <Typography className="payment-wallets-title" >

                        </Typography> 
                </Col>


                <Col xs={24} sm={12}  >
                    <div className="payment_method__container" >
                    
                    <RadioGroup
                    style={{padding:"24px",paddingTop:0}}
                       aria-label="position" 
                       name="position"
                      defaultValue="start">
                        <Typography className="payment-wallets-title">
                         Add Wallets
                        </Typography>
        <FormControlLabel
          value="gPay"
          control={<Radio color="primary" />}
          label={<Box style={{width:"300px"}}> <img className="payment-icon" src={gPay} alt="Gpay" /> G Pay</Box>}
          labelPlacement="start"
        />
        <Divider/>
        <FormControlLabel
          value="bhimUPI"
          control={<Radio color="primary" />}
          label= {<Box style={{width:"300px"}}> <img className="payment-icon" src={bhimUPI} alt="Gpay" />Bhim Pay</Box>}
          labelPlacement="start"
        />
        <Divider/>
        <FormControlLabel
          value="phonePay"
          control={<Radio color="primary" />}
          label={<Box style={{width:"300px"}}> <img className="payment-icon" src={phonePay} alt="Gpay" />Phone Pay</Box>}
          labelPlacement="start"
        />
        <Divider/>

          <Typography className="payment-wallets-title">
                              Net Banking
          </Typography>
          <FormControlLabel
          value="netBanking"
          control={<Radio color="primary" />}
          label={<Box style={{width:"300px"}}> <img className="payment-icon" src={bank} alt="Gpay" />Choose Bank</Box>}
          labelPlacement="start"
        />

<Divider/>

<Typography className="payment-wallets-title">
  Pay on Delivery
</Typography>
<FormControlLabel
value="payOnDelivery"
control={<Radio color="primary" />}
label={<Box style={{width:"300px"}}> <img className="payment-icon" src={cashOnDelivery} alt="Gpay" />Cash on Delivery</Box>}
labelPlacement="start"
/>
       
      </RadioGroup>





                      {/* <Radio.Group style={{width:"100%"}} size="large" value={this.state.selectedPaymentMethod} onChange={(selectedMethod)=>this.handlePaymentMethod(selectedMethod)}  >
                      <Space direction="vertical" style={{width:"100%"}} >

                            <Radio value={1} className="radio-button-label"  >
                             <img className="payment-icon" src={gPay} alt="Gpay" />   G Pay
                            </Radio>
                            <Divider/>

                            <Radio value="ghimUPI" className="radio-button-label"   >
                            <img className="bhim-payment-icon" src={bhimUPI} />  Bhim Pay
                            </Radio>
                            <Divider/>

                            <Radio value="phonePay" className="radio-button-label"   >
                            <img className="payment-icon" src={phonePay} />  Phone Pay
                            </Radio>
                            <Divider/>

                            <Typography className="payment-wallets-title">
                              Net Banking
                            </Typography>


                            <Radio value="choose_bank" className="radio-button-label"   >
                            <img src={bank} className="payment-icon" alt="" />  Choose Bank
                            </Radio>
                            <Divider/>

                            <Typography  className="payment-wallets-title">
                              Pay on Delivery
                            </Typography>
                            <br/>

                            <Radio value="cashOnDelivery" className="radio-button-label"   >
                            <img className="payment-icon" src={cashOnDelivery} alt="cashonDelivery" />  Cash on Delivery
                            </Radio>
                          <Divider/>
                            
                        
                         </Space>

                      </Radio.Group> */}

                    </div>
                </Col>

                <Col xs={24} sm={8} >

                    <div className="payment-details-wrapper" >
                     <Typography className="payment-details-label" data-test-id="paymentTextId">Payment Details</Typography>
                     <div className="payment-details-text-container" >
                      <Typography className="payment-details-consultation-fees" >Consultation fees</Typography>
                      <Typography className="payment-details-consultation-fees-amount" >Rs 500/ -</Typography>
                     </div>

                     <div className="payment-details-text-container">
                      <Typography className="payment-details-total-amount" >Total Amount</Typography>
                      <Typography className="payment-details-total-amount-fees">Rs 500/ -</Typography>
                     </div>

                     <div className="payment-details-text-container">
                      <Typography className="payment-details-faqa" >
                         Having trouble with the booking? Checkout  <span className="payment-details-FAQs-text" >
                         FAQs 
                         </span>
                      </Typography>
                    
                     </div>

                     <div className="payment-details-text-container">
                      <Link to="patient-appointment-dashboard" >
                      <Button className="payment-details-checkout-button" >
                      Make Payment
                      </Button>
                      </Link>

                     </div>

                     <div>

                     </div>

                     
                    </div>
                </Col>
            </Row>

        </div>

     <CertificationComponent/>
     </div>
      <FooterCommonComponent />

     </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default MerilLoader(PatientAppointmentPayment);
export { PatientAppointmentPayment };
// Customizable Area End
