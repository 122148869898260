import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import ApiCall from "../../../components/src/ApiCall.web";
import { RouteComponentProps } from "react-router-dom";

export interface Appointment {
  id: number;
  type: string;
  attributes: AppointmentAttributes;
}

interface AppointmentAttributes {
  doctor_id: number;
  patient_id: number;
  health_id: string;
  patient_phone_number: string;
  symtoms: string | null;
  time_slot: string;
  end_time: string;
  consultation_category: string;
  pick_time: string;
  consultation_type: string;
  fees: number;
  mode_type: string;
  doctor_full_name: string;
  doctor_phone_number: string;
  created_by: string;
  status: number;
  last_visit: string;
  razorpay_order_id: string;
  payment_status: string;
  promo_code: string;
  original_price: string;
  discount_price: string;
  payment_method: string | null;
  patient_age: string | null;
  patient_full_name: string;
  patient_gender: string;
  patient_address: PatientAddress;
  patient_weight: string;
  patient_blood_group: string;
  patient_year: string;
  patient_profile_image: string;
  doctor_category: string;
  doctor_specialization: string;
  doctor_experience: string;
  doctor_gender: string | null;
  doctor_age: string | null;
  appointment_date: string;
  appointment_created_time: string;
  appointment_type: string;
  doctor_profile_image: string;
  payment: string | null;
  type: string;
  is_prescription_present: boolean;
}

interface PatientAddress {
  id: number;
  full_name: string;
  building_name: string;
  area: string;
  landmark: string;
  pincode: string;
  city: string;
  state: string;
  patient_id: number;
  created_at: string;
  updated_at: string;
}

interface AppointmentsData {
  data: Appointment[];
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends RouteComponentProps {
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
upCominAppointment : AppointmentsData;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class DoctorUpcomingAppointmentController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getDoctorUpcomingListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      // Customizable Area Start
      upCominAppointment :{data:[]}
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
   // Customizable Area Start
  async componentDidMount() {
  this.getUpcomingAppointmentsFunction();
}
// Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      
      if (this.isAppointmentInValidResponse(responseJson)) {
        this.appointmentApiFailureCallBacks(apiRequestCallId, responseJson);
      }
      else if (this.isAppointmentValidResponse(responseJson)) {
        this.appointmentApiSuccessCallBacks(apiRequestCallId, responseJson);
      }  else if (errorReponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  isAppointmentValidResponse = (responseJson: any) => {
    return responseJson ;
  };
  // Customizable Area End

  // Customizable Area Start
  isAppointmentInValidResponse = (responseJson: any) => {
    return responseJson && responseJson.errors;
  };
  // Customizable Area End

  // Customizable Area Start
  appointmentApiSuccessCallBacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getDoctorUpcomingListApiCallId) {
      this.doctorUpcomingAppointmentsSuccessCallBack(responseJson);
    }
  };
  // Customizable Area End

  // Customizable Area Start
  appointmentApiFailureCallBacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getDoctorUpcomingListApiCallId) {
      this.doctorUpcomingAppointmentsFailureCallBack(responseJson);
    }
    
  };
  // Customizable Area End

  getUpcomingAppointmentsFunction = async () => {
    this.getDoctorUpcomingListApiCallId = ApiCall({
      contentType: configJSON.getDoctorUpcomingAppointmentApiContentType,
      method: configJSON.getDoctorUpcomingAppointmentAPiMethod,
      endPoint: configJSON.getDoctorUpcomingAppointmentAPiEndPoint,
    });
  };

  doctorUpcomingAppointmentsSuccessCallBack = (res: any) => {
    this.setState({
      upCominAppointment: res
    })
    
  };

  doctorUpcomingAppointmentsFailureCallBack = async (err: any) => {
   if (err.errors[0].message == "Not found any appointment.") {
    notification["error"]({
      message: "Not found any appointment." 
    });
  }
  };

  handleAppointmentsDetails = (id: number) => {
    this.props.history.push({
      pathname: "/appointmentsdetails",
      state: {
        appointmentId: id
      }
    })
  };
  // Customizable Area End
}
// Customizable Area End
