import React from "react";

// Customizable Area Start
import {
  Modal,
  Container,
  Box,
  Button,
  Typography,
  Input,
  styled,
  Tab,
  Tabs,
  TextField,
  Divider,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import { PiChatsLight , PiDotsThreeOutlineVerticalDuotone} from "react-icons/pi";
import { FiPhone ,FiPhoneCall} from "react-icons/fi";
import { IoVideocamOutline } from "react-icons/io5";
import { IoMdDownload } from "react-icons/io";
import { GoDeviceCameraVideo } from "react-icons/go";
import { FaFileLines } from "react-icons/fa6";
import { LuSend, LuLaugh } from "react-icons/lu";
import FooterDoctor from "../../../components/src/FooterDoctor.web";
import { GrAttachment } from "react-icons/gr";
import Loader from "../../../components/src/Loader.web";
import { Link as Redirect} from 'react-router-dom';
import DoctorProfilePopup from "../../../components/src/DoctorProfilePopup.web";
let configBaseURL = require('../../../framework/src/config.js').baseURL;

interface MessageData {
  _id: number;
  createdAt: number;
  file?: string;
  read: boolean;
  sender: string;
  text?: string;
  type?: string;
}
// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderChatNoData = () => {
    return (
      <NoDataBox>
        <p>No Chat found</p>
      </NoDataBox>
    )
  };

  renderLeftBox = () => {
    return (
      <LeftBox className={this.state.isChatMessage ? "noDataClass2" : "noDataClass"}>
        {
          this.state.patientList?.data?.map((patient) => {
            return (
              <ChatBoxInner>
                  <StyledUserImg src={patient.attributes.profile_photo.length > 0 ? configBaseURL + patient.attributes.profile_photo : "https://i.imgur.com/IUw5yWb.png"} onClick={() => this.handleNavigate(patient.attributes.patient_id)} data-test-id="navigateId"/>
                <UserBox onClick={() => this.validateAndGoToChat(patient)} data-test-id="goToChatId">
                  <LeftuserBox>
                    <NameBoxChat>
                      <UserNameTypography>
                        {patient.attributes.full_name} <CallIdSpanChat data-test-id="patientId">#{patient.attributes.patient_id}</CallIdSpanChat>
                      </UserNameTypography>
                    </NameBoxChat>
                  </LeftuserBox>
                  <RightUserBox className={this.state.chatBox ? "" : "visible"}>
                    <Typography>12:00 PM</Typography>
                    <MessageCountBox>3</MessageCountBox>
                  </RightUserBox>
                </UserBox>
              </ChatBoxInner>
            )
          })
        }
      </LeftBox>
    )
  };

  renderRightBox = () => {
    return (
      <>
        {this.state.chatBox &&
          <RightBox>
            <ChatDetailContainer>
              <ChatContainerHeader>
                <UserBox>
                  <LeftuserBox>
                    <StyledUserImg
                      src={this.state.patientChatDetails?.avatar}
                    />
                    <NameBox>
                      <UserNameTypography>{this.state.patientChatDetails?.full_name}</UserNameTypography>
                      <DoctorStatusTypoGraphy data-test-id="onlineId">
                      {this.state.showStatus ? 
                                <><DoctorStatusindicator></DoctorStatusindicator>Online</>: <><DoctorStatusindicator style={{background:"grey"}}></DoctorStatusindicator>Offline</>}
                      </DoctorStatusTypoGraphy>
                    </NameBox>
                  </LeftuserBox>
                  <IconsBox>
                    <StyledPhoneInTalkOutlinedIcon />
                    <StyledVideocamOutlinedIcon />
                    <StyledMoreVertOutlinedIcon />
                  </IconsBox>
                </UserBox>
              </ChatContainerHeader>
              <Divider />
              {this.renderRightChat()}
            </ChatDetailContainer>
            <TextFieldBox>
              <StyledTextField
                data-test-id="messageId"
                placeholder="Write a Message..."
                variant="standard"
                fullWidth
                value={this.state.messageText}
                onKeyDown={this.handlekeyDown}
                onChange={this.messageTextChange}
                InputProps={
                  {
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                        >
                          <LuLaugh />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                        >
                          <input
                            data-test-id="imgUploadId"
                            type="file"
                            name="image-upload"
                            id="input"
                            multiple
                            style={{ display: "none" }}
                            onChange={this.handleFileChange}
                          />
                          <label htmlFor='input' >
                            <Attachment />
                          </label>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
              />
              <SendIconBox onClick={() => this.onSend()} data-test-id="sendButtonId">
                <StyledSendOutlinedIcon />
              </SendIconBox>
            </TextFieldBox>
          </RightBox>}
      </>
    )
  };

  renderChatBox = () => {
    return (
      <HeaderChat>
        {this.state.isData ? 
        this.renderChatNoData() 
        :  
        <>  
        {this.renderLeftBox()}
        {this.renderRightBox()}
        </>
        }
      </HeaderChat>
    )
  };

  renderCallBox = () => {
    return (
      <Box>
        <Box>
          <CallUserBox>
            <CallLeftUserBox>
              <CallStyledUserImg src="https://images.unsplash.com/photo-1479936343636-73cdc5aae0c3?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
              <CallNameBox>
                <CallUserNameTypography>
                  John Lee <CallIdSpan>#0001</CallIdSpan>
                </CallUserNameTypography>
                <Typography>Outgoing Call</Typography>
              </CallNameBox>
            </CallLeftUserBox>
            <CallRightUserBox>
              <CallTypographyGreen>Outgoing Call</CallTypographyGreen>
              <CallDurationTypography>12 mins 44 secs</CallDurationTypography>
            </CallRightUserBox>
          </CallUserBox>
          <Divider />
        </Box>
      </Box>
    )
  };

  renderVcallBox = () => {
    return (
      <Box>
        <Box>
          <VcallUserBox>
            <LeftUserBox>
              <VcallStyledUserImg src="https://images.unsplash.com/photo-1479936343636-73cdc5aae0c3?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
              <VcallNameBox>
                <VcallUserNameTypography>
                  John Lee <CallIdSpan>#2323</CallIdSpan>
                </VcallUserNameTypography>
                <Typography>09:30 PM</Typography>
              </VcallNameBox>
            </LeftUserBox>
            <VcallRightUserBox>
              <VcallTypographyGreen>Incoming Video Call</VcallTypographyGreen>
              <VcallCallDurationTypography>17 mins 23 secs</VcallCallDurationTypography>
            </VcallRightUserBox>
          </VcallUserBox>
          <Divider />
        </Box>
      </Box>
    )
  };

  renderHeader = () => {
    return (
      <>
        <NavigateBox>
          <Redirect to={"/doctorhome"}><NavigateTitle>Home</NavigateTitle></Redirect>
          <Redirect to={"/doctorschedule"}><NavigateTitle>Schedule</NavigateTitle></Redirect>
          <Redirect to={"/addpatient"}><NavigateTitle>Add Patient</NavigateTitle></Redirect>
          <Redirect to={"/chat"}><NavigateTitleActive>Chats</NavigateTitleActive></Redirect>
        </NavigateBox>
       </>
    )
  };

  renderRightChat = () => {
    return (
      <ChatsBox>
        {
          this.state.messages.map((message) => {
            return (
              <>
                {
                  message.sender === this.state.doctorChatDetails.username ?
                    this.renderMessageBox(message)
                    :
                    this.renderFileMessageBox(message)
                }
              </>
            )
          })
        }
      </ChatsBox>
    )
  };

  renderFileMessageBox = (message : MessageData) => {
    return (
      <FileMessageBox key={message._id}>
      <PaitentProfileBox>
        <ProfileImage src={this.state.patientChatDetails.avatar} />
      </PaitentProfileBox>
      {
        message.file ?
          this.renderFileMessage(message)
          :
          <TimeBox>
            <span style={{ fontSize: "14px", wordWrap: "break-word", overflowWrap: "break-word" }}>{message.text}</span>
          </TimeBox>
      }
      <MessageTime style={{ fontSize: "10px" }}>{this.messageTimeFormate(message.createdAt)}</MessageTime>
    </FileMessageBox>
    )
  };

  renderFileMessage = (message: MessageData) => {
    return (
      <>
        {
          message.type === "images" ?
            <PaitentFileMessage>
              <img height={"100%"} width={"100%"} src={message.file} />
              <IoMdDownload className="downLoadIcon" onClick={() => this.handleDownload(JSON.stringify(message.file))}
                data-test-id={"downloadButtonId"} />
            </PaitentFileMessage> :
            <ReceivePaitentMediaBox>
              <FaFileLines className="pdfIcon" />
              <DownLoad className="downLoadIcon" onClick={() => this.handleDownload(JSON.stringify(message.file))} data-test-id={"downloadButtonId"} />
            </ReceivePaitentMediaBox>
        }
      </>
    )
  };

  renderMessageBox = (message: MessageData) => {
    return (
      <MessageBox key={message._id}>
        <ProfileBox>
          <ProfileImage src={this.state.doctorChatDetails.avatar} />
        </ProfileBox>
        {
          message.file ?
            this.renderReceived(message)
            :
            <DoctMessage>
              <span style={{ fontSize: "14px" , wordWrap: "break-word", overflowWrap: "break-word" }}>{message.text}</span>
            </DoctMessage>
        }
        <MessageTime>{this.messageTimeFormate(message.createdAt)}</MessageTime>
      </MessageBox>
    )
  };

  renderReceived = (message: MessageData) => {
    return (
      <>
      {
          message.type === "images" ?
          <FileMessage>
            <img height={"100%"} width={"100%"} src={message.file} />
            <IoMdDownload className="downLoadIcon" onClick={() => this.handleDownload(JSON.stringify(message.file))}
              data-test-id={"downloadButtonId"} />
          </FileMessage> :
          <ReceiveMediaBox>
            <FaFileLines className="pdfIcon" />
            <DownLoad className="downLoadIcon" onClick={() => this.handleDownload(JSON.stringify(message.file))} data-test-id={"downloadButtonId"} />
          </ReceiveMediaBox>
      }
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <HeaderBox>
        <NavbarCommonComponent handleProfile={this.showDetailsHandler} data-test-id="navbarId" />
        {this.renderHeader()}
        </HeaderBox>
        <DoctorProfilePopup data-test-id="handleProfileId" profileData={this.state.showPopup} logoutFunctiuon={this.handleDoctorLogout} showProfile={this.handleDoctorDetailsPage} doctorDetailsData={this.state.doctorDetails} handleEditProfile={this.handleEditDoctorProfile} handleClosePopup={this.handlePopupClose} />
        <ContainerBox>
        <ChatTitle data-test-id="chatTextId">Chats</ChatTitle>
          <StyledTabs value={this.state.value} onChange={this.handletabItemChange} data-test-id="tabId">
            <StyledTab icon={<ChatIcon />} />
            <StyledTab icon={<PhoneIcon />} />
            <StyledTab icon={<VideoIcon />} />
          </StyledTabs>
          <ChatBox>
            {this.state.value === 0 && this.renderChatBox()}
            {this.state.value === 1 && this.renderCallBox()}
            {this.state.value === 2 && this.renderVcallBox()}
          </ChatBox>
        </ContainerBox>
        <FooterDoctor />
        <Loader loading={this.state.isLoader} height={this.state.contentHeight} />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const HeaderBox = styled(Box)({
  boxShadow: "0px 8px 8px -6px rgba(211, 211, 211, 0.5)"
});

const ChatBoxInner = styled(Box)({
  display: "flex",
  boxShadow: "0px 8px 8px -6px rgba(211, 211, 211, 0.5)",
  paddingBottom: "10px"
});
 
const ReceiveMediaBox = styled(Box)({
  display: "flex",
  margin: "10px 0 0 50px",
  alignItems: "baseline",
  gap: "5px",
  "& .pdfIcon": {
    fontSize: "50px",
    color: "#247EBF"
  },
  "& .downLoadIcon" : {
    fontSize: "24px",
    cursor: "pointer"
  }
});

const ReceivePaitentMediaBox = styled(Box)({
  display: "flex",
  marginTop: "10px",
  alignItems: "baseline",
  gap: "5px",
  "& .pdfIcon": {
    fontSize: "50px",
    color: "#247EBF"
  },
  "& .downLoadIcon" : {
    fontSize: "24px",
    cursor: "pointer"
  }
});

const DownLoad = styled(IoMdDownload)({
  fontSize: "24px",
  cursor: "pointer"
});

const Attachment = styled(GrAttachment) ({
  cursor: "pointer",
  fontSize: "24px"
});

const NoDataBox = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "300px",
  boxShadow: "1px 1px 5px #aaaaaa",
  marginBottom: "20px",
  marginTop:"20px"
});

const ContainerBox = styled(Box)({
  width: "89%",
  margin: "0 auto",
  paddingTop: "15px"
});

const ChatBox = styled(Box)({
  marginBottom: "70px"
});

const StyledTabs = styled(Tabs)({
  backgroundColor: "#f3f9fe",
  display: "flex",
  marginBottom: "12px",
  width:"39%",
  borderRadius: "30px",
  "& .MuiTab-root":{
    minWidth:"33%"
  },
  "& .MuiTabs-fixed":{
    overflowX:"scroll"
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
  "& .Mui-selected": {
    background: "#5989bd",
    color: "white",
    borderRadius: "30px",
  },
  "@media screen and (min-width:600px) and (max-width:1020px)":{
    width:"70%",
  },
  "@media screen and (max-width:599px)":{
    width:"100%",
  }
});

const NavigateBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexFlow: "wrap",
  width: "89%",
  margin: "0 auto",
  paddingBottom: "15px"
});

const StyledTab = styled(Tab)({
  color: "#5989bd",
  "&.Mui-selected": { color: "white" }
});

const ChatIcon = styled(PiChatsLight)({
  fontSize: "25px !important"
});

const PhoneIcon = styled(FiPhone)({
  fontSize: "25px !important"
});

const VideoIcon = styled(IoVideocamOutline)({
  fontSize: "25px !important"
});

const NavigateTitleActive = styled("p")({
  color: "rgb(90,140,196)",
  fontSize: "16px",
  fontWeight: 600
});

const HeaderChat = styled(Box)({
  display: "flex",
  boxShadow: "0px 8px 8px -6px rgba(211, 211, 211, 0.5)",
  paddingBottom: "10px",
  gap: "1%",
  "& .noDataClass" : {
    width: "100% !important",

  },
  "& .noDataClass2" : {
    width: "39%",
  }
});

const NavigateTitle = styled("p")({
  color: "black",
  fontSize: "16px",
  fontWeight: 600
});

const ChatTitle = styled("p")({
  color: "black",
  fontSize: "24px",
  fontWeight: 600
});

const LeftBox = styled(Box)({
  padding: 10,
  display: "flex",
  flexDirection: "column",
  gap: 10,
  "@media screen and (max-width:1020px)":{
    width:"100%"
  },
  height: "430px",
  overflowX: "scroll"
});

const LeftuserBox = styled(Box)({ display: "flex", gap: 20 });

const StyledUserImg = styled("img")({
  borderRadius: "50%",
  height: "60px",
  width:"60px",
  cursor: "pointer"
});

const UserBox = styled(Box)({ display: "flex", gap: 10, justifyContent: "space-between",
  "& .visible": {
    display: "block !important"
  },
  cursor: "pointer",
  width: "100%",
  marginLeft: "5px"
});

const NameBox = styled(Box)({ display: "flex", flexDirection: "column", justifyContent: "center",cursor: "pointer" });

const NameBoxChat = styled(Box)({ display: "flex", flexDirection: "column", justifyContent: "center",cursor: "pointer",
  "& .lastMsg": {
    "@media(max-width:750px)": {
      display: "none"
    }
  }
});

const CallIdSpan = styled("span")({
  fontWeight: 700,
  color: "#5989bd"
});

const CallIdSpanChat = styled("span")({
  fontWeight: 700,
  color: "#5989bd",
  "@media(max-width:750px)":{
    display:"block"
  }
});

const UserNameTypography = styled(Typography)({
  fontWeight: "bold",
});

const RightUserBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  "@media(max-width:750px)":{
    display:"none"
  }
});

const MessageCountBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  width: "25px",
  height: "25px",
  background: "#5989bd",
  color: "white",
  alignSelf: "center",
  padding: 5,
});

const RightBox = styled(Box)({
  width: "60%",
  marginBlock: "-55px",
  display: "flex",
  flexDirection: "column",
  gap: "15px",
  "@media screen and (max-width:1020px)":{
    marginBlock: "0px",
    width:"100%"
  }
});

const ChatDetailContainer = styled(Box)({
  background: "#f4f4f4",
  padding: "30px",
  borderRadius: 10,
  height: "400px",
  display: "flex",
  flexDirection: "column",
  "@media screen and (max-width:599px)":{
    padding:"10px"
  }
});

const ChatContainerHeader = styled(Box)({
  marginBottom: "20px",
});

const DoctorStatusindicator = styled("span")({
  display: "inline-block",
  background: "green",
  height: "10px",
  width: "10px",
  borderRadius: "50%",
  marginRight: "10px",
});

const DoctorStatusTypoGraphy = styled(Typography)({
  fontSize: "14px",
});

const IconsBox = styled(Box)({
  display: "flex",
  gap: "15%",
  color: "#5989bd",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "60px",
  "@media screen and (max-width:599px)": {
    marginRight: "0px"
  }
});

const StyledPhoneInTalkOutlinedIcon = styled(FiPhoneCall)({
  fontSize: "40px",
});

const StyledVideocamOutlinedIcon = styled(GoDeviceCameraVideo)({
  fontSize: "40px"
});

const StyledMoreVertOutlinedIcon = styled(PiDotsThreeOutlineVerticalDuotone)({
  fontSize: "40px"
});

const ChatsBox = styled(Box)({
  flex: 1,
  overflowY: "auto",
  display: "flex",
  flexDirection: "column-reverse"
});

const TextFieldBox = styled(Box)({
  display: "flex",
  width: "100%",
  background: "white",
  alignSelf: "center",
  gap: "10px",
  "& .MuiInput-underline":{
    "&:hover":{
      "&:before":{
        borderBottom:"none"
      }
    },
    "&:before":{
      borderBottom:"none"
    },
    "&:after":{
      borderBottom:"none"
    }
  }
});

const StyledTextField = styled(TextField)({
  width: "90%",
  background: "#f4f4f4",
  textAlign: "center",
  padding: "20px",
  borderRadius: 10
});

const SendIconBox = styled(Box)({
  background: "#5989bd",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  width: "60px",
  height: "60px",
  marginTop: "5px",
  cursor: "pointer"
});

const StyledSendOutlinedIcon = styled(LuSend)({
  color: "white",
  fontSize: "30px"
});

const CallStyledUserImg = styled("img")({
  borderRadius: "50%",
  height: "60px"
});

const CallUserBox = styled(Box)({
  padding: 10,
  display: "flex",
  justifyContent: "space-between"
});

const CallLeftUserBox = styled(Box)({
  display: "flex",
  gap: 10
});

const CallNameBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center"
});

const CallUserNameTypography = styled(Typography)({
  fontWeight: "bold"
});

const CallRightUserBox = styled(Box)({
  display: "flex",
  flexDirection: "column"
});

const CallDurationTypography = styled(Typography)({
  alignSelf: "flex-end"
});

const CallTypographyGreen = styled(Typography)({
  color: "#84bcb4"
});

const CallTypographyTomato = styled(Typography)({
  color: "#e68d84"
});

const VcallStyledUserImg = styled("img")({
  borderRadius: "50%",
  height: "60px"
});

const VcallUserBox = styled(Box)({
  padding: 10,
  display: "flex",
  justifyContent: "space-between"
});

const LeftUserBox = styled(Box)({
  display: "flex",
  gap: 10
});

const VcallNameBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center"
});

const VcallUserNameTypography = styled(Typography)({
  fontWeight: "bold"
});

const VcallRightUserBox = styled(Box)({
  display: "flex",
  flexDirection: "column"
});

const VcallCallDurationTypography = styled(Typography)({
  alignSelf: "flex-end"
});

const VcallTypographyGreen = styled(Typography)({
  color: "#84bcb4"
});

const VcallTypographyTomato = styled(Typography)({
  color: "#e68d84"
});

const MessageBox = styled("div")({
  margin: "10px 5px",
  display: "flex",
  alignItems: "flex-end",
  flexDirection: "column",
  rowGap: "5px",
  paddingRight:"50px",
  position:"relative"
});

const ProfileBox = styled(Box)({
  height:"30px",
  width:"30px",
  backgroundColor:"transparent",
  position:"absolute",
  top:-10,
  right:10,
  borderRadius:"50%"
});

const PaitentProfileBox = styled(Box)({
  height:"30px",
  width:"30px",
  backgroundColor:"transparent",
  position:"absolute",
  top:-10,
  left:0,
  borderRadius:"50%"
});

const ProfileImage = styled("img")({
  height:"100%",
  width:"100%",
  objectFit:"cover",
  borderRadius: "50%"
});

const MessageType = styled("div")({
  backgroundColor: "#f4f9ff",
  padding: "5px",
  borderRadius: "5px",
  width: "180px",
  height: "180px"
});

const DoctMessage = styled("div") ({
  backgroundColor: "#f4f9ff",
  padding: "10px",
  borderRadius: "5px",
  color: "#000",
  maxWidth: "400px"
});

const MessageTime = styled("span") ({
   fontSize: "10px"
});

const FileMessageBox = styled("div") ({
  margin: "10px 5px",
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column",
  rowGap: "5px",
  paddingLeft:"50px",
  position:"relative"
});

const FileMessage = styled("div") ({
  backgroundColor: "#f4f9ff",
  padding: "5px",
  borderRadius: "5px",
  width: "180px",
  height: "180px",
  position: "relative",
  "& .downLoadIcon" : {
    fontSize: "24px",
    cursor: "pointer",
    position: "absolute",
    bottom: 0,
    right: "-22px"
  }
});

const PaitentFileMessage = styled("div") ({
  backgroundColor: "#f4f9ff",
  padding: "5px",
  borderRadius: "5px",
  width: "180px",
  height: "180px",
  position:"relative",
  "& .downLoadIcon" : {
    fontSize: "24px",
    cursor: "pointer",
    position:"absolute",
    bottom:0,
    right:-25
  }
});

const TimeBox = styled("div") ({
  backgroundColor: "#f4f9ff",
  padding: "10px",
  borderRadius: "5px",
  color: "#000",
  maxWidth: "400px"
});
// Customizable Area End
