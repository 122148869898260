export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const Add = require("../assets/Add.png");
export const RightOutlined = require("../assets/back.png");
export const contactus = require("../assets/contactus.png");
export const edit = require("../assets/edit.png");
export const healthrecords = require("../assets/healthrecords.png");
export const homaddress = require("../assets/home-address.png");
export const Linkinsurance = require("../assets/Linkinsurance.png");
export const logout = require("../assets/log-out.png");
export const loupegrey = require("../assets/loupe grey.png");
export const Myorder = require("../assets/Myorder.png");
export const Paymentmethod = require("../assets/Paymentmethod.png");
export const qrcode = require("../assets/qrcode.png");
export const Search = require("../assets/Search.png");
export const select = require("../assets/select.png");
export const settings = require("../assets/settings.png");
export const Shape = require("../assets/Shape.png");
export const Shapetop = require("../assets/Shapetop.png");
export const switchaccount = require("../assets/switchaccount.png");
export const voice = require("../assets/voice.png");
export const profile = require("../assets/profile.png");
export const notification = require("../assets/notification.png");
export const Language = require("../assets/Language.png");
export const privacy = require("../assets/privacy.png");
export const question = require("../assets/question.png");
export const deleteAccount = require("../assets/deleteAccount.png");
export const downback = require("../assets/downback.png");
export const editimg = require("../assets/editimg.png");
export const editname = require("../assets/Editname.png");
export const deleteimag = require("../assets/deleteimg.png");
export const deletename = require("../assets/Deletename.png");
export const Plusimg = require("../assets/Plusimg.png");
export const calendarimg = require("../assets/calendar.png");
export const clockimg = require("../assets/clock.png");
export const locationimg = require("../assets/location.png");
export const defaultImg = require("../assets/default.png");
