Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start

exports.getDoctorUpcomingAppointmentAPiEndPoint = "bx_block_appointment_management/doctor_upcoming_appointment";
exports.getDoctorUpcomingAppointmentAPiMethod = "GET";
exports.getDoctorUpcomingAppointmentApiContentType = "application/json";

exports.getAvailabilityAPiEndPoint = "bx_block_calendar/today_availiablity";


exports.appointmentApiContentType = 'application/json'

exports.appointmentAPiEndPoint = 'appointment_management/availabilities'
exports.getTimeSlotEndpoint = "bx_block_appointment_management/time_slot";
exports.addAppointmentAPiMethod = 'POST'
exports.deleteAllAppointmentsAPiMethod = 'DELETE'
exports.getAppointmentListAPiMethod = 'GET'
exports.getTimeSlotAPiMethod = 'GET'
exports.getApiMethod="GET"
exports.errorTitle = 'Error'
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.'
exports.bookAppointmentApiMethod="POST"
exports.getPatientDetails="account_block/current_patient_detail"
exports.razorpayCheckoutURL="https://checkout.razorpay.com/v1/checkout.js"
exports.bookingAppointmentSlotEndPoint='bx_block_appointment_management/create_appointment'
// exports.doctorsListApiEndPoint=`account_block/search_doctor_categorywise?doctor_category=${localStorage.getItem("doctor_category")}&city=delhi`
exports.getTimeSlotApiEndPoint=`bx_block_appointment_management/time_slot?[data][attributes][appointment_date]=9-08-2022&[data][attributes][mode_type]=clinic&[data][attributes][doctor_id]=404&[data][attributes][time_slot]=Morning&[data][attributes][user_type]=patient`
exports.getDoctorByIdApiEndPoint="account_block/show_doctor";
exports.getAppointmentMethod = "GET";
exports.getAppointmentDetailsContentType = "application/json";
exports.getAppointmentDetailsEndPoint = "bx_block_appointment_management/patient_appointment_detail";
exports.getProfileDetailsMethod = "GET";
exports.getProfileDetailsContentType = "application/json";
exports.getProfileDetailsEndPoint = "account_block/show";
exports.postCreatePrescriptionContentType = "application/json";
exports.postCreatePrescriptionApiMethod = "POST";
exports.deletePrescriptionMedicineMethod = "DELETE";
exports.postCreatePrescriptionApiEndPoint = "bx_block_medicine/create";
exports.showPrescriptionApiEndPoint = "bx_block_medicine/show";
exports.allRecordsApiEndPoint = "bx_block_appointment_management/records_patients";
exports.deletePrescriptionMedicineApiEndPoint = "bx_block_medicine/remove_prescription_medicine";
exports.getInvoiceDetailsContentType = "application/json";
exports.getInvoiceDetailsMethod = "GET";
exports.getInvoiceDetailsEndPoint = "bx_block_invoicebilling/invoice_details";
exports.getInvoicePdfDetailsEndPoint = "bx_block_invoicebilling/invoice_details_pdf";
exports.getMedicalHistoryEndPoint = "bx_block_medicine/patient_medical_history";
exports.getClinicDetailsAPIEndpoint="bx_block_address/get_clinics"
exports.putPrescriptionMedicineMethod = "PUT";
exports.putPrescriptionMedicineEndPoint = "bx_block_medicine/update_prescription_medicine";
exports.getAvailabilityByDateEndPoint = "bx_block_calendar/get_date_wise_availability";
exports.Summary = "Summary";
exports.DrName = "Dr.Manish mehta's Clinic Reports";
exports.getFaqEndPoints= "bx_block_settings/index_faqs"
// Customizable Area End