import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { notification } from "antd";
export interface DoctorProfileDetailsType {
  data: ProfileData;
}

export interface ProfileData {
  id: string;
  type: string;
  attributes: {
    representative_contact_no: string;
    full_name: string;
    medical_representative_name: string;
    profile_image: string;
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  isVisible: boolean;
  showPopup: boolean;
  isLogin: string | null;
  isPatientGoogleLogin: string | null;
  healthId: string | null;
  doctorName: string | null;
  doctorProfileData: DoctorProfileDetailsType;
  isKyc: string | null;
  isLoginUser: boolean;
  faqData:Array<object>
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FaqPageController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  postContactUsApiCallId: string = "";
  getFaqDataApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.deleteContactApiCallId = "";
    this.addContactApiCallId = "";

    this.state = {
      token: "",
      isVisible: false,
      showPopup: false,
      isLogin: "",
      isPatientGoogleLogin: "",
      healthId: '',
      doctorName: "",
      doctorProfileData: {
        data: {
          id: "",
          type: "",
          attributes: {
            full_name: "",
            profile_image: "",
            medical_representative_name: "",
            representative_contact_no: "",
          }
        }
      },
      isKyc: "",
      isLoginUser: false,
      faqData: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.getFaqApiCall();
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    const doctorName = localStorage.getItem("doctorName");
    const is_kyc = localStorage.getItem("is_kyc");
    const isDoctorGoogleLogin = localStorage.getItem("isDoctorGoogleLogin");
    const isPatientGoogleLogin = localStorage.getItem("isLogin");
    const healthId = localStorage.getItem("health_id");
    this.setState({
      isLogin: isDoctorGoogleLogin,
      isPatientGoogleLogin: isPatientGoogleLogin,
      healthId: healthId,
      doctorName: doctorName,
      isKyc: is_kyc
    });
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
     if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if(apiRequestCallId === this.getFaqDataApiCallId){
        this.setState({ faqData: responseJson.faqs })
      }
      runEngine.debugLog("API Message Recived", message);
    console.log(apiRequestCallId, responseJson, errorReponse)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handelShowDoctoPopUp = () => {
    this.setState({ showPopup: !this.state.showPopup });
  };

  handleDoctorLogout = () => {
    localStorage.clear();
    notification["success"]({
      message: "Logout successfully",
    });
    this.props.history.push("/");
    this.setState({
      isLoginUser: false
  })
  };
  getFaqApiCall = () => {
    const token = localStorage.getItem("token");
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      token: token,
    };
    this.getFaqDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFaqEndPoints
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
