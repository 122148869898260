import { BlockComponent } from "framework/src/BlockComponent";
export const configJSON = require("./config");
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import ApiCall from "../../../components/src/ApiCall.web";

// Customizable Area Start
import { message as MESSAGE, notification } from "antd";

export interface Props {
  navigation: any;
  id: string;
  history: any;
  location: any;
  // Customizable Area Start
  // Customizable Area End
}
interface FamilyMember {
  id: string;
  type: "family_member";
  attributes: {
    patient_id: number;
    email: string | null;
    full_phone_number: string | null;
    health_id: string | null;
    full_name: string;
    date_of_birth: string; // YYYY-MM-DD format
    gender: "Male" | "Female" | "Other";
    list_status: boolean;
    relation: string;
    blood_group: string;
    weight: string; // Can be converted to `number` if needed
    profile_pic: string | null;
  };
}
interface FamilyMembersResponse {
  data: FamilyMember[];
}
export interface S {
  // Customizable Area Start
  isModal:boolean;
  fullName: string;
  gender: string;
  relation:string;
  bloodGroup:string;
  dateOfBirth:string;
  weight: string;
  bloodGroup_error: string;
  fullName_error: string;
  gender_error: string;
  dateOfBirth_error: string;
  weight_error: string;
  relation_error: string;
  GenderData:string[];
  relationData:string[];
  bloodGroupData:string[];
  setImage: File | null;
  memberListData:FamilyMembersResponse;
  deleteId:string;
  isEdit:boolean
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProfileSwitchAccountPageController extends BlockComponent<Props, S, SS> {
  addAccountApiCallId:string ="";
  updateAccountApiCallId:string="";
  getMemberListDataApiCallId:string="";
  deleteMemberDetailsApiCallId:string=""
  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];


    this.state = {
    // Customizable Area Start
    isModal:false,
    fullName: "",
      gender: "Select",
      relation:"Select",
      bloodGroup:"Select",
      dateOfBirth:"",
      weight: "",
      bloodGroup_error: '',
      fullName_error: "",
      gender_error: "",
      dateOfBirth_error: "",
      weight_error: "",
      relation_error: "",
      GenderData:["Male", "Female"],
      relationData:["GrandFather", "GrandMother","Father", "Mother","Brother", "Sister", "Child"],
      bloodGroupData:["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"],
      setImage:null,
      memberListData:{data:[]},
      deleteId:"",
      isEdit:false
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if(apiRequestCallId === this.addAccountApiCallId){
        this.setAddAccountResponse(responseJson)
      }
  if(apiRequestCallId === this.getMemberListDataApiCallId){
    this.setMemberDetailsList(responseJson)
  }
  if(apiRequestCallId === this.deleteMemberDetailsApiCallId){
    notification["success"]({
      message: responseJson.message,
    })
    this.setState({isModal:false}, () => this.getMemberListDataApi())
  }
  if(apiRequestCallId === this.updateAccountApiCallId){
    this.setUpdateAccountResponse(responseJson)
  }
    }
    // Customizable Area End
  }
  setAddAccountResponse = (responseJson: any) => {
    if(responseJson.data){
      notification["success"]({
        message:`Family Member added Successfully`,
      })
      this.props.history.push('/PatientFamilyMembersList')
    }
    else{
      notification["error"]({
        message:responseJson.message,
      })
    }
  }
  setUpdateAccountResponse = (responseJson: any) => {
    if(responseJson.data){
      notification["success"]({
        message:`Family Member updated Successfully`,
      })
      this.props.history.push('/PatientFamilyMembersList')
    }
  }
  setMemberDetailsList = (responseJson: FamilyMembersResponse) => {
    if(responseJson?.data){
      this.setState({memberListData: responseJson})
    }
  }
  async componentDidMount() {
    const memberDetails = this.props.location?.state || {};
    super.componentDidMount();
    this.getMemberListDataApi()
    if(memberDetails?.item){
    this.setState({fullName: memberDetails.item.attributes.full_name})
    this.setState({gender: memberDetails.item.attributes.gender})
    this.setState({relation: memberDetails.item.attributes.relation})
    this.setState({dateOfBirth: memberDetails.item.attributes.date_of_birth})
   this.setState({weight: memberDetails.item.attributes.weight})
   this.setState({bloodGroup: memberDetails.item.attributes.blood_group})
   this.setState({setImage: memberDetails.item.attributes.profile_pic})

  this.setState({isEdit:true})
    }
  }

  handleMemberDetailsOpen = () => {
    this.props.history.push("/ProfileMemberDetailsPage");
  }
  handleEditIcon = (data: FamilyMember) => {
    this.props.history.push({pathname:"/ProfileMemberDetailsPage", state:{item: data}});
  }
  handleDeleteBtn = (event:any, dataId:string) => {
    event.stopPropagation()
    this.setState({isModal:true, deleteId: dataId})
  }
  handleBack = () => {
    this.setState({isModal:false})
  }
  handleChangeFullName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fullName = event.target.value;
    const alphaFullName = fullName.replace(/[^a-zA-Z ]/g, "");
    this.setState({ fullName: alphaFullName, fullName_error:"" });
  };

  handleChangeGender = (value:string) => {
    this.setState({ gender: value, gender_error:""});
  };
  handleChangeRelation = (value:string) => {
    this.setState({ relation: value, relation_error:"" });
  };
  handleChangeBloodGroup = (value:string) => {
    this.setState({ bloodGroup: value, bloodGroup_error:"" });
  };
  handleChangeDateOfBirth = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ dateOfBirth: event.target.value , dateOfBirth_error:""});
  };
  handleChangeWeight = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let value = event.target.value.replace(/[^\d]/g, "");
    this.setState({ weight: value, weight_error:'' });
  };
  handleGoToMemberList = () => {
        this.props.history.push("/PatientFamilyMembersList");
  }
  validate = () => {
    const { fullName, gender, dateOfBirth, weight, relation, bloodGroup, isEdit} = this.state;
    if (fullName === "") {
      this.setState({ fullName_error: "Please Enter Your full Name" });
    }
    if (gender === "Select") {
      this.setState({ gender_error: "Please Select Gender" });
    }
    if (dateOfBirth === "") {
      this.setState({ dateOfBirth_error: "Please Enter Date of Birth" });
    }
    if (weight === "") {
      this.setState({ weight_error: "Enter Your weight" });
    }
    if (relation === "Select") {
        this.setState({ relation_error: "Select relation" });
      }
    if (bloodGroup === "Select") {
        this.setState({ bloodGroup_error: "Select blood group" });
      }
    else{
      if(isEdit){
        this.updateMemeberDetailsApi()
      }
      else{
        this.postCreateAccountApi()
    }
    }
  };
  handleSubmit = async () => {
    this.validate();
  };
  handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      this.setState({setImage:file})
    }
  };
    postCreateAccountApi = async () => {
    const { fullName, gender, dateOfBirth, weight, relation, bloodGroup, setImage} = this.state;
      let formdata = new FormData();
      formdata.append("data[attributes][full_name]", fullName);
    formdata.append("data[attributes][weight]", weight);
    formdata.append("data[attributes][blood_group]", bloodGroup);
    formdata.append("data[attributes][gender]",gender);
    formdata.append("data[attributes][relation]", relation);
    formdata.append("data[attributes][date_of_birth]", dateOfBirth);
    if(setImage){
     formdata.append("profile_pic", setImage);
    }
      const header = {
        ContentType: "multipart/form-data",
        token: localStorage.getItem('token')
      };

      const httpBody = formdata;
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.addAccountApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'Post'
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        httpBody
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addMemberEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    updateMemeberDetailsApi = async () => {
      const { fullName, gender, dateOfBirth, weight, relation, bloodGroup, setImage} = this.state;
        let formdata = new FormData();
        formdata.append("id", this.props.location?.state.item?.id);
        formdata.append("data[attributes][full_name]", fullName);
      formdata.append("data[attributes][gender]",gender);
      formdata.append("data[attributes][relation]", relation);
      formdata.append("data[attributes][date_of_birth]", dateOfBirth);
      formdata.append("data[attributes][weight]", weight);
      formdata.append("data[attributes][blood_group]", bloodGroup);
      if (setImage) {
        if (typeof setImage === "string") {
          const response = await fetch(setImage);
          const blob = await response.blob();
          const file = new File([blob], "profile_pic.png", { type: blob.type });
    
          formdata.append("profile_pic", file);
        } else {
          formdata.append("profile_pic", setImage);
        }
      }
        const header = {
          ContentType: "multipart/form-data",
          token: localStorage.getItem('token')
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateAccountApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.updateMemberEndPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          header
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          `${configJSON.updateManageAddressAPiMethod}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formdata
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    getMemberListDataApi = async () => {
          this.getMemberListDataApiCallId = ApiCall({
            contentType: configJSON.getMyOrderApiContentType,
            method: configJSON.getMyOrderAPiMethod,
            endPoint: `${configJSON.getMemberListEndPoints}`,
          });
        };
        deleteMemberDetailsApi = async () => {
          this.deleteMemberDetailsApiCallId = ApiCall({
            contentType: configJSON.getMyOrderApiContentType,
            method: configJSON.patchmethod,
            endPoint: `${configJSON.deleteMemberDetailsEndPoints}?id=${this.state.deleteId}`,
          });
        };
  // Customizable Area End
}
